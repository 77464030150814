const translations = {
    appDownloadButton: {
        languages: {
            1: {
                language: "English",
                translation: "Download APP",
            },
            2: {
                language: "hindi",
                translation: "ऐप डाउनलोड करें",
            },
            3: {
                language: "Marathi",
                translation: "ऐप डाउनलोड करा",
            },
            7: {
                language: "Punjabi",
                translation: "APP ਡਾਊਨਲੋਡ ਕਰੋ",
            },
            6: {
                language: "Bengali",
                translation: "APP ডাউনলোড করুন",
            },
            14: {
                language: "Assamese",
                translation: "APP ডাউনলোড কৰক",
            },
            18: {
                language: "Nagamese",
                translation: "APP download kor bhi ",
            },
            21: {
                language: "Khasi",
                translation: "Download ia ka APP",
            },
            20: {
                language: "Mizo",
                translation: "App Download rawh",
            },
            19: {
                language: "Manipuri",
                translation: "App download toubiyu",
            },
            8: {
                language: "Gujarati",
                translation: "APP ડાઉનલોડ કરો",
            },
            17: {
                language: "Tamil",
                translation: "APP ஐப் பதிவிறக்கவும்",
            },
            5: {
                language: "Telugu",
                translation: "అనువర్తనాన్ని డౌన్లోడ్ చేయండి",
            },
            10: {
                language: "Malayalam",
                translation: "APP ഡൗൺലോഡ് ചെയ്യുക",
            },
            4: {
                language: "Kannada",
                translation: "APP ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
            },
        },
        default: "Download APP",
    },
    betaText: {
        languages: {
            1: {
                language: "English",
                translation: "Now in Beta!",
            },
            2: {
                language: "hindi",
                translation: "अब बीटा में!",
            },
            3: {
                language: "Marathi",
                translation: "आता बीटा मध्ये!",
            },
            7: {
                language: "Punjabi",
                translation: "ਹੁਣ ਬੀਟਾ ਵਿੱਚ!",
            },
            6: {
                language: "Bengali",
                translation: "এখন বিটাতে!",
            },
            14: {
                language: "Assamese",
                translation: "এতিয়া বিটাত!",
            },
            18: {
                language: "Nagamese",
                translation: "etya beta deh!",
            },
            21: {
                language: "Khasi",
                translation: "Mynta ha Beta!",
            },
            20: {
                language: "Mizo",
                translation: "Tunah chuan Beta ah a awm ta!",
            },
            19: {
                language: "Manipuri",
                translation: "Houjikti Beta!",
            },
            8: {
                language: "Gujarati",
                translation: "હવે બીટામાં!",
            },
            17: {
                language: "Tamil",
                translation: "இப்போது பீட்டாவில்!",
            },
            5: {
                language: "Telugu",
                translation: "ఇప్పుడు బీటాలో!",
            },
            10: {
                language: "Malayalam",
                translation: "ഇപ്പോൾ ബീറ്റയിൽ!",
            },
            4: {
                language: "Kannada",
                translation: "ಈಗ ಬೀಟಾದಲ್ಲಿ!",
            },
        },
        default: "Now in Beta!",
    },
    communityText: {
        languages: {
            1: {
                language: "English",
                translation: "Community of, for and by Rural India",
            },
            2: {
                language: "hindi",
                translation: "ग्रामीण भारत का समुदाय, उसके लिए और उसके द्वारा",
            },
            3: {
                language: "Marathi",
                translation: "ग्रामीण भारतासाठी आणि द्वारे समुदाय",
            },
            7: {
                language: "Punjabi",
                translation: "ਗ੍ਰਾਮੀਣ ਭਾਰਤ ਲਈ ਅਤੇ ਦੁਆਰਾ ਕਮਿਊਨਿਟੀ",
            },
            6: {
                language: "Bengali",
                translation: "গ্রামীণ ভারতের জন্য এবং দ্বারা সম্প্রদায়",
            },
            14: {
                language: "Assamese",
                translation: "গ্ৰাম্য ভাৰতৰ, ভাৰতৰ বাবে আৰু ইয়াৰ দ্বাৰা সম্প্ৰদায়",
            },
            18: {
                language: "Nagamese",
                translation: "manu kan, karney aru bosti india bara ",
            },
            21: {
                language: "Khasi",
                translation: "Ki nongshongshnong jong,na bynta bad da ka Rural India",
            },
            20: {
                language: "Mizo",
                translation: "Rural India Khawtlang mite tan,khawtlang mi leh Rural India siam",
            },
            19: {
                language: "Manipuri",
                translation: "Khunganggi meeyamgi, eikhoigini amasung Rural India singini",
            },
            8: {
                language: "Gujarati",
                translation: "ગ્રામીણ ભારત માટે અને દ્વારા સમુદાય",
            },
            17: {
                language: "Tamil",
                translation: "கிராமப்புற இந்தியாவின் சமூகம்",
            },
            5: {
                language: "Telugu",
                translation: "గ్రామీణ భారతదేశం కోసం మరియు వారిచే సంఘం",
            },
            10: {
                language: "Malayalam",
                translation: "ഗ്രാമീണ ഇന്ത്യയുടെ, വേണ്ടിയുള്ള, വേണ്ടിയുള്ള സമൂഹം",
            },
            4: {
                language: "Kannada",
                translation: "ಗ್ರಾಮೀಣ ಭಾರತದ ಸಮುದಾಯ",
            },
        },
        default: "Community of, for and by Rural India",
    },
    mgIntroText: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Mera Gaon is a hyperlocal and private hub for villages across the length and breadth of the country 🇮🇳 where 1B rural user can interact with each other regarding matters concerning the local community.",
            },
            2: {
                language: "hindi",
                translation:
                    "मेरा गांव देश भर के गांवों के लिए एक हाइपरलोकल और निजी केंद्र है 🇮🇳 जहां 1बी ग्रामीण उपयोगकर्ता स्थानीय समुदाय से संबंधित मामलों के बारे में एक दूसरे के साथ बातचीत कर सकते हैं।",
            },
            3: {
                language: "Marathi",
                translation:
                    "मेरा गाव हे देशभरातील गावांसाठी एक हायपरलोकल आणि खाजगी केंद्र आहे 🇮🇳 जिथे 1B ग्रामीण वापरकर्ते स्थानिक समुदायाशी संबंधित बाबींवर एकमेकांशी संवाद साधू शकतात.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਮੇਰਾ ਗਾਓਂ ਦੇਸ਼ ਦੀ ਲੰਬਾਈ ਅਤੇ ਚੌੜਾਈ ਦੇ ਪਿੰਡਾਂ ਲਈ ਇੱਕ ਹਾਈਪਰਲੋਕਲ ਅਤੇ ਪ੍ਰਾਈਵੇਟ ਹੱਬ ਹੈ 🇮🇳 ਜਿੱਥੇ 1B ਪੇਂਡੂ ਉਪਭੋਗਤਾ ਸਥਾਨਕ ਭਾਈਚਾਰੇ ਨਾਲ ਸਬੰਧਤ ਮਾਮਲਿਆਂ ਬਾਰੇ ਇੱਕ ਦੂਜੇ ਨਾਲ ਗੱਲਬਾਤ ਕਰ ਸਕਦੇ ਹਨ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "মেরা গাঁও দেশের দৈর্ঘ্য এবং প্রস্থ জুড়ে গ্রামগুলির জন্য একটি হাইপারলোকাল এবং ব্যক্তিগত কেন্দ্র 🇮🇳 যেখানে 1B গ্রামীণ ব্যবহারকারী স্থানীয় সম্প্রদায় সম্পর্কিত বিষয়ে একে অপরের সাথে যোগাযোগ করতে পারে",
            },
            14: {
                language: "Assamese",
                translation:
                    "মেৰা গাওঁ দেশৰ দৈৰ্ঘ্য আৰু প্ৰস্থৰ গাঁওসমূহৰ বাবে এটা হাইপাৰলোকেল আৰু ব্যক্তিগত হাব 🇮🇳 য'ত ১বি গ্ৰাম্য ব্যৱহাৰকাৰীয়ে স্থানীয় সম্প্ৰদায়ৰ সম্পৰ্কীয় বিষয়সমূহৰ সন্দৰ্ভত ইজনে সিজনৰ লগত মত বিনিময় কৰিব পাৰে।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "Mera Gaon doh bosti manu aru private hub ase bosti mnau kan karney lamba aru etu desh🇮🇳 laka  etu deh 1B mnau kan ekjun ekjun ki kota kory boley barey bo nijor bosti manu kan laka.",
            },
            21: {
                language: "Khasi",
                translation:
                    "Mera Gaon kadei hyper local bad shimet na bynta ki  nongshong shnong bad baroh kawei ka ri 🇮🇳  ha ka ba 1B ki nongkyndong ba pyndonkam ki lah ban ia phylliew jingmut markylliang ha kaba iadei bad ka trai nongshong shnong.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Mera Gaon hi ram 🇮🇳  chhung hmun hrang hranga khaw hrang hrang te mahni khua bik leh private hub a ni a, thingtlang lama mi 1B chuan tualchhung mite chungchanga thil awm danah an inpawh tawn thei a ni.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Mera Gaon asi akoibagi amadi lanaiga oiba khunganggi apakpa amadi asangba oiba  leibak 🇮🇳  asigi 1B khungangi meeoi kayanaa amaga amaga wari watai saba akoibada kari tanaba yabage haibadu tanaphamni.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "મેરા ગાંવ એ દેશની લંબાઇ અને પહોળાઈના ગામડાઓ માટે હાઇપરલોકલ અને ખાનગી હબ છે 🇮🇳 જ્યાં 1B ગ્રામીણ વપરાશકર્તા સ્થાનિક સમુદાયને લગતી બાબતો અંગે એકબીજા સાથે વાર્તાલાપ કરી શકે છે.",
            },
            17: {
                language: "Tamil",
                translation:
                    "மேரா காவ்ன் என்பது நாட்டின் நீளம் மற்றும் அகலத்தில் உள்ள கிராமங்களுக்கான ஹைப்பர்லோகல் மற்றும் தனியார் மையமாகும், அங்கு 🇮🇳 1B கிராமப்புற பயனர்கள் உள்ளூர் சமூகம் தொடர்பான விஷயங்களில் ஒருவருக்கொருவர் தொடர்பு கொள்ளலாம்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "మేరా గావ్ అనేది దేశంలోని పొడవు మరియు వెడల్పులో ఉన్న గ్రామాలకు హైపర్‌లోకల్ మరియు ప్రైవేట్ హబ్, 🇮🇳  1B గ్రామీణ వినియోగదారు స్థానిక కమ్యూనిటీకి సంబంధించిన విషయాలకు సంబంధించి ఒకరితో ఒకరు సంభాషించవచ్చు.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "മേരാ ഗാവ്, vi   Sheet1 രാജ്യത്തിന്റെ നീളത്തിലും പരപ്പിലുമുള്ള പര്യവേക്ഷണങ്ങൾക്കായുള്ള ഒരു ഹൈപ്പർലോക്കൽ, സ്വകാര്യ ഹബ്ബാണ് 🇮🇳 അവിടെ 1B ഗ്രാമീണ ഉപഭോക്താക്കൾക്ക് പ്രാദേശിക സമൂഹവുമായി ബന്ധപ്പെട്ട കാര്യങ്ങളിൽ പരസ്പരം സംവദിക്കാൻ കഴിയും.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಮೇರಾ ಗಾಂವ್ ದೇಶದ ಉದ್ದ ಮತ್ತು ಅಗಲದ ಹಳ್ಳಿಗಳಿಗೆ ಹೈಪರ್‌ಲೋಕಲ್ ಮತ್ತು ಖಾಸಗಿ ಕೇಂದ್ರವಾಗಿದೆ 🇮🇳 ಇಲ್ಲಿ 1B ಗ್ರಾಮೀಣ ಬಳಕೆದಾರರು ಸ್ಥಳೀಯ ಸಮುದಾಯಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ವಿಷಯಗಳಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಪರಸ್ಪರ ಸಂವಹನ ನಡೆಸಬಹುದು.",
            },
        },
        default:
            "Mera Gaon is a hyperlocal and private hub for villages across the length and breadth of the country 🇮🇳 where 1B rural user can interact with each other regarding matters concerning the local community.",
    },
    getLinkText: {
        languages: {
            1: {
                language: "English",
                translation: "Enter 10 digit mobile number",
            },
            2: {
                language: "hindi",
                translation: "10 अंकों का मोबाइल नंबर दर्ज करें",
            },
            3: {
                language: "Marathi",
                translation: "10 अंकी मोबाईल नंबर टाका",
            },
            7: {
                language: "Punjabi",
                translation: "10 ਅੰਕਾਂ ਦਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
            },
            6: {
                language: "Bengali",
                translation: "10 সংখ্যার মোবাইল নম্বর লিখুন",
            },
            14: {
                language: "Assamese",
                translation: "১০ অংকৰ মোবাইল নম্বৰ লিখক",
            },
            18: {
                language: "Nagamese",
                translation: "dosta mobile number taley bhi ",
            },
            21: {
                language: "Khasi",
                translation: "Ai u 10 digit mobile number",
            },
            20: {
                language: "Mizo",
                translation: "Digit 10 mobile number ziak lut rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Masing 10 gi phone number nammu",
            },
            8: {
                language: "Gujarati",
                translation: "10 અંકનો મોબાઈલ નંબર દાખલ કરો",
            },
            17: {
                language: "Tamil",
                translation: "10 இலக்க மொபைல் எண்ணை உள்ளிடவும்",
            },
            5: {
                language: "Telugu",
                translation: "10 అంకెల మొబైల్ నంబర్‌ను నమోదు చేయండి",
            },
            10: {
                language: "Malayalam",
                translation: "10 അക്ക മൊബൈൽ നമ്പർ നൽകുക",
            },
            4: {
                language: "Kannada",
                translation: "10 ಅಂಕಿಗಳ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
            },
        },
        default: "Enter 10 digit mobile number",
    },
    getLink: {
        languages: {
            1: {
                language: "English",
                translation: "Get Link",
            },
            2: {
                language: "hindi",
                translation: " लिंक प्राप्त करे",
            },
            3: {
                language: "Marathi",
                translation: "लिंक मिळवा",
            },
            7: {
                language: "Punjabi",
                translation: "ਲਿੰਕ ਪ੍ਰਾਪਤ ਕਰੋ",
            },
            6: {
                language: "Bengali",
                translation: "লিংক পেতে",
            },
            14: {
                language: "Assamese",
                translation: "লিংক পাওক",
            },
            18: {
                language: "Nagamese",
                translation: "link bai bhi ",
            },
            21: {
                language: "Khasi",
                translation: "Ioh ka link",
            },
            20: {
                language: "Mizo",
                translation: "Link dawng rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Link phangani",
            },
            8: {
                language: "Gujarati",
                translation: "લિંક મેળવો",
            },
            17: {
                language: "Tamil",
                translation: "இணைப்பைப் பெறுங்கள்",
            },
            5: {
                language: "Telugu",
                translation: "లింక్ పొందండి",
            },
            10: {
                language: "Malayalam",
                translation: "ലിങ്ക് നേടുക",
            },
            4: {
                language: "Kannada",
                translation: "ಲಿಂಕ್ ಪಡೆಯಿರಿ",
            },
        },
        default: "Get Link",
    },
    getLinkBottomText: {
        languages: {
            1: {
                language: "English",
                translation: `<p>By submitting your mobile number, you agree to <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Our Terms of use</a> and <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal"> Privacy Policy</a></p>`,
            },
            2: {
                language: "hindi",
                translation: `<p>अपना मोबाइल नंबर सबमिट करके, आप हमारी उपयोग की<a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">शर्तों</a> और <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal"> गोपनीयता नीति </a> से सहमत होते हैं।</p>`,
            },
            3: {
                language: "Marathi",
                translation: `<p>तुमचा मोबाइल नंबर प्रस्तुत करून, तुम्ही  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">आमच्या वापराच्या अटी</a> आणि <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">गोपनीयता धोरण</a> यांना सहमती देता.</p>`,
            },
            7: {
                language: "Punjabi",
                translation: `<p>ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰਕੇ, ਤੁਸੀਂ <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ਸਾਡੀਆਂ ਵਰਤੋਂ ਦੀਆਂ ਸ਼ਰਤਾਂ</a> ਅਤੇ <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ਪਰਦੇਦਾਰੀ ਨੀਤੀ </a> ਨਾਲ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ।</p>`,
            },
            6: {
                language: "Bengali",
                translation: `<p>আপনার মোবাইল নম্বর জমা দিয়ে, আপনি <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Our Terms of use</a> এবং <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Privacy Policy</a> এ সম্মত হন।</p>`,
            },
            14: {
                language: "Assamese",
                translation: `<p>আপোনাৰ মোবাইল নম্বৰ জমা দি আপুনি <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">আমাৰ ব্যৱহাৰৰ চৰ্তসমূহ</a> আৰু <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">গোপনীয়তা নীতি</a> ত সন্মত হৈছে।</p>`,
            },
            18: {
                language: "Nagamese",
                translation: `<p>apne laka moibile number de kina, aone <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">moikan laka terms</a> aru <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Privacy Policy</a> maney ase</p>`,
            },
            21: {
                language: "Khasi",
                translation: `<p>Da ka ba ai u number phone ,Phi  lah mynjur ia <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Our Terms of use</a> bad <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Privacy Policy</a></p>`,
            },
            20: {
                language: "Mizo",
                translation: `<p>I mobile number i submit hian <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Kan kalhmang leh dan te</a> leh <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Thuruk humhalh dan tur</a> i pawm tihna a ni.</p>`,
            },
            19: {
                language: "Manipuri",
                translation: `<p>Adomgi phone number pisalaga, adom yarani madudi <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Our Terms of use</a> amadi <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Privacy Policy</a></p>`,
            },
            8: {
                language: "Gujarati",
                translation: `<p>તમારો મોબાઇલ નંબર સબમિટ કરીને, તમે  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">અમારી ઉપયોગની શરતો</a> અને <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ગોપનીયતા નીતિ</a> સાથે સંમત થાઓ છો.</p>`,
            },
            17: {
                language: "Tamil",
                translation: `<p>உங்கள் மொபைல் எண்ணைச் சமர்ப்பிப்பதன் மூலம்,  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal"> எங்கள் பயன்பாட்டு விதிமுறைகள்</a> மற்றும் <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal"> தனியுரிமைக் கொள்கையை</a> ஏற்கிறீர்கள்</p>`,
            },
            5: {
                language: "Telugu",
                translation: `<p>మీ మొబైల్ నంబర్‌ను సమర్పించడం ద్వారా, మీరు <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">మా ఉపయోగ నిబంధనలు</a> మరియు <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal"> గోప్యతా విధానం</a> కి అంగీకరిస్తున్నారు.</p>`,
            },
            10: {
                language: "Malayalam",
                translation: `<p>നിങ്ങളുടെ മൊബൈൽ നമ്പർ സമർപ്പിക്കുന്നതിലൂടെ, നിങ്ങൾ  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ഞങ്ങളുടെ ഉപയോഗ നിബന്ധനകളും</a>  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">സ്വകാര്യതാ നയവും</a> അംഗീകരിക്കുന്നു</p>`,
            },
            4: {
                language: "Kannada",
                translation: `<p>ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಸಲ್ಲಿಸುವ ಮೂಲಕ, ನೀವು  <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ನಮ್ಮ ಬಳಕೆಯ ನಿಯಮಗಳು</a> ಮತ್ತು <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">ಗೌಪ್ಯತೆ ನೀತಿಗೆ</a> ಸಮ್ಮತಿಸುತ್ತೀರಿ.</p>`,
            },
        },
        default: `<p>By submitting your mobile number, you agree to <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Our Terms of use</a> and <a rel=“noopener noreferrer” target={“_blank”} href="https://meragaonapp.com/legal">Privacy Policy</a></p>`,
    },
    smsLinkSuccessfully: {
        languages: {
            1: {
                language: "English",
                translation: "APP dowload link has been sent to your number",
            },
            2: {
                language: "hindi",
                translation: "ऐप डाउनलोड लिंक आपके नंबर पर भेज दिया गया है। ",
            },
            3: {
                language: "Marathi",
                translation: "ऐप डाउनलोड लिंक तुमच्या नंबरवर पाठवली आहे",
            },
            7: {
                language: "Punjabi",
                translation: "ਐਪ ਡਾਊਨਲੋਡ ਲਿੰਕ ਤੁਹਾਡੇ ਨੰਬਰ 'ਤੇ ਭੇਜ ਦਿੱਤਾ ਗਿਆ ਹੈ ",
            },
            6: {
                language: "Bengali",
                translation: "আপনার নাম্বারে অ্যাপ ডাউনলোড লিংক পাঠানো হয়েছে",
            },
            14: {
                language: "Assamese",
                translation: "আপোনাৰ নম্বৰত App download লিংক প্ৰেৰণ কৰা হৈছে ",
            },
            18: {
                language: "Nagamese",
                translation: "AAP download laka link doh apne laka number deh batai shai",
            },
            21: {
                language: "Khasi",
                translation: "Ka link ban download ia ka APP lah phah ha u number jong phi.",
            },
            20: {
                language: "Mizo",
                translation: "App download link chu i number ah a rawn thawn tawh a ni",
            },
            19: {
                language: "Manipuri",
                translation: "App download tounaba link asi adomgi phone number da thakhare",
            },
            8: {
                language: "Gujarati",
                translation: "એપ ડાઉનલોડ લિંક તમારા નંબર પર મોકલવામાં આવી છે ",
            },
            17: {
                language: "Tamil",
                translation: "ஆப்ஸ் பதிவிறக்க இணைப்பு உங்கள் எண்ணுக்கு அனுப்பப்பட்டது",
            },
            5: {
                language: "Telugu",
                translation: "యాప్ డౌన్‌లోడ్ లింక్ మీ నంబర్‌కి పంపబడింది ",
            },
            10: {
                language: "Malayalam",
                translation: "ആപ്പ് ഡൗൺലോഡ് ലിങ്ക് നിങ്ങളുടെ നമ്പറിലേക്ക് അയച്ചു",
            },
            4: {
                language: "Kannada",
                translation: "ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಲಿಂಕ್ ಅನ್ನು ನಿಮ್ಮ ಸಂಖ್ಯೆಗೆ ಕಳುಹಿಸಲಾಗಿದೆ",
            },
        },
        default: "APP dowload link has been sent to your number",
    },
    greenPartHeading1: {
        languages: {
            1: {
                language: "English",
                translation: "At Mera Gaon, we take the pre-existing social fabric of a village online",
            },
            2: {
                language: "hindi",
                translation: "मेरा गाँव में, हम गाँव की पहले से मौजूद सामाजिक संरचना को ऑनलाइन लेते हैं",
            },
            3: {
                language: "Marathi",
                translation: "मेरा गाव येथे, आम्ही खेडेगावातील पूर्वीपासून अस्तित्वात असलेली सामाजिक रचना ऑनलाइन घेतो",
            },
            7: {
                language: "Punjabi",
                translation: "ਮੇਰਾ ਗਾਓਂ ਵਿਖੇ, ਅਸੀਂ ਇੱਕ ਪਿੰਡ ਦੇ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ ਸਮਾਜਿਕ ਤਾਣੇ-ਬਾਣੇ ਨੂੰ ਆਨਲਾਈਨ ਲੈਂਦੇ ਹਾਂ",
            },
            6: {
                language: "Bengali",
                translation: "মেরা গাঁও-এ, আমরা অনলাইনে একটি গ্রামের প্রাক-বিদ্যমান সামাজিক ফ্যাব্রিক গ্রহণ করি",
            },
            14: {
                language: "Assamese",
                translation: "মেৰা গাওঁত আমি এখন গাঁৱৰ পূৰ্বতে থকা সামাজিক তন্ত্ৰটো অনলাইনত লওঁ",
            },
            18: {
                language: "Nagamese",
                translation: "Mera Gaon deh, moi kan taka laka social fabric bosti online loi ",
            },
            21: {
                language: "Khasi",
                translation: "Ha Mera Gaon,ngi shim ia ki social fabric ba lah don lypa jong ka shnong online",
            },
            20: {
                language: "Mizo",
                translation: "Mera Gaon-ah hian khaw pakhata khawtlang nunphung awm tawh chu online-ah kan la a",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Mera Gaon asida, eikhoina hanna leitriba social fabric oiba ama khungang online da phangani",
            },
            8: {
                language: "Gujarati",
                translation: "મેરા ગાંવ ખાતે, અમે ગામડાની પૂર્વ-અસ્તિત્વમાં રહેલી સામાજિક રચનાને ઓનલાઈન લઈએ છીએ",
            },
            17: {
                language: "Tamil",
                translation: "மேரா கானில், ஒரு கிராமத்தின் முன்பே இருக்கும் சமூக அமைப்பை ஆன்லைனில் எடுத்துக்கொள்கிறோம்",
            },
            5: {
                language: "Telugu",
                translation: "మేరా గావ్ వద్ద, మేము ఆన్‌లైన్‌లో ఒక గ్రామం యొక్క పూర్వ సామాజిక స్వరూపాన్ని తీసుకుంటాము",
            },
            10: {
                language: "Malayalam",
                translation: "മേരാ ഗാവിൽ, ഞങ്ങൾ ഒരു ഗ്രാമത്തിന്റെ മുൻകാല സാമൂഹിക ഘടന ഓൺലൈനിൽ എടുക്കുന്നു",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಮೇರಾ ಗಾಂವ್‌ನಲ್ಲಿ, ನಾವು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಹಳ್ಳಿಯ ಪೂರ್ವ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಸಾಮಾಜಿಕ ರಚನೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳುತ್ತೇವೆ",
            },
        },
        default: "At Mera Gaon, we take the pre-existing social fabric of a village online",
    },
    greenPartSubHeading1: {
        languages: {
            1: {
                language: "English",
                translation:
                    "When a user sign ups, they have to choose the village they belong to, and once approved by existing occupants of that village, they are right away connected with others on a page that only they and fellow village occupants have access to.",
            },
            2: {
                language: "hindi",
                translation:
                    "जब कोई उपयोगकर्ता साइन अप करता है, तो उन्हें उस गांव का चयन करना होता है, जिससे वे संबंधित होते हैं, और एक बार उस गांव के मौजूदा निवासियों द्वारा अनुमोदित होने के बाद, वे तुरंत एक पृष्ठ पर दूसरों के साथ जुड़ जाते हैं, जिस तक केवल उनके और गांव के रहने वालों की पहुंच होती है।",
            },
            3: {
                language: "Marathi",
                translation:
                    "जेव्हा एक वापरकर्ता साइन अप करतो, तेव्हा त्यांना ते संबंधित गाव निवडावे लागते आणि एकदा त्या गावातील विद्यमान रहिवाशांनी मान्यता दिली की, ते लगेचच इतरांशी एका पृष्ठावर जोडले जातात ज्यामध्ये फक्त त्यांना आणि गावातील सहवासीयांना प्रवेश असतो.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਜਦੋਂ ਇੱਕ ਉਪਭੋਗਤਾ ਸਾਈਨ ਅੱਪ ਕਰਦਾ ਹੈ, ਤਾਂ ਉਹਨਾਂ ਨੂੰ ਉਸ ਪਿੰਡ ਦੀ ਚੋਣ ਕਰਨੀ ਪੈਂਦੀ ਹੈ ਜਿਸ ਨਾਲ ਉਹ ਸਬੰਧਤ ਹਨ, ਅਤੇ ਇੱਕ ਵਾਰ ਉਸ ਪਿੰਡ ਦੇ ਮੌਜੂਦਾ ਵਸਨੀਕਾਂ ਦੁਆਰਾ ਮਨਜ਼ੂਰੀ ਦਿੱਤੇ ਜਾਣ ਤੋਂ ਬਾਅਦ, ਉਹ ਤੁਰੰਤ ਇੱਕ ਪੰਨੇ 'ਤੇ ਦੂਜਿਆਂ ਨਾਲ ਜੁੜ ਜਾਂਦੇ ਹਨ ਜਿਸ ਤੱਕ ਸਿਰਫ਼ ਉਹਨਾਂ ਅਤੇ ਪਿੰਡ ਦੇ ਰਹਿਣ ਵਾਲਿਆਂ ਦੀ ਪਹੁੰਚ ਹੁੰਦੀ ਹੈ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "যখন একজন ব্যবহারকারী সাইন আপ করেন, তখন তাদের সেই গ্রামটি বেছে নিতে হবে যেটি তাদের অন্তর্গত, এবং একবার সেই গ্রামের বিদ্যমান বাসিন্দাদের দ্বারা অনুমোদিত হলে, তারা অবিলম্বে একটি পৃষ্ঠায় অন্যদের সাথে সংযুক্ত হয়ে যায় যেটি শুধুমাত্র তারা এবং ",
            },
            14: {
                language: "Assamese",
                translation:
                    "যেতিয়া কোনো ব্যৱহাৰকাৰীয়ে চাইন আপ কৰে, তেতিয়া তেওঁলোকে নিজৰ অন্তৰ্গত গাঁওখন বাছি ল’ব লাগে, আৰু সেই গাঁৱৰ বৰ্তমানৰ বাসিন্দাসকলে এবাৰ অনুমোদন দিলে, তেওঁলোকে লগে লগে আনৰ সৈতে এনে এটা পৃষ্ঠাত সংযোগ স্থাপন কৰে, য’ত কেৱল তেওঁলোক আৰু সহযোগী গাঁৱৰ বাসিন্দাসকলেহে প্ৰৱেশ কৰিব পাৰে।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "ketya jola manu sign up kory, daikan ki laka bosti bara ase chuni, aru bosti deh taka manu kan abara approve kory, etu bechi deh daikan etu page deh connected hoi jai aru etu eki bosti manu kan ki access bhi de,. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Haba i nongpyndonkam ki sign up,ki donkam ban jied ia ki shnong ba ki sah,bad hadien ba lah ioh jingbit na kito ki ba shong ha ka shnong jong phi,kin sa connect bad kiwei ha ka page ha kaba maki bad kiwei kiwei pat kaba ki lah peit. ",
            },
            20: {
                language: "Mizo",
                translation:
                    "User pakhatin a sign up chuan a awmna khua a thlan a ngai a, chu khuaa awm tawhte’n an pawmpui hnuah chuan anmahni leh an khaw mipuite chauhin an hman theih tur page-ah midangte nen an inzawm nghal a ni.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Masida sihn up touba matamda, makhoina leiba khungang adu khangdabani, khungang adugi ayaba leirabadi,khudakta page atoppada connect touraga khunganggi meeoi khaktana sijinaba yaba adu uba phangani.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "જ્યારે વપરાશકર્તા સાઇન અપ કરે છે, ત્યારે તેણે તે ગામ પસંદ કરવાનું હોય છે કે જેનાથી તેઓ સંબંધ ધરાવે છે, અને એકવાર તે ગામના હાલના રહેવાસીઓ દ્વારા મંજૂર કરવામાં આવે છે, તેઓ તરત જ અન્ય લોકો સાથે એવા પૃષ્ઠ પર જોડાયેલા હોય છે કે જ્યાં ફક્ત તેઓ અને સાથી ગામના રહેવાસીઓને જ ઍક્સેસ હોય.",
            },
            17: {
                language: "Tamil",
                translation:
                    "ஒரு பயனர் பதிவு செய்யும் போது, அவர்கள் தாங்கள் சேர்ந்த கிராமத்தைத் தேர்வு செய்ய வேண்டும், மேலும் அந்த கிராமத்தில் ஏற்கனவே வசிப்பவர்களால் அங்கீகரிக்கப்பட்டவுடன், அவர்களும் சக கிராமவாசிகளும் மட்டுமே அணுகக்கூடிய பக்கத்தில் உள்ள மற்றவர்களுடன் உடனடியாக இணைக்கப்படுவார்கள்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "ఒక వినియోగదారు సైన్ అప్ చేసినప్పుడు, వారు తమకు చెందిన గ్రామాన్ని ఎంచుకోవాలి మరియు ఆ గ్రామంలో ఇప్పటికే ఉన్న నివాసితులు ఆమోదించిన తర్వాత, వారు వెంటనే వారు మరియు తోటి గ్రామ నివాసితులు మాత్రమే యాక్సెస్ చేయగల పేజీలో ఇతరులతో కనెక్ట్ అవుతారు.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "ഒരു ഉപയോക്താവ് സൈൻ അപ്പ് ചെയ്യുമ്പോൾ, അവർ ഉൾപ്പെടുന്ന ഗ്രാമം തിരഞ്ഞെടുക്കണം, ആ ഗ്രാമത്തിലെ നിലവിലുള്ള നിവാസികൾ അംഗീകരിച്ചുകഴിഞ്ഞാൽ, അവർക്കും സഹ ഗ്രാമവാസികൾക്കും മാത്രം ആക്‌സസ് ഉള്ള ഒരു പേജിൽ മറ്റുള്ളവരുമായി ഉടൻ തന്നെ അവർ ബന്ധപ്പെട്ടിരിക്കുന്നു.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಬಳಕೆದಾರರು ಸೈನ್ ಅಪ್ ಮಾಡಿದಾಗ, ಅವರು ಸೇರಿರುವ ಗ್ರಾಮವನ್ನು ಆಯ್ಕೆ ಮಾಡಿಕೊಳ್ಳಬೇಕು ಮತ್ತು ಆ ಗ್ರಾಮದ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ನಿವಾಸಿಗಳಿಂದ ಒಮ್ಮೆ ಅನುಮೋದಿಸಿದರೆ, ಅವರು ಮತ್ತು ಸಹ ಹಳ್ಳಿಯ ನಿವಾಸಿಗಳು ಮಾತ್ರ ಪ್ರವೇಶವನ್ನು ಹೊಂದಿರುವ ಪುಟದಲ್ಲಿ ತಕ್ಷಣವೇ ಇತರರೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಿದ್ದಾರೆ.",
            },
        },
        default:
            "When a user sign ups, they have to choose the village they belong to, and once approved by existing occupants of that village, they are right away connected with others on a page that only they and fellow village occupants have access to.",
    },
    greenPartText1: {
        languages: {
            1: {
                language: "English",
                translation: "It is private and familier",
            },
            2: {
                language: "hindi",
                translation: "यह निजी और पारिवारिक है",
            },
            3: {
                language: "Marathi",
                translation: "हे खाजगी आणि परिचित आहे",
            },
            7: {
                language: "Punjabi",
                translation: "ਇਹ ਨਿੱਜੀ ਅਤੇ ਜਾਣੂ ਹੈ।",
            },
            6: {
                language: "Bengali",
                translation: "এটা ব্যক্তিগত এবং পরিচিত",
            },
            14: {
                language: "Assamese",
                translation: "ই ব্যক্তিগত আৰু পৰিয়ালৰ",
            },
            18: {
                language: "Nagamese",
                translation: "etu private are beshi familier ase",
            },
            21: {
                language: "Khasi",
                translation: "Kadei ba long marwei bad  ba ia ithuh bha",
            },
            20: {
                language: "Mizo",
                translation: "Private leh hriat hlawh tak a ni",
            },
            19: {
                language: "Manipuri",
                translation: "Masi lanai amadi emung manungi oibani",
            },
            8: {
                language: "Gujarati",
                translation: "તે ખાનગી અને પરિચિત છે",
            },
            17: {
                language: "Tamil",
                translation: "இது தனிப்பட்டது மற்றும் குடும்பமானது",
            },
            5: {
                language: "Telugu",
                translation: "ఇది ప్రైవేట్ మరియు సుపరిచితమైనది",
            },
            10: {
                language: "Malayalam",
                translation: "ഇത് സ്വകാര്യവും കുടുംബപരവുമാണ്",
            },
            4: {
                language: "Kannada",
                translation: "ಇದು ಖಾಸಗಿ ಮತ್ತು ಪರಿಚಿತವಾಗಿದೆ",
            },
        },
        default: "It is private and familier",
    },
    greenPartHeading2: {
        languages: {
            1: {
                language: "English",
                translation:
                    "All in one rural platform to stay connected with your village and the wider rural community",
            },
            2: {
                language: "hindi",
                translation: "अपने गांव और व्यापक ग्रामीण समुदाय से जुड़े रहने के लिए सभी एक ग्रामीण मंच में।",
            },
            3: {
                language: "Marathi",
                translation:
                    "तुमच्या गावाशी आणि व्यापक ग्रामीण समुदायाशी कनेक्ट राहण्यासाठी सर्व एकाच ग्रामीण व्यासपीठावर आहे.",
            },
            7: {
                language: "Punjabi",
                translation: "ਤੁਹਾਡੇ ਪਿੰਡ ਅਤੇ ਵਿਸ਼ਾਲ ਪੇਂਡੂ ਭਾਈਚਾਰੇ ਨਾਲ ਜੁੜੇ ਰਹਿਣ ਲਈ ਸਾਰੇ ਇੱਕ ਗ੍ਰਾਮੀਣ ਪਲੇਟਫਾਰਮ ਵਿੱਚ",
            },
            6: {
                language: "Bengali",
                translation:
                    "আপনার গ্রাম এবং বৃহত্তর গ্রামীণ সম্প্রদায়ের সাথে সংযুক্ত থাকার জন্য সমস্ত এক গ্রামীণ প্ল্যাটফর্ম",
            },
            14: {
                language: "Assamese",
                translation: "আপোনাৰ গাঁও আৰু বহল গ্ৰাম্য সম্প্ৰদায়ৰ সৈতে সংযুক্ত হৈ থাকিবলৈ সকলো এটা গ্ৰাম্য মঞ্চত",
            },
            18: {
                language: "Nagamese",
                translation: "sob ekta deh bosti laka platform nijor bosti aru nijor mnau lokot kota kory boley bara. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Hakwaei ka platform phi lah ban connect bad ka shnong jong phi bad ki wei ki shnong nongkyndong ",
            },
            20: {
                language: "Mizo",
                translation:
                    "I khua leh thingtlang khawtlang zau zawk nena inzawmna nei reng turin leh inpawh reng thei tura platform pakhat ani",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Ama khaktada mayam sijinaba yaraba khunganggi amadi atoppa kanglup kayaga wari watai yaba mapham amani",
            },
            8: {
                language: "Gujarati",
                translation:
                    "તમારા ગામ અને વ્યાપક ગ્રામીણ સમુદાય સાથે જોડાયેલા રહેવા માટે બધા એક ગ્રામીણ પ્લેટફોર્મમાં",
            },
            17: {
                language: "Tamil",
                translation: "உங்கள் கிராமம் மற்றும் பரந்த கிராமப்புற சமூகத்துடன் இணைந்திருக்க ஒரே கிராமப்புற மேடையில்",
            },
            5: {
                language: "Telugu",
                translation:
                    "మీ గ్రామం మరియు విస్తృత గ్రామీణ కమ్యూనిటీతో కనెక్ట్ అవ్వడానికి అన్నీ ఒకే గ్రామీణ ప్లాట్‌ఫారమ్‌లో ఉన్నాయి",
            },
            10: {
                language: "Malayalam",
                translation:
                    "നിങ്ങളുടെ ഗ്രാമവുമായും വിശാലമായ ഗ്രാമീണ സമൂഹവുമായും ബന്ധം നിലനിർത്താൻ എല്ലാം ഒരു ഗ്രാമീണ പ്ലാറ്റ്‌ഫോമിൽ",
            },
            4: {
                language: "Kannada",
                translation: "ನಿಮ್ಮ ಗ್ರಾಮ ಮತ್ತು ವ್ಯಾಪಕ ಗ್ರಾಮೀಣ ಸಮುದಾಯದೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಲು ಒಂದೇ ಗ್ರಾಮೀಣ ವೇದಿಕೆಯಲ್ಲಿ",
            },
        },
        default: "All in one rural platform to stay connected with your village and the wider rural community",
    },
    greenPartSubHeading2: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Everyone in rural India can use Mera Gaon - village residents, local businesses / kiranas and local public institutions. They can then go on to communicate about their daily social and work life like the birth of a child in the family or their surplus perishable agriculture output, advertise new stock availability (as a local business) before the sowing season, and even share information on a Covid 19 vaccination camp as a health centre.",
            },
            2: {
                language: "hindi",
                translation:
                    "ग्रामीण भारत में हर कोई मेरा गाँव का उपयोग कर सकता है - गाँव के निवासी, स्थानीय व्यवसाय / किराना और स्थानीय सार्वजनिक संस्थान। फिर वे अपने दैनिक सामाजिक और कामकाजी जीवन जैसे परिवार में बच्चे के जन्म या अपने अधिशेष खराब होने वाले कृषि उत्पादन के बारे में संवाद करने के लिए आगे बढ़ सकते हैं, बुवाई के मौसम से पहले नए स्टॉक की उपलब्धता (स्थानीय व्यवसाय के रूप में) का विज्ञापन कर सकते हैं, और यहां तक ​​कि जानकारी भी साझा कर सकते हैं। स्वास्थ्य केंद्र के रूप में कोविड 19 टीकाकरण शिविर।",
            },
            3: {
                language: "Marathi",
                translation:
                    "ग्रामीण भारतातील प्रत्येकजण मेरा गाव - गावातील रहिवासी, स्थानिक व्यवसाय/किराणा आणि स्थानिक सार्वजनिक संस्था वापरू शकतो. त्यानंतर ते त्यांच्या दैनंदिन सामाजिक आणि कामाच्या जीवनाविषयी संवाद साधू शकतात जसे की कुटुंबातील मुलाचा जन्म किंवा त्यांचे अतिरिक्त नाशवंत शेती उत्पादन, पेरणीच्या हंगामापूर्वी नवीन स्टॉक उपलब्धतेची (स्थानिक व्यवसाय म्हणून) जाहिरात करू शकतात आणि आरोग्य केंद्र म्हणून कोविड 19 लसीकरण शिबिर. माहिती देखील शेअर करू शकतात. ",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਗ੍ਰਾਮੀਣ ਭਾਰਤ ਵਿੱਚ ਹਰ ਕੋਈ ਮੇਰਾ ਗਾਓਂ - ਪਿੰਡ ਵਾਸੀ, ਸਥਾਨਕ ਕਾਰੋਬਾਰ/ਕਿਰਾਨਾ ਅਤੇ ਸਥਾਨਕ ਜਨਤਕ ਸੰਸਥਾਵਾਂ ਦੀ ਵਰਤੋਂ ਕਰ ਸਕਦਾ ਹੈ। ਫਿਰ ਉਹ ਆਪਣੇ ਰੋਜ਼ਾਨਾ ਸਮਾਜਿਕ ਅਤੇ ਕੰਮ ਦੇ ਜੀਵਨ ਬਾਰੇ ਸੰਚਾਰ ਕਰ ਸਕਦੇ ਹਨ ਜਿਵੇਂ ਕਿ ਪਰਿਵਾਰ ਵਿੱਚ ਇੱਕ ਬੱਚੇ ਦੇ ਜਨਮ ਜਾਂ ਉਹਨਾਂ ਦੇ ਵਾਧੂ ਨਾਸ਼ਵਾਨ ਖੇਤੀ ਉਤਪਾਦਨ, ਬਿਜਾਈ ਦੇ ਸੀਜ਼ਨ ਤੋਂ ਪਹਿਲਾਂ ਨਵੇਂ ਸਟਾਕ ਦੀ ਉਪਲਬਧਤਾ (ਸਥਾਨਕ ਕਾਰੋਬਾਰ ਵਜੋਂ) ਦੀ ਘੋਸ਼ਣਾ ਕਰ ਸਕਦੇ ਹਨ, ਅਤੇ ਇਸ ਬਾਰੇ ਜਾਣਕਾਰੀ ਵੀ ਸਾਂਝੀ ਕਰ ਸਕਦੇ ਹਨ। ਇੱਕ ਸਿਹਤ ਕੇਂਦਰ ਵਜੋਂ ਇੱਕ ਕੋਵਿਡ 19 ਟੀਕਾਕਰਨ ਕੈਂਪ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "গ্রামীণ ভারতে সবাই মেরা গাঁও ব্যবহার করতে পারে - গ্রামের বাসিন্দা, স্থানীয় ব্যবসা/কিরানা এবং স্থানীয় সরকারী প্রতিষ্ঠান। তারপরে তারা তাদের দৈনন্দিন সামাজিক এবং কাজের জীবন সম্পর্কে যোগাযোগ করতে পারে ",
            },
            14: {
                language: "Assamese",
                translation:
                    "ভাৰতৰ গ্ৰাম্য অঞ্চলৰ সকলোৱে ব্যৱহাৰ কৰিব পাৰে মেৰা গাওঁ - গাঁৱৰ বাসিন্দা, স্থানীয় ব্যৱসায়ী / কিৰানা আৰু স্থানীয় ৰাজহুৱা অনুষ্ঠান। তাৰ পিছত তেওঁলোকে পৰিয়ালত শিশুৰ জন্ম বা তেওঁলোকৰ উদ্বৃত্ত নষ্ট হ’ব পৰা কৃষিৰ উৎপাদনৰ দৰে নিজৰ দৈনন্দিন সামাজিক আৰু কৰ্মজীৱনৰ বিষয়ে যোগাযোগ কৰিব পাৰে, বীজ সিঁচাৰ বতৰৰ আগতে নতুন ষ্টক উপলব্ধতাৰ বিজ্ঞাপন দিব পাৰে (স্থানীয় ব্যৱসায় হিচাপে), আনকি তথ্যও ভাগ-বতৰা কৰিব পাৰে স্বাস্থ্য কেন্দ্ৰ হিচাপে Covid 19 টিকাকৰণ শিবিৰ।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "sob manu india laka bosti kan deh taka kan Mera Gaon jola boley barey bo- bosti deh taka kan, local bssiness kora kan/ local public institutions. daikan sob nijor kam kora laka aru daikan laka bacha laka janam kan family deh aru keti deh bhal bara kam howa kan, nuton stock kiman ase (local bussiness kan) season nahowa akey deh aru covid 19 kan laka vaccination camp kan sob health laka kota neshina kota kory boley barey bo. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Baroh ha jaka nongkyndong ki lah pyndonkam Mera Gaon - Ki nongshong shnong , local businesses / kiranas and ki jaka hikai paidbah. Ki lah  ban iakren shaphang   ka jingim ba man ka sngi bad ki jingtrei ka jing ioh ia ki khun ha  iing lane kaba tam jong ki mar rep mar riang, advertise ki stock bathymmai ba mih   (as a local business) shwa ka por aoim thung, bad share  ia ki jingtip ha   Covid 19 vaccination camp kum ka health centre.",
            },
            20: {
                language: "Mizo",
                translation:
                    "India thingtlang mi zawng zawngin Mera Gaon hi an hmang thei a - khuaa chengte, tualchhung sumdawnna / kirana leh tualchhung vantlang inkhawmpui te an hmang thei a ni. Chumi hnuah chuan an nitin khawtlang leh hnathawhna nun chhungkuaa naupang pian emaw, an loneih chhuah chhe thei tam zawk emaw ang chi te chu an inbiak zui thei a, thlai chin hun hmain stock thar awm theihna tur (tualchhûng sumdawnna angin) an puang chhuak thei a, chungchânga thu hriatte pawh an insem thei bawk health centre atan Covid 19 vaccination camp siam a ni.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Khunganggi meeoi khudingmak Mera Gaon sijinaba yare-Khungangi leipham, akoibagi karbar/ amadi meyamgi institutions. Nongmagi oiba thoudok kayaga mari leinaba thabak anagng pokpa ntraga loubagi maramda tangaiphadaba, anouba stock kaya phangani (local business oina) pot thaba matam oidri mamangda, amadi information pibiyu Covid-19 Vaccination camp mari leinana health centre na.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "ગ્રામીણ ભારતમાં દરેક વ્યક્તિ મેરા ગાંવનો ઉપયોગ કરી શકે છે - ગામના રહેવાસીઓ, સ્થાનિક વ્યવસાયો/કિરાણા અને સ્થાનિક જાહેર સંસ્થાઓ.  ત્યારબાદ તેઓ તેમના રોજિંદા સામાજિક અને કાર્યકારી જીવન વિશે વાતચીત કરી શકે છે જેમ કે કુટુંબમાં બાળકનો જન્મ અથવા તેમના ફાજલ નાશવંત કૃષિ ઉત્પાદન, વાવણીની મોસમ પહેલાં નવા સ્ટોકની ઉપલબ્ધતા (સ્થાનિક વ્યવસાય તરીકે)ની જાહેરાત કરી શકે છે અને માહિતી પણ શેર કરી શકે છે.  આરોગ્ય કેન્દ્ર તરીકે કોવિડ 19 રસીકરણ કેમ્પ.",
            },
            17: {
                language: "Tamil",
                translation:
                    "கிராமப்புற இந்தியாவில் உள்ள அனைவரும் மேரா காவ்னைப் பயன்படுத்தலாம் - கிராமவாசிகள், உள்ளூர் வணிகங்கள் / கிரணங்கள் மற்றும் உள்ளூர் பொது நிறுவனங்கள். குடும்பத்தில் குழந்தை பிறப்பது அல்லது அழிந்துபோகும் விவசாயத்தின் உபரி போன்ற அவர்களின் அன்றாட சமூக மற்றும் வேலை வாழ்க்கையைப் பற்றி அவர்கள் தொடர்ந்து பேசலாம், விதைப்புப் பருவத்திற்கு முன் புதிய பங்குகள் கிடைப்பதை (உள்ளூர் வணிகமாக) விளம்பரப்படுத்தலாம் மற்றும் தகவல்களைப் பகிர்ந்து கொள்ளலாம். ஒரு சுகாதார மையமாக கோவிட் 19 தடுப்பூசி முகாம்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "గ్రామీణ భారతదేశంలోని ప్రతి ఒక్కరూ మేరా గావ్ - గ్రామ నివాసితులు, స్థానిక వ్యాపారాలు / కిరణాలు మరియు స్థానిక ప్రభుత్వ సంస్థలను ఉపయోగించవచ్చు. వారు కుటుంబంలో పిల్లల పుట్టుక లేదా వారి మిగులు పాడయ్యే వ్యవసాయ ఉత్పత్తి వంటి వారి రోజువారీ సామాజిక మరియు పని జీవితం గురించి కమ్యూనికేట్ చేయవచ్చు, విత్తే సీజన్‌కు ముందు కొత్త స్టాక్ లభ్యతను (స్థానిక వ్యాపారంగా) ప్రచారం చేయవచ్చు మరియు సమాచారాన్ని పంచుకోవచ్చు. ఆరోగ్య కేంద్రంగా కోవిడ్ 19 టీకా శిబిరం.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "ഗ്രാമീണ ഇന്ത്യയിലെ എല്ലാവർക്കും മേരാ ഗാവ് ഉപയോഗിക്കാം - ഗ്രാമവാസികൾ, പ്രാദേശിക ബിസിനസുകൾ / കിരണങ്ങൾ, പ്രാദേശിക പൊതു സ്ഥാപനങ്ങൾ. കുടുംബത്തിലെ ഒരു കുട്ടിയുടെ ജനനം അല്ലെങ്കിൽ അവരുടെ മിച്ചം വരുന്ന കാർഷിക ഉൽപ്പാദനം പോലെയുള്ള അവരുടെ ദൈനംദിന സാമൂഹിക, തൊഴിൽ ജീവിതത്തെക്കുറിച്ച് ആശയവിനിമയം നടത്താനും വിതയ്ക്കുന്നതിന് മുമ്പ് പുതിയ സ്റ്റോക്ക് ലഭ്യത (ഒരു പ്രാദേശിക ബിസിനസ്സ് എന്ന നിലയിൽ) പരസ്യം ചെയ്യാനും വിവരങ്ങൾ പങ്കിടാനും അവർക്ക് കഴിയും. ആരോഗ്യ കേന്ദ്രമായി ഒരു കോവിഡ് 19 വാക്സിനേഷൻ ക്യാമ്പ്.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಗ್ರಾಮೀಣ ಭಾರತದ ಪ್ರತಿಯೊಬ್ಬರೂ ಮೇರಾ ಗಾಂವ್ ಅನ್ನು ಬಳಸಬಹುದು - ಹಳ್ಳಿಯ ನಿವಾಸಿಗಳು, ಸ್ಥಳೀಯ ವ್ಯವಹಾರಗಳು / ಕಿರಣಗಳು ಮತ್ತು ಸ್ಥಳೀಯ ಸಾರ್ವಜನಿಕ ಸಂಸ್ಥೆಗಳು.  ನಂತರ ಅವರು ಕುಟುಂಬದಲ್ಲಿ ಮಗುವಿನ ಜನನ ಅಥವಾ ಅವರ ಹೆಚ್ಚುವರಿ ಹಾಳಾಗುವ ಕೃಷಿ ಉತ್ಪನ್ನಗಳಂತಹ ತಮ್ಮ ದೈನಂದಿನ ಸಾಮಾಜಿಕ ಮತ್ತು ಕೆಲಸದ ಜೀವನದ ಬಗ್ಗೆ ಸಂವಹನ ನಡೆಸಬಹುದು, ಬಿತ್ತನೆ ಋತುವಿನ ಮೊದಲು ಹೊಸ ಸ್ಟಾಕ್ ಲಭ್ಯತೆಯನ್ನು (ಸ್ಥಳೀಯ ವ್ಯಾಪಾರವಾಗಿ) ಜಾಹೀರಾತು ಮಾಡಬಹುದು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳಬಹುದು.  ಆರೋಗ್ಯ ಕೇಂದ್ರವಾಗಿ ಕೋವಿಡ್ 19 ಲಸಿಕೆ ಶಿಬಿರ.",
            },
        },
        default:
            "Everyone in rural India can use Mera Gaon - village residents, local businesses / kiranas and local public institutions. They can then go on to communicate about their daily social and work life like the birth of a child in the family or their surplus perishable agriculture output, advertise new stock availability (as a local business) before the sowing season, and even share information on a Covid 19 vaccination camp as a health centre.",
    },
    greenPartText2: {
        languages: {
            1: {
                language: "English",
                translation: "All in a safe and secure environment.",
            },
            2: {
                language: "hindi",
                translation: "सभी एक सुरक्षित और सुरक्षित वातावरण में।",
            },
            3: {
                language: "Marathi",
                translation: "सर्व सुरक्षित आणि गुप्त वातावरणात.",
            },
            7: {
                language: "Punjabi",
                translation: "ਸਾਰੇ ਇੱਕ ਸੁਰੱਖਿਅਤ ਅਤੇ ਸੁਰੱਖਿਅਤ ਵਾਤਾਵਰਣ ਵਿੱਚ.",
            },
            6: {
                language: "Bengali",
                translation: "সব একটি নিরাপদ এবং নিরাপদ পরিবেশে.",
            },
            14: {
                language: "Assamese",
                translation: "সকলো নিৰাপদ আৰু সুৰক্ষিত পৰিৱেশত।",
            },
            18: {
                language: "Nagamese",
                translation: "sob manu jaka bhal aru environment deh ",
            },
            21: {
                language: "Khasi",
                translation: "Baroh ha ka jaka ba shngain.",
            },
            20: {
                language: "Mizo",
                translation: "Environment him takah a awm vek.",
            },
            19: {
                language: "Manipuri",
                translation: "Pumnamak aphaba environment kayagani",
            },
            8: {
                language: "Gujarati",
                translation: "બધા સુરક્ષિત અને સુરક્ષિત વાતાવરણમાં.",
            },
            17: {
                language: "Tamil",
                translation: "அனைத்தும் பாதுகாப்பான மற்றும் பாதுகாப்பான சூழலில்.",
            },
            5: {
                language: "Telugu",
                translation: "అన్నీ సురక్షితమైన మరియు సురక్షితమైన వాతావరణంలో.",
            },
            10: {
                language: "Malayalam",
                translation: "എല്ലാം സുരക്ഷിതവും സുരക്ഷിതവുമായ അന്തരീക്ഷത്തിൽ.",
            },
            4: {
                language: "Kannada",
                translation: "ಎಲ್ಲಾ ಸುರಕ್ಷಿತ ಮತ್ತು ಸುರಕ್ಷಿತ ಪರಿಸರದಲ್ಲಿ.",
            },
        },
        default: "All in a safe and secure environment.",
    },
    ss1: {
        languages: {
            1: {
                language: "English",
                translation: "Private Village Feed",
            },
            2: {
                language: "hindi",
                translation: "निजी ग्राम फ़ीड",
            },
            3: {
                language: "Marathi",
                translation: "खाजगी गाव फीड",
            },
            7: {
                language: "Punjabi",
                translation: "ਪ੍ਰਾਈਵੇਟ ਪਿੰਡ ਫੀਡ",
            },
            6: {
                language: "Bengali",
                translation: "প্রাইভেট ভিলেজ ফিড",
            },
            14: {
                language: "Assamese",
                translation: "ব্যক্তিগত গাঁৱৰ খাদ্য",
            },
            18: {
                language: "Nagamese",
                translation: "private ka laka bosti ",
            },
            21: {
                language: "Khasi",
                translation: " Feed ba long marwei jong ka shnong",
            },
            20: {
                language: "Mizo",
                translation: "Mimal Khaw Feed",
            },
            19: {
                language: "Manipuri",
                translation: "Lanaigi oiba khunganggi feed",
            },
            8: {
                language: "Gujarati",
                translation: "ખાનગી ગામ ફીડ",
            },
            17: {
                language: "Tamil",
                translation: "தனியார் கிராம உணவு",
            },
            5: {
                language: "Telugu",
                translation: "ప్రైవేట్ విలేజ్ ఫీడ్",
            },
            10: {
                language: "Malayalam",
                translation: "സ്വകാര്യ വില്ലേജ് ഫീഡ്",
            },
            4: {
                language: "Kannada",
                translation: "ಖಾಸಗಿ ಗ್ರಾಮ ಫೀಡ್",
            },
        },
        default: "Private Village Feed",
    },
    ss1d: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Share latest updates about your personal and work life with your fellow villagers in a closed and secured network.",
            },
            2: {
                language: "hindi",
                translation:
                    "एक बंद और सुरक्षित नेटवर्क में अपने साथी ग्रामीणों के साथ अपने व्यक्तिगत और कामकाजी जीवन के बारे में नवीनतम अपडेट साझा करें।",
            },
            3: {
                language: "Marathi",
                translation:
                    "बंद आणि सुरक्षित नेटवर्कमध्ये तुमच्या सहकारी गावकऱ्यांसोबत तुमच्या वैयक्तिक आणि कामाच्या जीवनाविषयी नवीनतम अपडेट शेअर करा.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਇੱਕ ਬੰਦ ਅਤੇ ਸੁਰੱਖਿਅਤ ਨੈੱਟਵਰਕ ਵਿੱਚ ਆਪਣੇ ਸਾਥੀ ਪਿੰਡ ਵਾਸੀਆਂ ਨਾਲ ਆਪਣੀ ਨਿੱਜੀ ਅਤੇ ਕੰਮਕਾਜੀ ਜੀਵਨ ਬਾਰੇ ਨਵੀਨਤਮ ਅੱਪਡੇਟ ਸਾਂਝੇ ਕਰੋ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "একটি বন্ধ এবং সুরক্ষিত নেটওয়ার্কে আপনার সহকর্মী গ্রামবাসীদের সাথে আপনার ব্যক্তিগত এবং কর্মজীবনের সাম্প্রতিক আপডেটগুলি ভাগ করুন৷",
            },
            14: {
                language: "Assamese",
                translation:
                    "আপোনাৰ ব্যক্তিগত আৰু কৰ্মজীৱনৰ শেহতীয়া আপডেটসমূহ আপোনাৰ সহযোগী গাঁৱৰ মানুহৰ সৈতে বন্ধ আৰু সুৰক্ষিত নেটৱৰ্কত শ্বেয়াৰ কৰক।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "apne laka nuton personal aru kam laka nijor bosti manu kan ki kota kory boley barey bo kumti aru secure network deh. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Share ki update ba thymmai shaphang ka jinglong shimet bad jingim kam bad ki para nong shong shnong jong phi ha ba shngain network.",
            },
            20: {
                language: "Mizo",
                translation:
                    "I mimal leh hnathawhna nun chungchanga update thar ber berte chu i khaw mipuite hnenah network him takah share rawh.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Adomgi oiba punsigi thabak kaya share touba yagani adomgi khungangi meeoi kayada yamna secured oiba network ta.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "બંધ અને સુરક્ષિત નેટવર્કમાં તમારા સાથી ગ્રામજનો સાથે તમારા અંગત અને કાર્ય જીવન વિશે નવીનતમ અપડેટ્સ શેર કરો.",
            },
            17: {
                language: "Tamil",
                translation:
                    "உங்கள் தனிப்பட்ட மற்றும் பணி வாழ்க்கை பற்றிய சமீபத்திய அறிவிப்புகளை உங்கள் சக கிராம மக்களுடன் மூடிய மற்றும் பாதுகாப்பான நெட்வொர்க்கில் பகிரவும்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "మూసివేసిన మరియు సురక్షితమైన నెట్‌వర్క్‌లో మీ తోటి గ్రామస్థులతో మీ వ్యక్తిగత మరియు పని జీవితం గురించి తాజా అప్‌డేట్‌లను షేర్ చేయండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "അടച്ചതും സുരക്ഷിതവുമായ നെറ്റ്‌വർക്കിൽ നിങ്ങളുടെ സഹ ഗ്രാമീണരുമായി നിങ്ങളുടെ വ്യക്തിപരവും തൊഴിൽ ജീവിതവുമായുള്ള ഏറ്റവും പുതിയ അപ്‌ഡേറ്റുകൾ പങ്കിടുക.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಮುಚ್ಚಿದ ಮತ್ತು ಸುರಕ್ಷಿತ ನೆಟ್‌ವರ್ಕ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಸಹ ಗ್ರಾಮಸ್ಥರೊಂದಿಗೆ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಮತ್ತು ಕೆಲಸದ ಜೀವನದ ಕುರಿತು ಇತ್ತೀಚಿನ ನವೀಕರಣಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ.",
            },
        },
        default:
            "Share latest updates about your personal and work life with your fellow villagers in a closed and secured network.",
    },
    ss2: {
        languages: {
            1: {
                language: "English",
                translation: "Apnon Se Poocho",
            },
            2: {
                language: "hindi",
                translation: "अपनों से पूछो",
            },
            3: {
                language: "Marathi",
                translation: "आपल्याना विचारा.",
            },
            7: {
                language: "Punjabi",
                translation: "ਆਪਨ ਸੇ ਪੂਛੋ",
            },
            6: {
                language: "Bengali",
                translation: "আপন সে পুছো",
            },
            14: {
                language: "Assamese",
                translation: "আপোনজনক সুধিব",
            },
            18: {
                language: "Nagamese",
                translation: "Apnon Se Poocho",
            },
            21: {
                language: "Khasi",
                translation: "Apnon Se Poocho",
            },
            20: {
                language: "Mizo",
                translation: "Apnon Se Poocho",
            },
            19: {
                language: "Manipuri",
                translation: "Apnon Se Poocho",
            },
            8: {
                language: "Gujarati",
                translation: "અપનોં સે પૂછો",
            },
            17: {
                language: "Tamil",
                translation: "அப்னோன் சே பூச்சோ",
            },
            5: {
                language: "Telugu",
                translation: "అప్నోన్ సే పూచో",
            },
            10: {
                language: "Malayalam",
                translation: "അപ്നോൻ സേ പൂച്ചോ",
            },
            4: {
                language: "Kannada",
                translation: "ಅಪ್ನೋನ್ ಸೆ ಪೂಚೋ",
            },
        },
        default: "Apnon Se Poocho",
    },
    ss2d: {
        languages: {
            1: {
                language: "English",
                translation: "Farmers can ask their agri-related queries to other farmers across other country.",
            },
            2: {
                language: "hindi",
                translation: "किसान अपने कृषि संबंधी प्रश्न दूसरे देश के अन्य किसानों से पूछ सकते हैं।",
            },
            3: {
                language: "Marathi",
                translation: "शेतकरी त्यांच्या शेतीशी संबंधित प्रश्न इतर देशातील इतर शेतकऱ्यांना विचारू शकतात.",
            },
            7: {
                language: "Punjabi",
                translation: "ਕਿਸਾਨ ਆਪਣੇ ਖੇਤੀ ਸੰਬੰਧੀ ਸਵਾਲ ਦੂਜੇ ਦੇਸ਼ ਦੇ ਦੂਜੇ ਕਿਸਾਨਾਂ ਨੂੰ ਪੁੱਛ ਸਕਦੇ ਹਨ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "কৃষকরা তাদের কৃষি সংক্রান্ত প্রশ্ন অন্যান্য দেশের অন্যান্য কৃষকদের কাছে জিজ্ঞাসা করতে পারেন।",
            },
            14: {
                language: "Assamese",
                translation: "কৃষকসকলে তেওঁলোকৰ কৃষি সম্পৰ্কীয় প্ৰশ্নসমূহ সমগ্ৰ দেশৰ আন কৃষকসকলক সুধিব পাৰে।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "keti kora manu keti kan laka kota kory boley bari bo nijor keti kora mnau kiman ase atu desh deh.",
            },
            21: {
                language: "Khasi",
                translation: "Ki nongrep ki lah ban kylli ha kaba iadei bad ka kam rep kam riang sha kiwei pat ki ri.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Loneitute chuan an loneih kawnga zawhnate chu ram danga loneitu dangte hnenah an zawt thei a ni.",
            },
            19: {
                language: "Manipuri",
                translation: "Loumi singna makhoigi loubaga mari leinaba wahang hangba yagani atoppa loumi kayada.",
            },
            8: {
                language: "Gujarati",
                translation: "ખેડૂતો તેમના કૃષિ સંબંધિત પ્રશ્નો અન્ય દેશના અન્ય ખેડૂતોને પૂછી શકે છે.",
            },
            17: {
                language: "Tamil",
                translation:
                    "விவசாயிகள் தங்கள் விவசாயம் தொடர்பான கேள்விகளை மற்ற நாடு முழுவதும் உள்ள மற்ற விவசாயிகளிடம் கேட்கலாம்.",
            },
            5: {
                language: "Telugu",
                translation: "రైతులు తమ వ్యవసాయ సంబంధిత సందేహాలను ఇతర దేశంలోని ఇతర రైతులకు అడగవచ్చు.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "കർഷകർക്ക് അവരുടെ കാർഷിക സംബന്ധിയായ ചോദ്യങ്ങൾ മറ്റ് രാജ്യത്തുടനീളമുള്ള മറ്റ് കർഷകരോട് ചോദിക്കാം.",
            },
            4: {
                language: "Kannada",
                translation: "ರೈತರು ತಮ್ಮ ಕೃಷಿ ಸಂಬಂಧಿತ ಪ್ರಶ್ನೆಗಳನ್ನು ಇತರ ದೇಶದ ಇತರ ರೈತರಿಗೆ ಕೇಳಬಹುದು.",
            },
        },
        default: "Farmers can ask their agri-related queries to other farmers across other country.",
    },
    ss3: {
        languages: {
            1: {
                language: "English",
                translation: "Local Sales, Offers and Discounts",
            },
            2: {
                language: "hindi",
                translation: "स्थानीय बिक्री, ऑफ़र और छूट",
            },
            3: {
                language: "Marathi",
                translation: "स्थानिक विक्री, ऑफर आणि सवलत",
            },
            7: {
                language: "Punjabi",
                translation: "ਸਥਾਨਕ ਵਿਕਰੀ, ਪੇਸ਼ਕਸ਼ਾਂ ਅਤੇ ਛੋਟਾਂ",
            },
            6: {
                language: "Bengali",
                translation: "স্থানীয় বিক্রয়, অফার এবং ডিসকাউন্ট",
            },
            14: {
                language: "Assamese",
                translation: "স্থানীয় বিক্ৰী, অফাৰ আৰু ৰেহাই",
            },
            18: {
                language: "Nagamese",
                translation: "local sales, offers aru discounts",
            },
            21: {
                language: "Khasi",
                translation: "Local Sales, Offers and Discounts",
            },
            20: {
                language: "Mizo",
                translation: "Local Sales, Offer leh Discount te a awm bawk",
            },
            19: {
                language: "Manipuri",
                translation: "Local sales, Offers amadi Discounts",
            },
            8: {
                language: "Gujarati",
                translation: "સ્થાનિક વેચાણ, ઑફર્સ અને ડિસ્કાઉન્ટ",
            },
            17: {
                language: "Tamil",
                translation: "உள்ளூர் விற்பனை, சலுகைகள் மற்றும் தள்ளுபடிகள்",
            },
            5: {
                language: "Telugu",
                translation: "స్థానిక విక్రయాలు, ఆఫర్‌లు మరియు తగ్గింపులు",
            },
            10: {
                language: "Malayalam",
                translation: "പ്രാദേശിക വിൽപ്പന, ഓഫറുകൾ, കിഴിവുകൾ",
            },
            4: {
                language: "Kannada",
                translation: "ಸ್ಥಳೀಯ ಮಾರಾಟಗಳು, ಕೊಡುಗೆಗಳು ಮತ್ತು ರಿಯಾಯಿತಿಗಳು",
            },
        },
        default: "Local Sales, Offers and Discounts",
    },
    ss3d: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Create and learn about local items for sale, and also latest offers and discounts by local businesses.",
            },
            2: {
                language: "hindi",
                translation:
                    "बिक्री के लिए स्थानीय वस्तुओं और स्थानीय व्यवसायों द्वारा नवीनतम ऑफ़र और छूट के बारे में जानें ।",
            },
            3: {
                language: "Marathi",
                translation:
                    "विक्रीसाठी स्थानिक वस्तू तयार करा आणि तसेच स्थानिक व्यवसायांद्वारे नवीनतम ऑफर आणि सवलत देखील जाणून घ्या",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਵਿਕਰੀ ਲਈ ਸਥਾਨਕ ਆਈਟਮਾਂ ਬਣਾਓ ਅਤੇ ਸਿੱਖੋ, ਅਤੇ ਸਥਾਨਕ ਕਾਰੋਬਾਰਾਂ ਦੁਆਰਾ ਨਵੀਨਤਮ ਪੇਸ਼ਕਸ਼ਾਂ ਅਤੇ ਛੋਟਾਂ ਵੀ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "বিক্রয়ের জন্য স্থানীয় আইটেমগুলি তৈরি করুন এবং শিখুন, এবং স্থানীয় ব্যবসার দ্বারা সাম্প্রতিক অফার এবং ডিসকাউন্টগুলিও।",
            },
            14: {
                language: "Assamese",
                translation:
                    "বিক্ৰীৰ বাবে স্থানীয় সামগ্ৰী সৃষ্টি আৰু শিকিব, আৰু লগতে স্থানীয় ব্যৱসায়ীসকলৰ শেহতীয়া অফাৰ আৰু ৰেহাই।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "banai kina aru local saman laka sheki bhi sale kan karney, aru nuton offers aru discount local bussinesses manu kan bara  ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Shna bad ioh jingtip ki mar ba pynmih na shnong na bynta ban die,bad ruh ki offers bathymmai bad discount da ka local businesses",
            },
            20: {
                language: "Mizo",
                translation:
                    "Local item hralh tur siam leh zir la, tualchhung sumdawngte offer leh discount thar ber ber pawh zir rawh.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Local oiba item kaya yonaba semmu, amadi anouba offers amadi discounts kaya local toubiyu local karbarda.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "વેચાણ માટેની સ્થાનિક વસ્તુઓ અને સ્થાનિક વ્યવસાયો દ્વારા નવીનતમ ઑફરો અને ડિસ્કાઉન્ટ વિશે બનાવો અને જાણો.",
            },
            17: {
                language: "Tamil",
                translation:
                    "விற்பனைக்கான உள்ளூர் பொருட்களை உருவாக்கி அறிந்துகொள்ளுங்கள், மேலும் உள்ளூர் வணிகங்கள் வழங்கும் சமீபத்திய சலுகைகள் மற்றும் தள்ளுபடிகள்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "అమ్మకానికి స్థానిక వస్తువులను సృష్టించండి మరియు తెలుసుకోండి మరియు స్థానిక వ్యాపారాల ద్వారా తాజా ఆఫర్‌లు మరియు తగ్గింపులను కూడా పొందండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "വിൽപ്പനയ്‌ക്കുള്ള പ്രാദേശിക ഇനങ്ങൾ സൃഷ്‌ടിക്കുകയും അറിയുകയും ചെയ്യുക, കൂടാതെ പ്രാദേശിക ബിസിനസുകൾ നൽകുന്ന ഏറ്റവും പുതിയ ഓഫറുകളും കിഴിവുകളും.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಸ್ಥಳೀಯ ವಸ್ತುಗಳ ಮಾರಾಟಕ್ಕಾಗಿ ಮತ್ತು ಇತ್ತೀಚಿನ ಕೊಡುಗೆಗಳು ಮತ್ತು ರಿಯಾಯಿತಿಗಳ ಕುರಿತು ಸ್ಥಳೀಯ ವ್ಯಾಪಾರಗಳಿಂದ ರಚಿಸಿ ಮತ್ತು ತಿಳಿದುಕೊಳ್ಳಿ.",
            },
        },
        default:
            "Create and learn about local items for sale, and also latest offers and discounts by local businesses.",
    },
    ss4: {
        languages: {
            1: {
                language: "English",
                translation: "Announcements from public institutions",
            },
            2: {
                language: "hindi",
                translation: "aaसार्वजनिक संस्थानों से घोषणाएँaaa",
            },
            3: {
                language: "Marathi",
                translation: "सार्वजनिक संस्थांकडून घोषणा",
            },
            7: {
                language: "Punjabi",
                translation: "ਜਨਤਕ ਸੰਸਥਾਵਾਂ ਤੋਂ ਘੋਸ਼ਣਾਵਾਂ",
            },
            6: {
                language: "Bengali",
                translation: "সরকারি প্রতিষ্ঠান থেকে ঘোষণা",
            },
            14: {
                language: "Assamese",
                translation: "ৰাজহুৱা প্ৰতিষ্ঠানৰ পৰা ঘোষণা",
            },
            18: {
                language: "Nagamese",
                translation: "annnouncements public institutions  bara",
            },
            21: {
                language: "Khasi",
                translation: "Jingpynbna na ki jaka hikai paidbah",
            },
            20: {
                language: "Mizo",
                translation: "Mipui institution hrang hrang atanga thuchhuah te",
            },
            19: {
                language: "Manipuri",
                translation: "Announcement piri public institutions dagi",
            },
            8: {
                language: "Gujarati",
                translation: "જાહેર સંસ્થાઓ તરફથી જાહેરાતો",
            },
            17: {
                language: "Tamil",
                translation: "பொது நிறுவனங்களில் இருந்து அறிவிப்புகள்",
            },
            5: {
                language: "Telugu",
                translation: "ప్రభుత్వ సంస్థల నుండి ప్రకటనలు",
            },
            10: {
                language: "Malayalam",
                translation: "പൊതു സ്ഥാപനങ്ങളിൽ നിന്നുള്ള അറിയിപ്പുകൾ",
            },
            4: {
                language: "Kannada",
                translation: "ಸಾರ್ವಜನಿಕ ಸಂಸ್ಥೆಗಳಿಂದ ಪ್ರಕಟಣೆಗಳು",
            },
        },
        default: "Announcements from public institutions",
    },
    ss4d: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Stay up to date with latest announcements by your local institutions like a Covid-19 camp run by the local clinic.",
            },
            2: {
                language: "hindi",
                translation:
                    "अपने स्थानीय संस्थानों द्वारा नवीनतम घोषणाओं के साथ अद्यतित रहें, जैसे कि स्थानीय क्लिनिक द्वारा संचालित कोविड-19 शिविर।",
            },
            3: {
                language: "Marathi",
                translation:
                    "स्थानिक दवाखान्याद्वारे चालवल्या जाणार्‍या कोविड-19 शिबिरासारख्या तुमच्या स्थानिक संस्थांच्या नवीनतम घोषणांसह अद्ययावत रहा.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਸਥਾਨਕ ਕਲੀਨਿਕ ਦੁਆਰਾ ਚਲਾਏ ਜਾਣ ਵਾਲੇ ਕੋਵਿਡ-19 ਕੈਂਪ ਵਰਗੀਆਂ ਤੁਹਾਡੀਆਂ ਸਥਾਨਕ ਸੰਸਥਾਵਾਂ ਦੁਆਰਾ ਨਵੀਨਤਮ ਘੋਸ਼ਣਾਵਾਂ ਨਾਲ ਅੱਪ ਟੂ ਡੇਟ ਰਹੋ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "স্থানীয় ক্লিনিক দ্বারা পরিচালিত একটি Covid-19 ক্যাম্পের মতো আপনার স্থানীয় প্রতিষ্ঠানের সাম্প্রতিক ঘোষণাগুলির সাথে আপ টু ডেট থাকুন।",
            },
            14: {
                language: "Assamese",
                translation:
                    "স্থানীয় ক্লিনিকৰ দ্বাৰা পৰিচালিত Covid-19 শিবিৰৰ দৰে আপোনাৰ স্থানীয় প্ৰতিষ্ঠানৰ শেহতীয়া ঘোষণাৰ বিষয়ে অৱগত থাকক।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "nuton announcements deh kobor rakey bhi apne laka local institutions kan nishina covid 19 laka camp kan local clinic kan bara jola kan",
            },
            21: {
                language: "Khasi",
                translation:
                    "Ioh jingpynbna  da ki jaka hikai paidbah kum Covid-19 camp  ba la pyniaid da ki clinic ba na shnong.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Tualchhung clinic-in Covid-19 camp a kalpui ang chi in tualchhung institution-te puan thar ber berte chu ngaihven reng ang che.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Anouba announcement kaya khanbiyu adomgi local institutions kayadagi khudam oina Covid-19 camp local clinic kayana chalaiba.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "સ્થાનિક ક્લિનિક દ્વારા ચલાવવામાં આવતી કોવિડ-19 શિબિર જેવી તમારી સ્થાનિક સંસ્થાઓ દ્વારા નવીનતમ ઘોષણાઓ સાથે અદ્યતન રહો.",
            },
            17: {
                language: "Tamil",
                translation:
                    "உள்ளூர் கிளினிக் நடத்தும் கோவிட்-19 முகாம் போன்ற உங்கள் உள்ளூர் நிறுவனங்களின் சமீபத்திய அறிவிப்புகளுடன் புதுப்பித்த நிலையில் இருங்கள்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "స్థానిక క్లినిక్ నిర్వహించే కోవిడ్-19 క్యాంప్ వంటి మీ స్థానిక సంస్థల తాజా ప్రకటనలతో తాజాగా ఉండండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "പ്രാദേശിക ക്ലിനിക്ക് നടത്തുന്ന കോവിഡ്-19 ക്യാമ്പ് പോലുള്ള നിങ്ങളുടെ പ്രാദേശിക സ്ഥാപനങ്ങളുടെ ഏറ്റവും പുതിയ അറിയിപ്പുകൾ ഉപയോഗിച്ച് കാലികമായി തുടരുക.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಸ್ಥಳೀಯ ಕ್ಲಿನಿಕ್ ನಡೆಸುವ ಕೋವಿಡ್-19 ಶಿಬಿರದಂತಹ ನಿಮ್ಮ ಸ್ಥಳೀಯ ಸಂಸ್ಥೆಗಳ ಇತ್ತೀಚಿನ ಪ್ರಕಟಣೆಗಳೊಂದಿಗೆ ನವೀಕೃತವಾಗಿರಿ.",
            },
        },
        default:
            "Stay up to date with latest announcements by your local institutions like a Covid-19 camp run by the local clinic.",
    },
    ss5: {
        languages: {
            1: {
                language: "English",
                translation: "Rural Centric Videos",
            },
            2: {
                language: "hindi",
                translation: "ग्रामीण केंद्रित वीडियो",
            },
            3: {
                language: "Marathi",
                translation: "ग्रामीण केंद्रीत व्हिडिओ",
            },
            7: {
                language: "Punjabi",
                translation: "ਪੇਂਡੂ ਕੇਂਦਰਿਤ ਵੀਡੀਓ",
            },
            6: {
                language: "Bengali",
                translation: "গ্রামীণ কেন্দ্রিক ভিডিও",
            },
            14: {
                language: "Assamese",
                translation: "গ্ৰাম্য কেন্দ্ৰিক ভিডিঅ'",
            },
            18: {
                language: "Nagamese",
                translation: "bosti centric videos kan ",
            },
            21: {
                language: "Khasi",
                translation: "Rural Centric Videos",
            },
            20: {
                language: "Mizo",
                translation: "Thingtlang lam hawi Video te",
            },
            19: {
                language: "Manipuri",
                translation: "Rural Centric Videos",
            },
            8: {
                language: "Gujarati",
                translation: "ગ્રામીણ કેન્દ્રિત વિડિઓઝ",
            },
            17: {
                language: "Tamil",
                translation: "கிராமப்புற மைய வீடியோக்கள்",
            },
            5: {
                language: "Telugu",
                translation: "గ్రామీణ కేంద్రీకృత వీడియోలు",
            },
            10: {
                language: "Malayalam",
                translation: "ഗ്രാമീണ കേന്ദ്രീകൃത വീഡിയോകൾ",
            },
            4: {
                language: "Kannada",
                translation: "ಗ್ರಾಮೀಣ ಕೇಂದ್ರಿತ ವೀಡಿಯೊಗಳು",
            },
        },
        default: "Rural Centric Videos",
    },
    ss5d: {
        languages: {
            1: {
                language: "English",
                translation: "Create and watch videos relating to your local community and rural life.",
            },
            2: {
                language: "hindi",
                translation: "अपने स्थानीय समुदाय और ग्रामीण जीवन से संबंधित वीडियो बनाएं और देखें।",
            },
            3: {
                language: "Marathi",
                translation: "तुमच्या स्थानिक समुदायाशी आणि ग्रामीण जीवनाशी संबंधित व्हिडिओ तयार करा आणि पहा.",
            },
            7: {
                language: "Punjabi",
                translation: "ਆਪਣੇ ਸਥਾਨਕ ਭਾਈਚਾਰੇ ਅਤੇ ਪੇਂਡੂ ਜੀਵਨ ਨਾਲ ਸਬੰਧਤ ਵੀਡੀਓ ਬਣਾਓ ਅਤੇ ਦੇਖੋ।",
            },
            6: {
                language: "Bengali",
                translation: "আপনার স্থানীয় সম্প্রদায় এবং গ্রামীণ জীবন সম্পর্কিত ভিডিও তৈরি করুন এবং দেখুন।",
            },
            14: {
                language: "Assamese",
                translation: "আপোনাৰ স্থানীয় সম্প্ৰদায় আৰু গ্ৰাম্য জীৱনৰ সৈতে জড়িত ভিডিঅ' তৈয়াৰ কৰক আৰু চাওক।",
            },
            18: {
                language: "Nagamese",
                translation: "videos kan bana bhi aru sabi apne laka nijor loal manu kan aru bosti man kan laka ",
            },
            21: {
                language: "Khasi",
                translation: "Shna bad peit ia ki video ba iadei bad ki nongshong shnong bad ka jingim nongkyndong",
            },
            20: {
                language: "Mizo",
                translation: "I tualchhung khawtlang leh thingtlang nun nena inzawm video siam la, en rawh.",
            },
            19: {
                language: "Manipuri",
                translation: "Video yengbiyu amadi sembiyu adomgi khungangi oiba punsiga mari leinana.",
            },
            8: {
                language: "Gujarati",
                translation: "તમારા સ્થાનિક સમુદાય અને ગ્રામીણ જીવનને લગતા વીડિયો બનાવો અને જુઓ.",
            },
            17: {
                language: "Tamil",
                translation:
                    "உங்கள் உள்ளூர் சமூகம் மற்றும் கிராமப்புற வாழ்க்கை தொடர்பான வீடியோக்களை உருவாக்கி பார்க்கவும்.",
            },
            5: {
                language: "Telugu",
                translation: "మీ స్థానిక సంఘం మరియు గ్రామీణ జీవితానికి సంబంధించిన వీడియోలను సృష్టించండి మరియు చూడండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "നിങ്ങളുടെ പ്രാദേശിക കമ്മ്യൂണിറ്റിയും ഗ്രാമീണ ജീവിതവുമായി ബന്ധപ്പെട്ട വീഡിയോകൾ സൃഷ്‌ടിക്കുകയും കാണുക.",
            },
            4: {
                language: "Kannada",
                translation: "ನಿಮ್ಮ ಸ್ಥಳೀಯ ಸಮುದಾಯ ಮತ್ತು ಗ್ರಾಮೀಣ ಜೀವನಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ವೀಡಿಯೊಗಳನ್ನು ರಚಿಸಿ ಮತ್ತು ವೀಕ್ಷಿಸಿ.",
            },
        },
        default: "Create and watch videos relating to your local community and rural life.",
    },
    ss6: {
        languages: {
            1: {
                language: "English",
                translation: "Support for 14 Languages",
            },
            2: {
                language: "hindi",
                translation: "14 भाषाओं के लिए समर्थन",
            },
            3: {
                language: "Marathi",
                translation: "14 भाषांसाठी समर्थन",
            },
            7: {
                language: "Punjabi",
                translation: "14 ਭਾਸ਼ਾਵਾਂ ਲਈ ਸਮਰਥਨ",
            },
            6: {
                language: "Bengali",
                translation: "14টি ভাষার জন্য সমর্থন",
            },
            14: {
                language: "Assamese",
                translation: "১৪টা ভাষাৰ বাবে সমৰ্থন",
            },
            18: {
                language: "Nagamese",
                translation: "sob 14 kota barey",
            },
            21: {
                language: "Khasi",
                translation: "Kynrshan na bynta 14 tylli ki jait ktien.",
            },
            20: {
                language: "Mizo",
                translation: "Ṭawng chi hrang 14 tanpui",
            },
            19: {
                language: "Manipuri",
                translation: "Lon 14 yagani",
            },
            8: {
                language: "Gujarati",
                translation: "14 ભાષાઓ માટે સપોર્ટ",
            },
            17: {
                language: "Tamil",
                translation: "14 மொழிகளுக்கான ஆதரவு",
            },
            5: {
                language: "Telugu",
                translation: "14 భాషలకు మద్దతు",
            },
            10: {
                language: "Malayalam",
                translation: "14 ഭാഷകൾക്കുള്ള പിന്തുണ",
            },
            4: {
                language: "Kannada",
                translation: "14 ಭಾಷೆಗಳಿಗೆ ಬೆಂಬಲ",
            },
        },
        default: "Support for 14 Languages",
    },
    ss6d: {
        languages: {
            1: {
                language: "English",
                translation: "Connect with friends and loved ones, in a language of your choice",
            },
            2: {
                language: "hindi",
                translation: "अपनी पसंद की भाषा में मित्रों और प्रियजनों से जुड़ें",
            },
            3: {
                language: "Marathi",
                translation: "आपल्या आवडीच्या भाषेत मित्र आणि प्रियजनांशी जोडा ",
            },
            7: {
                language: "Punjabi",
                translation: "ਆਪਣੀ ਪਸੰਦ ਦੀ ਭਾਸ਼ਾ ਵਿੱਚ ਦੋਸਤਾਂ ਅਤੇ ਅਜ਼ੀਜ਼ਾਂ ਨਾਲ ਜੁੜੋ",
            },
            6: {
                language: "Bengali",
                translation: "আপনার পছন্দের ভাষায় বন্ধু এবং প্রিয়জনের সাথে সংযোগ করুন",
            },
            14: {
                language: "Assamese",
                translation: "বন্ধু-বান্ধৱ আৰু আপোনজনৰ সৈতে সংযোগ স্থাপন কৰক, আপোনাৰ পছন্দৰ ভাষাত",
            },
            18: {
                language: "Nagamese",
                translation: "nijor sathi satni kan aru morom kora manu kan ki conncet kory bhi, nijor laka kota bara",
            },
            21: {
                language: "Khasi",
                translation: "Connect bad ki paralok bad ki baieid, ha ka ktien ba phi kwah",
            },
            20: {
                language: "Mizo",
                translation: "Ṭhiante leh i hmangaih tak takte nen inzawmna siam la, i duh duh ṭawng hmangin",
            },
            19: {
                language: "Manipuri",
                translation: "Adomgi marup singga amadi nungsiba kayaga connect toubiyu adomna pamjaba lon khanbiraga",
            },
            8: {
                language: "Gujarati",
                translation: "તમારી પસંદગીની ભાષામાં મિત્રો અને પ્રિયજનો સાથે જોડાઓ",
            },
            17: {
                language: "Tamil",
                translation: "நீங்கள் விரும்பும் மொழியில் நண்பர்கள் மற்றும் அன்பானவர்களுடன் இணையுங்கள்",
            },
            5: {
                language: "Telugu",
                translation: "మీకు నచ్చిన భాషలో స్నేహితులు మరియు ప్రియమైన వారితో కనెక్ట్ అవ్వండి",
            },
            10: {
                language: "Malayalam",
                translation: "നിങ്ങൾക്ക് ഇഷ്ടമുള്ള ഭാഷയിൽ സുഹൃത്തുക്കളുമായും പ്രിയപ്പെട്ടവരുമായും ബന്ധപ്പെടുക",
            },
            4: {
                language: "Kannada",
                translation: "ನಿಮ್ಮ ಆಯ್ಕೆಯ ಭಾಷೆಯಲ್ಲಿ ಸ್ನೇಹಿತರು ಮತ್ತು ಪ್ರೀತಿಪಾತ್ರರೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಿ",
            },
        },
        default: "Connect with friends and loved ones, in a language of your choice",
    },
    reviewHeading: {
        languages: {
            1: {
                language: "English",
                translation: "What our rural users are saying about Mera Gaon",
            },
            2: {
                language: "hindi",
                translation: "मेरा गांव के बारे में हमारे ग्रामीण उपयोगकर्ता क्या कह रहे हैं",
            },
            3: {
                language: "Marathi",
                translation: "आमचे ग्रामीण वापरकर्ते मेरा गाव बद्दल काय म्हणत आहेत",
            },
            7: {
                language: "Punjabi",
                translation: "ਸਾਡੇ ਪੇਂਡੂ ਉਪਭੋਗਤਾ ਮੇਰਾ ਗਾਓਂ ਬਾਰੇ ਕੀ ਕਹਿ ਰਹੇ ਹਨ",
            },
            6: {
                language: "Bengali",
                translation: "আমাদের গ্রামীণ ব্যবহারকারীরা মেরা গাঁও সম্পর্কে যা বলছেন",
            },
            14: {
                language: "Assamese",
                translation: "আমাৰ গ্ৰাম্য ব্যৱহাৰকাৰীসকলে মেৰা গাওঁৰ বিষয়ে কি কৈছে",
            },
            18: {
                language: "Nagamese",
                translation: "nijor bosti manu kan bara ki koi ase Mera Gaon laka ",
            },
            21: {
                language: "Khasi",
                translation: "Ki Nongpyndonkam jong ka Mera Gaon ki don aiu ban ong.",
            },
            20: {
                language: "Mizo",
                translation: "Kan thingtlang mi a hmangtute hian Mera Gaon chungchanga an sawi dan",
            },
            19: {
                language: "Manipuri",
                translation: "Kari hairi eikhoigi Mera Gaon sijinaba singna.",
            },
            8: {
                language: "Gujarati",
                translation: "અમારા ગ્રામીણ વપરાશકર્તાઓ મેરા ગાંવ વિશે શું કહે છે",
            },
            17: {
                language: "Tamil",
                translation: "மேரா காவ்ன் பற்றி நமது கிராமப்புற பயனர்கள் என்ன சொல்கிறார்கள்",
            },
            5: {
                language: "Telugu",
                translation: "మేరా గావ్ గురించి మన గ్రామీణ వినియోగదారులు ఏమి చెబుతున్నారు",
            },
            10: {
                language: "Malayalam",
                translation: "മേരാ ഗാവിനെക്കുറിച്ച് നമ്മുടെ ഗ്രാമീണ ഉപയോക്താക്കൾ പറയുന്നത്",
            },
            4: {
                language: "Kannada",
                translation: "ಮೇರಾ ಗಾಂವ್ ಬಗ್ಗೆ ನಮ್ಮ ಗ್ರಾಮೀಣ ಬಳಕೆದಾರರು ಏನು ಹೇಳುತ್ತಿದ್ದಾರೆ",
            },
        },
        default: "What our rural users are saying about Mera Gaon",
    },
    reviewer1: {
        languages: {
            1: {
                language: "English",
                translation: "Arun Dixit",
            },
            2: {
                language: "hindi",
                translation: "अरुण दिक्षित",
            },
            3: {
                language: "Marathi",
                translation: "अरुण दीक्षित",
            },
            7: {
                language: "Punjabi",
                translation: "ਅਰੁਣ ਦੀਕਸ਼ਿਤ",
            },
            6: {
                language: "Bengali",
                translation: "অরুণ দীক্ষিত",
            },
            14: {
                language: "Assamese",
                translation: "অৰুণ দীক্ষিত",
            },
            18: {
                language: "Nagamese",
                translation: "Arun Dixit",
            },
            21: {
                language: "Khasi",
                translation: "Arun Dixit",
            },
            20: {
                language: "Mizo",
                translation: "Arun Dixit",
            },
            19: {
                language: "Manipuri",
                translation: "Arun Dixit",
            },
            8: {
                language: "Gujarati",
                translation: "અરુણ દીક્ષિત",
            },
            17: {
                language: "Tamil",
                translation: "அருண் தீட்சித்",
            },
            5: {
                language: "Telugu",
                translation: "అరుణ్ దీక్షిత్",
            },
            10: {
                language: "Malayalam",
                translation: "അരുൺ ദീക്ഷിത്",
            },
            4: {
                language: "Kannada",
                translation: "ಅರುಣ್ ದೀಕ್ಷಿತ್",
            },
        },
        default: "Arun Dixit",
    },
    review1: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Aap is very much useful for rural areas. Several more things has been added here for convenience of users. We can sale our products from home and get accurate answers of any questions from here. Alongwith it we can also entertain us from using video feature. Can sale our crop. So very beautiful app for rural areas.",
            },
            2: {
                language: "hindi",
                translation:
                    "ऐप ग्रामीण क्षेत्रों के लिए बहुत उपयोगी है। यूजर्स की सुविधा के लिए यहां कई और चीजें जोड़ी गई हैं। हम अपने उत्पादों को घर बैठे बेच सकते हैं और यहां से किसी भी प्रश्न का सटीक उत्तर प्राप्त कर सकते हैं। इसके साथ-साथ हम वीडियो फीचर का उपयोग करके भी हमारा मनोरंजन कर सकते हैं। अपनी फसल बेच सकते हैं। ग्रामीण क्षेत्रों के लिए बहुत ही सुंदर ऐप।",
            },
            3: {
                language: "Marathi",
                translation:
                    "ऐप ग्रामीण भागासाठी खूप उपयुक्त आहे. वापरकर्त्यांच्या सोयीसाठी येथे आणखी काही गोष्टी जोडल्या गेल्या आहेत. आम्ही आमची उत्पादने घरबसल्या विकू शकतो आणि कोणत्याही प्रश्नांची अचूक उत्तरे येथून मिळवू शकतो. यासोबतच आम्ही व्हिडिओ फीचर वापरून आमचे मनोरंजन करू शकतो. आमचे पीक विकू शकतो. ग्रामीण भागासाठी खूप सुंदर ऐप.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "'ਆਪ' ਪੇਂਡੂ ਖੇਤਰਾਂ ਲਈ ਬਹੁਤ ਲਾਹੇਵੰਦ ਹੈ। ਉਪਭੋਗਤਾਵਾਂ ਦੀ ਸਹੂਲਤ ਲਈ ਇੱਥੇ ਕਈ ਹੋਰ ਚੀਜ਼ਾਂ ਸ਼ਾਮਲ ਕੀਤੀਆਂ ਗਈਆਂ ਹਨ। ਅਸੀਂ ਆਪਣੇ ਉਤਪਾਦਾਂ ਨੂੰ ਘਰ ਬੈਠੇ ਵੇਚ ਸਕਦੇ ਹਾਂ ਅਤੇ ਇੱਥੋਂ ਕਿਸੇ ਵੀ ਸਵਾਲ ਦੇ ਸਹੀ ਜਵਾਬ ਪ੍ਰਾਪਤ ਕਰ ਸਕਦੇ ਹਾਂ। ਇਸ ਦੇ ਨਾਲ ਅਸੀਂ ਵੀਡੀਓ ਫੀਚਰ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਸਾਡਾ ਮਨੋਰੰਜਨ ਵੀ ਕਰ ਸਕਦੇ ਹਾਂ। ਸਾਡੀ ਫਸਲ ਵੇਚ ਸਕਦਾ ਹੈ। ਪੇਂਡੂ ਖੇਤਰਾਂ ਲਈ ਬਹੁਤ ਸੁੰਦਰ ਐਪ.",
            },
            6: {
                language: "Bengali",
                translation:
                    "আপ গ্রামীণ এলাকার জন্য খুবই উপযোগী। ব্যবহারকারীদের সুবিধার্থে এখানে আরও বেশ কিছু জিনিস যোগ করা হয়েছে। আমরা ঘরে বসেই আমাদের পণ্য বিক্রি করতে পারি এবং এখান থেকে যেকোনো প্রশ্নের সঠিক উত্তর পেতে পারি। বরাবর",
            },
            14: {
                language: "Assamese",
                translation:
                    "গ্ৰামাঞ্চলৰ বাবে আপ অতি উপযোগী। ব্যৱহাৰকাৰীৰ সুবিধাৰ বাবে ইয়াত আৰু কেইবাটাও কথা যোগ কৰা হৈছে। আমি ঘৰৰ পৰাই আমাৰ সামগ্ৰী বিক্ৰী কৰিব পাৰো আৰু যিকোনো প্ৰশ্নৰ সঠিক উত্তৰ ইয়াৰ পৰা লাভ কৰিব পাৰো। ইয়াৰ লগতে আমি ভিডিঅ’ ফিচাৰ ব্যৱহাৰ কৰাৰ পৰাও আমাক মনোৰঞ্জন দিব পাৰো। আমাৰ শস্য বিক্ৰী কৰিব পাৰে। গতিকে গ্ৰাম্য অঞ্চলৰ বাবে অতি সুন্দৰ এপ।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "Aap doh bosti laka chaka kan karney beshi kam ase. aru beshi saman kan etu deh taley shai etu jola manu kan karney. moikan laka saman kor bara nekiry kory aru tik saval laka jawab kan bai etu bara. aru etu hi nahoi kina moi kan laka video kan sai kina kushu bai bhi. saman kan bi bekiry kory boley barey. beshi sunder app ase bosti deh taka manu kan ki. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Kane ka App ka ai jingmyntoi sha ki shnong nongkyndong.Bun shuh ki ba lah add na bynta ban suk ki nongpyndonkam.Ngi lah ban die ia ki mar jong ngi na iing bad phi lah ban ioh ki jubab ki ba biang na bynta kino kino ki jingkylli.Ryngkat bad kata ngi lah ban entertain da ka pyndonkam ki video feature.Lah ban die ia ki jingthung. Te kadei ka app ba biang tam na bynta ki nongkyndong.",
            },
            20: {
                language: "Mizo",
                translation:
                    "App hi thingtlang mite tan a tangkai hle. Hetah hian hmangtute tana awlsam zawk nan thil engemaw zat dah belh a ni bawk. Kan thil siamte chu in atangin kan hralh thei a, hetah hian zawhna eng pawh chhanna dik tak kan hmu thei bawk. Chumi rual chuan video feature hmangin min ti hlim thei bawk. Kan thlai thar hi hralh thei. Chuvangin thingtlang mite tan app tha tak a ni.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "App asi khungang singda yamna kanagani.Anouba kaya ama haple henna sijinaba nungainaba.Pot chei singsu yonba yagani amadi wahang singgi paokhum pibasu yagani masida.Video features asi nungaibasu piri.Eikhoigi pot yonba yagani.Aduna khungagi oinadi yamna phajaba app amani.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "આપ ગ્રામીણ વિસ્તારો માટે ખૂબ જ ઉપયોગી છે.  યુઝર્સની સુવિધા માટે અહીં બીજી ઘણી વસ્તુઓ ઉમેરવામાં આવી છે.  અમે અમારા ઉત્પાદનો ઘરે બેઠા વેચી શકીએ છીએ અને અહીંથી કોઈપણ પ્રશ્નોના સચોટ જવાબો મેળવી શકીએ છીએ.  તેની સાથે અમે વિડિયો ફીચરનો ઉપયોગ કરીને અમારું મનોરંજન પણ કરી શકીએ છીએ.  આપણો પાક વેચી શકે છે.  ગ્રામીણ વિસ્તારો માટે ખૂબ જ સુંદર એપ્લિકેશન.",
            },
            17: {
                language: "Tamil",
                translation:
                    "கிராமப்புறங்களுக்கு ஆப் மிகவும் பயனுள்ளதாக இருக்கிறது. பயனர்களின் வசதிக்காக மேலும் பல விஷயங்கள் இங்கே சேர்க்கப்பட்டுள்ளன. நாங்கள் எங்கள் தயாரிப்புகளை வீட்டிலிருந்தே விற்பனை செய்யலாம் மற்றும் ஏதேனும் கேள்விகளுக்கு இங்கிருந்து துல்லியமான பதில்களைப் பெறலாம். அதனுடன் வீடியோ அம்சத்தைப் பயன்படுத்தியும் நம்மை மகிழ்விக்க முடியும். எங்கள் பயிர்களை விற்பனை செய்யலாம். கிராமப்புறங்களுக்கு மிகவும் அழகான பயன்பாடு.",
            },
            5: {
                language: "Telugu",
                translation:
                    "గ్రామీణ ప్రాంతాలకు ఆప్ ఎంతగానో ఉపయోగపడుతుంది. వినియోగదారుల సౌలభ్యం కోసం ఇక్కడ మరిన్ని విషయాలు జోడించబడ్డాయి. మేము మా ఉత్పత్తులను ఇంటి నుండి అమ్మవచ్చు మరియు ఇక్కడ నుండి ఏవైనా ప్రశ్నలకు ఖచ్చితమైన సమాధానాలను పొందవచ్చు. దానితో పాటు మనం వీడియో ఫీచర్‌ని ఉపయోగించడం ద్వారా కూడా మనల్ని అలరించవచ్చు. మా పంటను అమ్ముకోవచ్చు. గ్రామీణ ప్రాంతాలకు చాలా అందమైన యాప్.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "ഗ്രാമപ്രദേശങ്ങളിൽ ആപ്പ് വളരെ ഉപകാരപ്രദമാണ്. ഉപയോക്താക്കളുടെ സൗകര്യാർത്ഥം നിരവധി കാര്യങ്ങൾ ഇവിടെ ചേർത്തിട്ടുണ്ട്. ഞങ്ങൾക്ക് ഞങ്ങളുടെ ഉൽപ്പന്നങ്ങൾ വീട്ടിൽ നിന്ന് വിൽക്കാനും ഇവിടെ നിന്ന് ഏത് ചോദ്യങ്ങൾക്കും കൃത്യമായ ഉത്തരം നേടാനും കഴിയും. അതോടൊപ്പം വീഡിയോ ഫീച്ചർ ഉപയോഗിക്കുന്നതിൽ നിന്നും ഞങ്ങളെ രസിപ്പിക്കാനും കഴിയും. നമ്മുടെ വിളകൾ വിൽക്കാം. ഗ്രാമപ്രദേശങ്ങൾക്ക് വളരെ മനോഹരമായ ആപ്പ്.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಿಗೆ ಆಪ್ ತುಂಬಾ ಉಪಯುಕ್ತವಾಗಿದೆ.  ಬಳಕೆದಾರರ ಅನುಕೂಲಕ್ಕಾಗಿ ಇನ್ನೂ ಹಲವಾರು ವಿಷಯಗಳನ್ನು ಇಲ್ಲಿ ಸೇರಿಸಲಾಗಿದೆ.  ನಾವು ನಮ್ಮ ಉತ್ಪನ್ನಗಳನ್ನು ಮನೆಯಿಂದಲೇ ಮಾರಾಟ ಮಾಡಬಹುದು ಮತ್ತು ಇಲ್ಲಿಂದ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳಿಗೆ ನಿಖರವಾದ ಉತ್ತರಗಳನ್ನು ಪಡೆಯಬಹುದು.  ಇದರೊಂದಿಗೆ ನಾವು ವೀಡಿಯೋ ವೈಶಿಷ್ಟ್ಯವನ್ನು ಬಳಸಿಕೊಂಡು ನಮ್ಮನ್ನು ಮನರಂಜಿಸಬಹುದು.  ನಮ್ಮ ಬೆಳೆಯನ್ನು ಮಾರಾಟ ಮಾಡಬಹುದು.  ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಿಗೆ ತುಂಬಾ ಸುಂದರವಾದ ಅಪ್ಲಿಕೇಶನ್.",
            },
        },
        default:
            "Aap is very much useful for rural areas. Several more things has been added here for convenience of users. We can sale our products from home and get accurate answers of any questions from here. Alongwith it we can also entertain us from using video feature. Can sale our crop. So very beautiful app for rural areas.",
    },
    reviewer2: {
        languages: {
            1: {
                language: "English",
                translation: "Atul Bohra",
            },
            2: {
                language: "hindi",
                translation: "अतुल बोहरा ",
            },
            3: {
                language: "Marathi",
                translation: "अतुल बोहरा",
            },
            7: {
                language: "Punjabi",
                translation: "ਅਤੁਲ ਬੋਹਰਾ",
            },
            6: {
                language: "Bengali",
                translation: "অতুল বোহরা",
            },
            14: {
                language: "Assamese",
                translation: "অতুল বহৰা",
            },
            18: {
                language: "Nagamese",
                translation: "Atul Bohra",
            },
            21: {
                language: "Khasi",
                translation: "Atul Bohra",
            },
            20: {
                language: "Mizo",
                translation: "Atul Bohra",
            },
            19: {
                language: "Manipuri",
                translation: "Atul Bohra",
            },
            8: {
                language: "Gujarati",
                translation: "અતુલ બોહરા",
            },
            17: {
                language: "Tamil",
                translation: "அதுல் போஹ்ரா",
            },
            5: {
                language: "Telugu",
                translation: "అతుల్ బోహ్రా",
            },
            10: {
                language: "Malayalam",
                translation: "അതുൽ ബോറ",
            },
            4: {
                language: "Kannada",
                translation: "ಅತುಲ್ ಬೋಹ್ರಾ",
            },
        },
        default: "Atul Bohra",
    },
    review2: {
        languages: {
            1: {
                language: "English",
                translation:
                    "One off the greatest and very awesome app in our indian country very nice service .now i am big fan this app . Thanks mera gaon creator and his all members for giving me my money and supporting me thank you very much so ❤️",
            },
            2: {
                language: "hindi",
                translation:
                    "हमारे भारतीय देश में सबसे महान और बहुत बढ़िया ऐप में से एक बहुत अच्छी सेवा है। अब मैं इस ऐप का बहुत बड़ा प्रशंसक हूं। मेरा गांव बनाने वाले और उनके सभी सदस्यों को मेरा पैसा देने और मेरा समर्थन करने के लिए बहुत-बहुत धन्यवाद ❤️",
            },
            3: {
                language: "Marathi",
                translation:
                    "आपल्या भारतीय देशातील सर्वात महान आणि अतिशय छान अँपपैकी एक अतिशय छान सेवा आहे .आता मी या अॅपचा मोठा चाहता आहे . माझे गांव निर्माते आणि त्यांचे सर्व सदस्य मला माझे पैसे दिल्याबद्दल आणि मला पाठिंबा दिल्याबद्दल धन्यवाद ❤️",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਸਾਡੇ ਭਾਰਤੀ ਦੇਸ਼ ਵਿੱਚ ਸਭ ਤੋਂ ਮਹਾਨ ਅਤੇ ਬਹੁਤ ਹੀ ਸ਼ਾਨਦਾਰ ਐਪ ਵਿੱਚੋਂ ਇੱਕ ਬਹੁਤ ਵਧੀਆ ਸੇਵਾ ਹੈ .ਹੁਣ ਮੈਂ ਇਸ ਐਪ ਦਾ ਬਹੁਤ ਵੱਡਾ ਪ੍ਰਸ਼ੰਸਕ ਹਾਂ . ਧੰਨਵਾਦ ਮੇਰਾ ਗਾਓਂ ਸਿਰਜਣਹਾਰ ਅਤੇ ਉਸਦੇ ਸਾਰੇ ਮੈਂਬਰਾਂ ਦਾ ਮੈਨੂੰ ਮੇਰੇ ਪੈਸੇ ਦੇਣ ਅਤੇ ਮੇਰਾ ਸਮਰਥਨ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਬਹੁਤ ਬਹੁਤ ਧੰਨਵਾਦ ❤️",
            },
            6: {
                language: "Bengali",
                translation:
                    "আমাদের ভারতীয় দেশের একটি সর্বশ্রেষ্ঠ এবং খুব ভয়ঙ্কর অ্যাপটি খুব সুন্দর পরিষেবা। এখন আমি এই অ্যাপটির বড় ভক্ত। ধন্যবাদ মেরা গাঁও সৃষ্টিকর্তা এবং তার সকল সদস্যদের আমার টাকা দেওয়ার জন্য এবং আমাকে সমর্থন করার জন্য",
            },
            14: {
                language: "Assamese",
                translation:
                    "আমাৰ ভাৰতীয় দেশৰ সৰ্বশ্ৰেষ্ঠ আৰু অতি ভয়ংকৰ এপটোৰ পৰা এটা অতি সুন্দৰ সেৱা।এতিয়া মই এই এপটোৰ ডাঙৰ অনুৰাগী। ধন্যবাদ mera gaon creator আৰু তেওঁৰ সকলো সদস্যক মোৰ টকা দি মোক সহায় কৰাৰ বাবে বহুত বহুত ধন্যবাদ গতিকে ❤️",
            },
            18: {
                language: "Nagamese",
                translation:
                    "etu app doh beshi bhal aru sabsi bhal wala ase moi kan laka desh deh, moi etu app laka sabse dangor ase. beshi kushi bai shai mera gaon bana manu aru daila manu kan sob moi ki poisa deya karney aru support kora karney. beshi beshi kushi bai shai ❤️",
            },
            21: {
                language: "Khasi",
                translation:
                    "Kawei na ki ba biang bad best app ha ka ri India jong ngi.ka jingshakri ba biang.mynta nga dei ka nongkyrshan ba radbah jong kane ka app.Khublei  ia u nongshna jong kane ka Mera Gaon  bad ki dkhot ba phi lah ai ia nga ka pisa bad ka jingkyrshan jong phi.Khublei Shibun eh❤️",
            },
            20: {
                language: "Mizo",
                translation:
                    "Kan India ram a app awm ho zingah  ropui leh tha pawl tak niin service tha tak min pe a .he app hi ka ngaina khawp mai . Thanks mera gaon creator leh a member zawng zawngte ka pawisa min pe a, min thlawp avangin lawmthu ka sawi tak meuh meuh a ni ❤️",
            },
            19: {
                language: "Manipuri",
                translation:
                    " Eikhoigi Indian country asida yamna phaba app amadi service su yamna phajaba app ama oiri. Houjikti app asigi fan oire. Thagatchari mera gaon sembiriba amadi member singbu eigi peisa pibiba amadi support toubiba hanna thagatchari adombu ❤️",
            },
            8: {
                language: "Gujarati",
                translation:
                    "આપણા ભારતીય દેશની સૌથી મહાન અને ખૂબ જ અદ્ભુત એપ્લિકેશનમાંથી એક ખૂબ જ સરસ સેવા છે .હવે હું આ એપ્લિકેશનનો મોટો ચાહક છું .  મને મારા પૈસા આપવા અને મને ટેકો આપવા બદલ મેરા ગાંવના સર્જક અને તેના તમામ સભ્યોનો આભાર ખૂબ ખૂબ આભાર ❤️",
            },
            17: {
                language: "Tamil",
                translation:
                    "நமது இந்திய நாட்டில் மிகச் சிறந்த மற்றும் அற்புதமான செயலிகளில் ஒன்று மிகவும் அருமையான சேவை .இப்போது நான் இந்த செயலியின் பெரிய ரசிகன். எனது பணத்தை எனக்கு அளித்து எனக்கு ஆதரவளித்த மேரா கான் படைப்பாளர் மற்றும் அவரது அனைத்து உறுப்பினர்களுக்கும் நன்றி மிக்க நன்றி ❤️",
            },
            5: {
                language: "Telugu",
                translation:
                    "మన భారత దేశంలో గొప్ప మరియు చాలా అద్భుతమైన యాప్‌లో ఒకటి చాలా మంచి సేవ. ఇప్పుడు నేను ఈ యాప్‌కి పెద్ద అభిమానిని. నా డబ్బును నాకు అందించినందుకు మరియు నాకు మద్దతు ఇచ్చినందుకు మేరా గావ్ సృష్టికర్త మరియు అతని సభ్యులందరికీ ధన్యవాదాలు, చాలా ధన్యవాదాలు ❤️",
            },
            10: {
                language: "Malayalam",
                translation:
                    "നമ്മുടെ ഇന്ത്യൻ രാജ്യത്തെ ഏറ്റവും മികച്ചതും അതിശയകരവുമായ ഒരു ആപ്പ് വളരെ നല്ല സേവനം .ഇപ്പോൾ ഞാൻ ഈ ആപ്ലിക്കേഷന്റെ വലിയ ആരാധകനാണ്. എന്റെ പണം തന്നതിനും എന്നെ പിന്തുണച്ചതിനും മേരാ ഗാവ് സ്രഷ്ടാവിനും അവന്റെ എല്ലാ അംഗങ്ങൾക്കും നന്ദി, വളരെ നന്ദി ❤️",
            },
            4: {
                language: "Kannada",
                translation:
                    "ನಮ್ಮ ಭಾರತೀಯ ದೇಶದಲ್ಲಿ ಅತ್ಯುತ್ತಮವಾದ ಮತ್ತು ಅದ್ಭುತವಾದ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಒಂದಾಗಿದೆ ಉತ್ತಮ ಸೇವೆ .ಈಗ ನಾನು ಈ ಅಪ್ಲಿಕೇಶನ್‌ನ ದೊಡ್ಡ ಅಭಿಮಾನಿಯಾಗಿದ್ದೇನೆ.  ಧನ್ಯವಾದಗಳು ಮೇರಾ ಗಾಂವ್ ಸೃಷ್ಟಿಕರ್ತ ಮತ್ತು ಅವರ ಎಲ್ಲಾ ಸದಸ್ಯರಿಗೆ ನನ್ನ ಹಣವನ್ನು ನೀಡಿದ್ದಕ್ಕಾಗಿ ಮತ್ತು ನನ್ನನ್ನು ಬೆಂಬಲಿಸಿದ್ದಕ್ಕಾಗಿ ತುಂಬಾ ಧನ್ಯವಾದಗಳು ❤️",
            },
        },
        default:
            "One off the greatest and very awesome app in our indian country very nice service .now i am big fan this app . Thanks mera gaon creator and his all members for giving me my money and supporting me thank you very much so ❤️",
    },
    reviewer3: {
        languages: {
            1: {
                language: "English",
                translation: "Ramkishor Thainua",
            },
            2: {
                language: "hindi",
                translation: "रामकिशोर थिनुआ",
            },
            3: {
                language: "Marathi",
                translation: "रामकिशोर थानुआ",
            },
            7: {
                language: "Punjabi",
                translation: "ਰਾਮਕਿਸ਼ੋਰ ਥੈਨੁਆ",
            },
            6: {
                language: "Bengali",
                translation: "রামকিশোর থাইনুয়া",
            },
            14: {
                language: "Assamese",
                translation: "ৰামকিশোৰ থাইনুৱা",
            },
            18: {
                language: "Nagamese",
                translation: "Ramkishor Thainua",
            },
            21: {
                language: "Khasi",
                translation: "Ramkishor Thainua",
            },
            20: {
                language: "Mizo",
                translation: "Ramkishor Thainua",
            },
            19: {
                language: "Manipuri",
                translation: "Ramkishor Thainua",
            },
            8: {
                language: "Gujarati",
                translation: "રામકિશોર થૈનુઆ",
            },
            17: {
                language: "Tamil",
                translation: "ராம்கிஷோர் தைனுவா",
            },
            5: {
                language: "Telugu",
                translation: "రాంకిషోర్ థైనువా",
            },
            10: {
                language: "Malayalam",
                translation: "രാംകിഷോർ തൈനുവ",
            },
            4: {
                language: "Kannada",
                translation: "ರಾಮಕಿಶೋರ್ ಥೈನುವಾ",
            },
        },
        default: "Ramkishor Thainua",
    },
    review3: {
        languages: {
            1: {
                language: "English",
                translation:
                    "It is very good and useful app. By using it farmer can calculate the size of the corp, discribe their crop size because of this application..and very easy to use",
            },
            2: {
                language: "hindi",
                translation:
                    "यह बहुत ही अच्छा और उपयोगी ऐप है। इसका उपयोग करके किसान फसल के आकार की गणना कर सकता है, इस एप्लिकेशन के कारण उनकी फसल के आकार का वर्णन कर सकता है..और उपयोग करने में बहुत आसान है",
            },
            3: {
                language: "Marathi",
                translation:
                    "हे खूप चांगले आणि उपयुक्त ऐप आहे. याचा वापर करून शेतकरी या ऍप्लिकेशनमुळे पिकाचा  आकार मोजू शकतो, त्यांच्या पिकाच्या आकाराचे वर्णन करू शकतो..आणि वापरण्यास अतिशय सोपे आहे.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਇਹ ਬਹੁਤ ਵਧੀਆ ਅਤੇ ਉਪਯੋਗੀ ਐਪ ਹੈ। ਇਸਦੀ ਵਰਤੋਂ ਕਰਕੇ ਕਿਸਾਨ ਕਾਰਪੋਰੇਸ਼ਨ ਦੇ ਆਕਾਰ ਦੀ ਗਣਨਾ ਕਰ ਸਕਦੇ ਹਨ, ਇਸ ਐਪਲੀਕੇਸ਼ਨ ਦੇ ਕਾਰਨ ਆਪਣੀ ਫਸਲ ਦੇ ਆਕਾਰ ਦਾ ਵਰਣਨ ਕਰ ਸਕਦੇ ਹਨ.. ਅਤੇ ਵਰਤਣ ਵਿੱਚ ਬਹੁਤ ਆਸਾਨ ਹੈ",
            },
            6: {
                language: "Bengali",
                translation:
                    "এটি খুবই ভালো এবং দরকারী অ্যাপ। এটি ব্যবহার করে কৃষক কর্পোরেশনের আকার গণনা করতে পারে, এই অ্যাপ্লিকেশনটির কারণে তাদের ফসলের আকার বর্ণনা করতে পারে..এবং ব্যবহার করা খুব সহজ",
            },
            14: {
                language: "Assamese",
                translation:
                    "ই অতি ভাল আৰু উপযোগী এপ। ইয়াক ব্যৱহাৰ কৰি কৃষকে কৰ্পৰ আকাৰ গণনা কৰিব পাৰে, এই প্ৰয়োগৰ বাবে তেওঁলোকৰ শস্যৰ আকাৰ বৰ্ণনা কৰিব পাৰে..আৰু ব্যৱহাৰ কৰাটো অতি সহজ",
            },
            18: {
                language: "Nagamese",
                translation:
                    "atu app doh kam karney bhal ase. etu app hlai kina keti kora mnau kan sabzi kan laka size kan ki sabo barey aru beshi bhal ase jola boley. ",
            },
            21: {
                language: "Khasi",
                translation:
                    "Kadei ba bha bad myntoi app.Da kaba pyndonkam ia ka ki nongrep ki lah ban khein ia ki jingkhia jong u jingthung,batai ia ka jingheh ujingthung na kane ka application.bad suk ban pyndonkam",
            },
            20: {
                language: "Mizo",
                translation:
                    "App tha tak leh tangkai tak a ni. A hmang hian farmer chuan thlai size a chhut thei a, he application avang hian an thali size a hrilfiah thei bawk..leh hman a awlsam hle bawk",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Yamna kanaba app amani. Loumi singna sijinaduna pambi amadi mahei size kaya hotpa yai, pambi size sandokna takli application asina. amadi yamna lai sijinabada",
            },
            8: {
                language: "Gujarati",
                translation:
                    "તે ખૂબ જ સારી અને ઉપયોગી એપ્લિકેશન છે.  તેનો ઉપયોગ કરીને ખેડૂત કોર્પોરેશનના કદની ગણતરી કરી શકે છે, આ એપ્લિકેશનને કારણે તેમના પાકના કદનું વર્ણન કરી શકે છે..અને ઉપયોગમાં ખૂબ જ સરળ",
            },
            17: {
                language: "Tamil",
                translation:
                    "இது மிகவும் நல்ல மற்றும் பயனுள்ள பயன்பாடாகும். இதைப் பயன்படுத்துவதன் மூலம் விவசாயிகள் கார்ப் அளவைக் கணக்கிடலாம், இந்த பயன்பாட்டின் காரணமாக அவர்களின் பயிர் அளவை விவரிக்கலாம். மேலும் பயன்படுத்த மிகவும் எளிதானது",
            },
            5: {
                language: "Telugu",
                translation:
                    "ఇది చాలా మంచి మరియు ఉపయోగకరమైన యాప్. దీన్ని ఉపయోగించడం ద్వారా రైతు కార్ప్ యొక్క పరిమాణాన్ని లెక్కించవచ్చు, ఈ అప్లికేషన్ కారణంగా వారి పంట పరిమాణాన్ని వివరించవచ్చు. మరియు ఉపయోగించడానికి చాలా సులభం",
            },
            10: {
                language: "Malayalam",
                translation:
                    "ഇത് വളരെ നല്ലതും ഉപയോഗപ്രദവുമായ ആപ്ലിക്കേഷനാണ്. ഇത് ഉപയോഗിക്കുന്നതിലൂടെ കർഷകന് കോർപ്പിന്റെ വലുപ്പം കണക്കാക്കാനും ഈ ആപ്ലിക്കേഷൻ കാരണം അവരുടെ വിളയുടെ വലുപ്പം വിവരിക്കാനും കഴിയും.. ഉപയോഗിക്കാൻ വളരെ എളുപ്പമാണ്",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಇದು ತುಂಬಾ ಒಳ್ಳೆಯ ಮತ್ತು ಉಪಯುಕ್ತ ಅಪ್ಲಿಕೇಶನ್ ಆಗಿದೆ.  ಇದನ್ನು ಬಳಸುವುದರ ಮೂಲಕ ರೈತರು ಕಾರ್ಪ್‌ನ ಗಾತ್ರವನ್ನು ಲೆಕ್ಕ ಹಾಕಬಹುದು, ಈ ಅಪ್ಲಿಕೇಶನ್‌ನಿಂದಾಗಿ ಅವರ ಬೆಳೆ ಗಾತ್ರವನ್ನು ವಿವರಿಸಬಹುದು. ಮತ್ತು ಬಳಸಲು ತುಂಬಾ ಸುಲಭ",
            },
        },
        default:
            "It is very good and useful app. By using it farmer can calculate the size of the corp, discribe their crop size because of this application..and very easy to use",
    },
    reviewer4: {
        languages: {
            1: {
                language: "English",
                translation: "Ramji Mishra",
            },
            2: {
                language: "hindi",
                translation: "रामजी मिश्रा",
            },
            3: {
                language: "Marathi",
                translation: "रामजी मिश्रा",
            },
            7: {
                language: "Punjabi",
                translation: "ਰਾਮਜੀ ਮਿਸ਼ਰਾ",
            },
            6: {
                language: "Bengali",
                translation: "রামজি মিশ্র",
            },
            14: {
                language: "Assamese",
                translation: "ৰামজী মিশ্ৰ",
            },
            18: {
                language: "Nagamese",
                translation: "Ramji Mishra",
            },
            21: {
                language: "Khasi",
                translation: "Ramji Mishra",
            },
            20: {
                language: "Mizo",
                translation: "Ramji Mishra",
            },
            19: {
                language: "Manipuri",
                translation: "Ramji Mishra",
            },
            8: {
                language: "Gujarati",
                translation: "રામજી મિશ્રા",
            },
            17: {
                language: "Tamil",
                translation: "ராம்ஜி மிஸ்ரா",
            },
            5: {
                language: "Telugu",
                translation: "రామ్‌జీ మిశ్రా",
            },
            10: {
                language: "Malayalam",
                translation: "റാംജി മിശ്ര",
            },
            4: {
                language: "Kannada",
                translation: "ರಾಮ್‌ಜಿ ಮಿಶ್ರಾ",
            },
        },
        default: "Ramji Mishra",
    },
    review4: {
        languages: {
            1: {
                language: "English",
                translation:
                    "This is nice apps for measurerment of agri cropsize, accurate size mapping.Very good app for farmers and traders to calculate the size of crop.",
            },
            2: {
                language: "hindi",
                translation:
                    "यह कृषि फसलों के आकार, सटीक आकार मानचित्रण के मापन के लिए अच्छा ऐप है। किसानों और व्यापारियों के लिए फसल के आकार की गणना करने के लिए बहुत अच्छा ऐप है।",
            },
            3: {
                language: "Marathi",
                translation:
                    "कृषी पिकांच्या आकाराचे मोजमाप करण्यासाठी, अचूक आकाराचे मॅपिंगसाठी हे छान ऐप आहे. शेतकरी आणि व्यापाऱ्यांसाठी पिकाचा आकार मोजण्यासाठी खूप चांगले ऐप आहे.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਇਹ ਖੇਤੀ ਫਸਲਾਂ ਦੇ ਆਕਾਰ ਨੂੰ ਮਾਪਣ, ਸਹੀ ਆਕਾਰ ਦੀ ਮੈਪਿੰਗ ਲਈ ਵਧੀਆ ਐਪ ਹੈ। ਕਿਸਾਨਾਂ ਅਤੇ ਵਪਾਰੀਆਂ ਲਈ ਫਸਲ ਦੇ ਆਕਾਰ ਦੀ ਗਣਨਾ ਕਰਨ ਲਈ ਬਹੁਤ ਵਧੀਆ ਐਪ ਹੈ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "কৃষি ফসলের আকার পরিমাপ করার জন্য এটি একটি চমৎকার অ্যাপ, সঠিক আকারের ম্যাপিং। কৃষক এবং ব্যবসায়ীদের জন্য ফসলের আকার গণনা করার জন্য খুব ভাল অ্যাপ।",
            },
            14: {
                language: "Assamese",
                translation:
                    "এইটো কৃষি শস্যৰ আকাৰ জোখাৰ বাবে ভাল এপ, সঠিক আকাৰৰ মেপিং।কৃষক আৰু ব্যৱসায়ীৰ বাবে শস্যৰ আকাৰ গণনা কৰিবলৈ অতি ভাল এপ।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "etu app doh beshi bahl ase sabzi kan nap lo boley aru mapping kan kory boley. beshi bhal ase bostimnau kan karney aru bekiry kory boley.",
            },
            21: {
                language: "Khasi",
                translation:
                    "kane ba biang na bynta ka jingthew jong ki marrep mar riang ,ka jingbatai ia ki map ba biang.Ka App ka ba bha na bynta ki nongrep bad ki nongkhaii ban khein ia ka jingheh u jingthung.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Hei hi agri cropsize tehna atan apps tha tak a ni a, size mapping dik tak a ni.Lo neitu leh sumdawngte tan thlai chi hrang hrang tehna atana app tha tak a ni.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Masigi app asi loubaga mari leinaba pambi size onba yai, chap chaba size piba ngammi. Loumi amadi traders singgi yamna phaba app ni amadi pambi size su khangba yai.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "કૃષિ પાકના કદના માપન, સચોટ માપ મેપિંગ માટે આ સરસ એપ્લિકેશન છે. ખેડૂતો અને વેપારીઓ માટે પાકના કદની ગણતરી કરવા માટે ખૂબ જ સારી એપ્લિકેશન છે.",
            },
            17: {
                language: "Tamil",
                translation:
                    "வேளாண் பயிர்களின் அளவை அளவிடுவதற்கும், துல்லியமான அளவு மேப்பிங்கிற்கும் இது ஒரு நல்ல பயன்பாடு ஆகும். விவசாயிகள் மற்றும் வியாபாரிகளுக்கு பயிர் அளவைக் கணக்கிடுவதற்கு மிகச் சிறந்த பயன்பாடு.",
            },
            5: {
                language: "Telugu",
                translation:
                    "వ్యవసాయ పంటల పరిమాణాన్ని కొలవడానికి, కచ్చితమైన సైజు మ్యాపింగ్ కోసం ఇది చక్కని యాప్‌లు. రైతులు మరియు వ్యాపారులు పంట పరిమాణాన్ని లెక్కించేందుకు చాలా మంచి యాప్.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "കാർഷിക വിളകളുടെ വലുപ്പം അളക്കുന്നതിനും കൃത്യമായ വലിപ്പം മാപ്പിംഗ് ചെയ്യുന്നതിനുമുള്ള നല്ല ആപ്ലിക്കേഷനാണിത്. കർഷകർക്കും വ്യാപാരികൾക്കും വിളയുടെ വലുപ്പം കണക്കാക്കാൻ വളരെ നല്ല ആപ്പ്.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಇದು ಕೃಷಿ ಬೆಳೆಗಳ ಅಳತೆ, ನಿಖರವಾದ ಗಾತ್ರದ ಮ್ಯಾಪಿಂಗ್‌ಗೆ ಉತ್ತಮವಾದ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು. ರೈತರು ಮತ್ತು ವ್ಯಾಪಾರಿಗಳಿಗೆ ಬೆಳೆಯ ಗಾತ್ರವನ್ನು ಲೆಕ್ಕಾಚಾರ ಮಾಡಲು ಉತ್ತಮ ಅಪ್ಲಿಕೇಶನ್.",
            },
        },
        default:
            "This is nice apps for measurerment of agri cropsize, accurate size mapping.Very good app for farmers and traders to calculate the size of crop.",
    },
    downloadApp: {
        languages: {
            1: {
                language: "English",
                translation: "Download our App",
            },
            2: {
                language: "hindi",
                translation: "हमारा ऐप डाउनलोड करें",
            },
            3: {
                language: "Marathi",
                translation: "आमचे ऐप डाउनलोड करा",
            },
            7: {
                language: "Punjabi",
                translation: "ਸਾਡੀ ਐਪ ਨੂੰ ਡਾਉਨਲੋਡ ਕਰੋ",
            },
            6: {
                language: "Bengali",
                translation: "আমাদের অ্যাপ ডাউনলোড করুন",
            },
            14: {
                language: "Assamese",
                translation: "আমাৰ App ডাউনলোড কৰক",
            },
            18: {
                language: "Nagamese",
                translation: "moika laka app download kory bhi",
            },
            21: {
                language: "Khasi",
                translation: "Download ia ka APP jong ngi",
            },
            20: {
                language: "Mizo",
                translation: "Kan App Download rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Download toubiyu eikhoigi app",
            },
            8: {
                language: "Gujarati",
                translation: "અમારી એપ ડાઉનલોડ કરો",
            },
            17: {
                language: "Tamil",
                translation: "எங்கள் பயன்பாட்டைப் பதிவிறக்கவும்",
            },
            5: {
                language: "Telugu",
                translation: "మా యాప్‌ను డౌన్‌లోడ్ చేయండి",
            },
            10: {
                language: "Malayalam",
                translation: "ഞങ്ങളുടെ ആപ്പ് ഡൗൺലോഡ് ചെയ്യുക",
            },
            4: {
                language: "Kannada",
                translation: "ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
            },
        },
        default: "Download our App",
    },
    bottomlogotext: {
        languages: {
            1: {
                language: "English",
                translation: "Mera Gaon is a community of, for and by rural India.",
            },
            2: {
                language: "hindi",
                translation: "मेरा गांव ग्रामीण भारत का, उसके लिए और उसके द्वारा समुदाय है।",
            },
            3: {
                language: "Marathi",
                translation: "मेरा गाव हा ग्रामीण भारतातील, साठी आणि द्वारे असलेला समुदाय आहे.",
            },
            7: {
                language: "Punjabi",
                translation: "ਮੇਰਾ ਗਾਓਂ ਪੇਂਡੂ ਭਾਰਤ ਦਾ, ਲਈ ਅਤੇ ਦੁਆਰਾ ਇੱਕ ਭਾਈਚਾਰਾ ਹੈ।",
            },
            6: {
                language: "Bengali",
                translation: "মেরা গাঁও গ্রামীণ ভারতের একটি সম্প্রদায়।",
            },
            14: {
                language: "Assamese",
                translation: "মেৰা গাঁও হৈছে গ্ৰাম্য ভাৰতৰ, ভাৰতৰ বাবে আৰু ভাৰতৰ দ্বাৰা এক সম্প্ৰদায়।",
            },
            18: {
                language: "Nagamese",
                translation: "Mera Gaon doh aru community ase rural India bara",
            },
            21: {
                language: "Khasi",
                translation: "Mera Gaon ka dei ka community jong,nabynta bad da ka rural India.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Mera Gaon hi India thingtlang khawtlang, India ram tan leh India ram tana khawtlang a ni.",
            },
            19: {
                language: "Manipuri",
                translation: "Mera Gaon haibasi meeyamgi oiba, amadi rural India kayagini.",
            },
            8: {
                language: "Gujarati",
                translation: "મેરા ગાંવ એ ગ્રામીણ ભારત માટે અને દ્વારા એક સમુદાય છે.",
            },
            17: {
                language: "Tamil",
                translation: "மேரா காவ்ன் என்பது கிராமப்புற இந்தியாவின் ஒரு சமூகமாகும்.",
            },
            5: {
                language: "Telugu",
                translation: "మేరా గావ్ అనేది గ్రామీణ భారతదేశం కోసం మరియు వారిచే ఒక సంఘం.",
            },
            10: {
                language: "Malayalam",
                translation: "മേരാ ഗാവ് ഗ്രാമീണ ഇന്ത്യയിലെ ഒരു സമൂഹമാണ്.",
            },
            4: {
                language: "Kannada",
                translation: "ಮೇರಾ ಗಾಂವ್ ಗ್ರಾಮೀಣ ಭಾರತದ ಸಮುದಾಯವಾಗಿದೆ.",
            },
        },
        default: "Mera Gaon is a community of, for and by rural India.",
    },
    email: {
        languages: {
            1: {
                language: "English",
                translation: "Email",
            },
            2: {
                language: "hindi",
                translation: "ई-मेल ",
            },
            3: {
                language: "Marathi",
                translation: "ई-मेल ",
            },
            7: {
                language: "Punjabi",
                translation: "ਈ-ਮੇਲ",
            },
            6: {
                language: "Bengali",
                translation: "ইমেইল",
            },
            14: {
                language: "Assamese",
                translation: "ইমেইল",
            },
            18: {
                language: "Nagamese",
                translation: "Email",
            },
            21: {
                language: "Khasi",
                translation: "Email",
            },
            20: {
                language: "Mizo",
                translation: "Email",
            },
            19: {
                language: "Manipuri",
                translation: "Email",
            },
            8: {
                language: "Gujarati",
                translation: "ઈમેલ",
            },
            17: {
                language: "Tamil",
                translation: "மின்னஞ்சல்",
            },
            5: {
                language: "Telugu",
                translation: "ఇమెయిల్",
            },
            10: {
                language: "Malayalam",
                translation: "ഇമെയിൽ",
            },
            4: {
                language: "Kannada",
                translation: "ಇಮೇಲ್",
            },
        },
        default: "Email",
    },
    mgMenu: {
        languages: {
            1: {
                language: "English",
                translation: "Mera Gaon",
            },
            2: {
                language: "hindi",
                translation: "मेरा गाँव ",
            },
            3: {
                language: "Marathi",
                translation: "मेरा गाव",
            },
            7: {
                language: "Punjabi",
                translation: "ਮੇਰਾ ਗਾਓਂ",
            },
            6: {
                language: "Bengali",
                translation: "মেরা গাঁও",
            },
            14: {
                language: "Assamese",
                translation: "মেৰা গাওঁ",
            },
            18: {
                language: "Nagamese",
                translation: "Mera Gaon",
            },
            21: {
                language: "Khasi",
                translation: "Mera Gaon",
            },
            20: {
                language: "Mizo",
                translation: "Mera Gaon",
            },
            19: {
                language: "Manipuri",
                translation: "Mera Gaon",
            },
            8: {
                language: "Gujarati",
                translation: "મેરા ગાંવ",
            },
            17: {
                language: "Tamil",
                translation: "மேரா காவ்ன்",
            },
            5: {
                language: "Telugu",
                translation: "మేరా గావ్",
            },
            10: {
                language: "Malayalam",
                translation: "മേരാ ഗാവ്",
            },
            4: {
                language: "Kannada",
                translation: "ಮೇರಾ ಗಾಂವ್",
            },
        },
        default: "Mera Gaon",
    },
    home: {
        languages: {
            1: {
                language: "English",
                translation: "Home",
            },
            2: {
                language: "hindi",
                translation: "होम",
            },
            3: {
                language: "Marathi",
                translation: "मुख्यपृष्ठ",
            },
            7: {
                language: "Punjabi",
                translation: "ਘਰ",
            },
            6: {
                language: "Bengali",
                translation: "বাড়ি",
            },
            14: {
                language: "Assamese",
                translation: "গৃহ",
            },
            18: {
                language: "Nagamese",
                translation: "Kor",
            },
            21: {
                language: "Khasi",
                translation: "Iing",
            },
            20: {
                language: "Mizo",
                translation: "In",
            },
            19: {
                language: "Manipuri",
                translation: "Yum",
            },
            8: {
                language: "Gujarati",
                translation: "ઘર",
            },
            17: {
                language: "Tamil",
                translation: "வீடு",
            },
            5: {
                language: "Telugu",
                translation: "హోమ్",
            },
            10: {
                language: "Malayalam",
                translation: "വീട്",
            },
            4: {
                language: "Kannada",
                translation: "ಮುಖಪುಟ",
            },
        },
        default: "home",
    },
    asp: {
        languages: {
            1: {
                language: "English",
                translation: "Apnon Se Poocho",
            },
            2: {
                language: "hindi",
                translation: "अपनों से पूछो",
            },
            3: {
                language: "Marathi",
                translation: "आपनो से पुछो",
            },
            7: {
                language: "Punjabi",
                translation: "ਆਪਨ ਸੇ ਪੂਛੋ",
            },
            6: {
                language: "Bengali",
                translation: "আপন সে পুছো",
            },
            14: {
                language: "Assamese",
                translation: "আপোনজনক সুধিব",
            },
            18: {
                language: "Nagamese",
                translation: "Apnon Se Poocho",
            },
            21: {
                language: "Khasi",
                translation: "Apnon Se Poocho",
            },
            20: {
                language: "Mizo",
                translation: "Apnon Se Poocho",
            },
            19: {
                language: "Manipuri",
                translation: "Apnon Se Poocho",
            },
            8: {
                language: "Gujarati",
                translation: "અપનોં સે પૂછો",
            },
            17: {
                language: "Tamil",
                translation: "அப்னோன் சே பூச்சோ",
            },
            5: {
                language: "Telugu",
                translation: "అప్నోన్ సే పూచో",
            },
            10: {
                language: "Malayalam",
                translation: "അപ്നോൻ സേ പൂച്ചോ",
            },
            4: {
                language: "Kannada",
                translation: "ಅಪ್ನೋನ್ ಸೆ ಪೂಚೋ",
            },
        },
        default: "Apnon Se Poocho",
    },
    careers: {
        languages: {
            1: {
                language: "English",
                translation: "Careers",
            },
            2: {
                language: "hindi",
                translation: "करियर",
            },
            3: {
                language: "Marathi",
                translation: "करिअर",
            },
            7: {
                language: "Punjabi",
                translation: "ਕਰੀਅਰ",
            },
            6: {
                language: "Bengali",
                translation: "কেরিয়ার",
            },
            14: {
                language: "Assamese",
                translation: "কেৰিয়াৰ",
            },
            18: {
                language: "Nagamese",
                translation: "Careers",
            },
            21: {
                language: "Khasi",
                translation: "Ka thong jingim",
            },
            20: {
                language: "Mizo",
                translation: "Careers",
            },
            19: {
                language: "Manipuri",
                translation: "Careers",
            },
            8: {
                language: "Gujarati",
                translation: "કારકિર્દી",
            },
            17: {
                language: "Tamil",
                translation: "தொழில்",
            },
            5: {
                language: "Telugu",
                translation: "కెరీర్లు",
            },
            10: {
                language: "Malayalam",
                translation: "തൊഴിലവസരങ്ങൾ",
            },
            4: {
                language: "Kannada",
                translation: "ವೃತ್ತಿಗಳು",
            },
        },
        default: "Careers",
    },
    legal: {
        languages: {
            1: {
                language: "English",
                translation: "Legal",
            },
            2: {
                language: "hindi",
                translation: "कानूनी",
            },
            3: {
                language: "Marathi",
                translation: "कायदेशीर",
            },
            7: {
                language: "Punjabi",
                translation: "ਕਾਨੂੰਨੀ",
            },
            6: {
                language: "Bengali",
                translation: "লেগা",
            },
            14: {
                language: "Assamese",
                translation: "লেগা",
            },
            18: {
                language: "Nagamese",
                translation: "hosa ase",
            },
            21: {
                language: "Khasi",
                translation: "Badei katkum ka Ain",
            },
            20: {
                language: "Mizo",
                translation: "dan in a phal",
            },
            19: {
                language: "Manipuri",
                translation: "Ain na yaba",
            },
            8: {
                language: "Gujarati",
                translation: "કાયદેસર",
            },
            17: {
                language: "Tamil",
                translation: "சட்டபூர்வமான",
            },
            5: {
                language: "Telugu",
                translation: "చట్టపరమైన",
            },
            10: {
                language: "Malayalam",
                translation: "നിയമപരമായ",
            },
            4: {
                language: "Kannada",
                translation: "ಕಾನೂನುಬದ್ಧ",
            },
        },
        default: "Legal",
    },
    copyright: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
            },
            2: {
                language: "hindi",
                translation:
                    "कॉपीराइट © 2023 मेरा गांव (एडिसन रूरल प्लेटफॉर्म्स इंडिया प्राइवेट लिमिटेड)। सर्वाधिकार सुरक्षित।",
            },
            3: {
                language: "Marathi",
                translation:
                    "Copyright © 2023 मेरा गाव (Adisan Rural Platforms India Private Limited). सर्व हक्क राखीव.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਕਾਪੀਰਾਈਟ © 2023 ਮੇਰਾ ਗਾਓਂ (ਐਡੀਸਨ ਰੂਰਲ ਪਲੇਟਫਾਰਮਸ ਇੰਡੀਆ ਪ੍ਰਾਈਵੇਟ ਲਿਮਿਟੇਡ)। ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ.",
            },
            6: {
                language: "Bengali",
                translation:
                    "কপিরাইট © 2023 মেরা গাঁও (Adisan Rural Platforms India Private Limited)। সমস্ত অধিকার সংরক্ষিত.",
            },
            14: {
                language: "Assamese",
                translation:
                    "কপিৰাইট © ২০২৩ মেৰা গাওঁ (আদিছান ৰুৰেল প্লেটফৰ্মছ ইণ্ডিয়া প্ৰাইভেট লিমিটেড)। সকলো অধিকাৰ সংৰক্ষিত।",
            },
            18: {
                language: "Nagamese",
                translation:
                    "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
            },
            21: {
                language: "Khasi",
                translation:
                    "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "કોપીરાઈટ © 2023 મેરા ગાંવ (એડીસાન રૂરલ પ્લેટફોર્મ ઈન્ડિયા પ્રાઈવેટ લિમિટેડ).  બધા હકો અમારી પાસે રાખેલા છે.",
            },
            17: {
                language: "Tamil",
                translation:
                    "பதிப்புரிமை © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை.",
            },
            5: {
                language: "Telugu",
                translation:
                    "కాపీరైట్ © 2023 మేరా గావ్ (అడిసన్ రూరల్ ప్లాట్‌ఫారమ్స్ ఇండియా ప్రైవేట్ లిమిటెడ్). సర్వ హక్కులు ప్రత్యేకించబడినవి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "പകർപ്പവകാശം © 2023 മേരാ ഗാവ് (ആദിസാൻ റൂറൽ പ്ലാറ്റ്‌ഫോംസ് ഇന്ത്യ പ്രൈവറ്റ് ലിമിറ്റഡ്). എല്ലാ അവകാശങ്ങളും നിക്ഷിപ്തം.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಕೃತಿಸ್ವಾಮ್ಯ © 2023 ಮೇರಾ ಗಾಂವ್ (ಆಡಿಸನ್ ರೂರಲ್ ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ಸ್ ಇಂಡಿಯಾ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್).  ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ.",
            },
        },
        default: "Copyright © 2023 Mera Gaon (Adisan Rural Platforms India Private Limited). All Rights Reserved.",
    },
    mgSikkaIntro: {
        languages: {
            1: {
                language: "English",
                translation: "Introducing MG Sikka",
            },
            2: {
                language: "hindi",
                translation: "पेश है एमजी सिक्का",
            },
            3: {
                language: "Marathi",
                translation: "एमजी सिक्का यांचा परिचय",
            },
            7: {
                language: "Punjabi",
                translation: "ਪੇਸ਼ ਹਨ ਐਮਜੀ ਸਿੱਕਾ",
            },
            6: {
                language: "Bengali",
                translation: "এমজি সিক্কার সাথে পরিচয়",
            },
            14: {
                language: "Assamese",
                translation: "এম জি ছিক্কাক চিনাকি কৰাই দিছো",
            },
            18: {
                language: "Nagamese",
                translation: "MG Sikka dekai ase",
            },
            21: {
                language: "Khasi",
                translation: "Introducing MG Sikka",
            },
            20: {
                language: "Mizo",
                translation: "MG Sikka inhmelhriattir",
            },
            19: {
                language: "Manipuri",
                translation: "MG Sikka asi mashak takpiyu",
            },
            8: {
                language: "Gujarati",
                translation: "એમજી સિક્કાનો પરિચય",
            },
            17: {
                language: "Tamil",
                translation: "எம்.ஜி.சிக்காவை அறிமுகப்படுத்துகிறோம்",
            },
            5: {
                language: "Telugu",
                translation: "MG సిక్కాను పరిచయం చేస్తున్నాము",
            },
            10: {
                language: "Malayalam",
                translation: "എംജി സിക്കയെ പരിചയപ്പെടുത്തുന്നു",
            },
            4: {
                language: "Kannada",
                translation: " ಎಂಜಿ ಸಿಕ್ಕಾ ವನ್ನು ಪರಿಚಯಿಸಲಾಗುತ್ತಿದೆ",
            },
        },
        default: "Introducing MG Sikka",
    },
    mgSikkaIntroDetails: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Complete different activities on the Mera Gaon App and win MG Sikka, our very own in app currency. Collect enough MG Sikka and use them for mobile recharges and other prizes.",
            },
            2: {
                language: "hindi",
                translation:
                    "मेरा गांव ऐप पर विभिन्न गतिविधियां पूरी करें और एमजी सिक्का जीतें, जो हमारी अपनी ऐप मुद्रा है। पर्याप्त एमजी सिक्का इकट्ठा करें और उनका उपयोग मोबाइल रिचार्ज और अन्य पुरस्कारों के लिए करें।",
            },
            3: {
                language: "Marathi",
                translation:
                    "मेरा गाव अॅपवर विविध क्रियाकलाप पूर्ण करा आणि अप चलनात आमची स्वतःची असलेली एमजी सिक्का जिंका. पुरेशी एम जी सिक्का गोळा करा आणि मोबाईल रिचार्ज आणि इतर बक्षिसांसाठी त्यांचा वापर करा.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਮੇਰਾ ਗਾਓਂ ਐਪ 'ਤੇ ਵੱਖ-ਵੱਖ ਗਤੀਵਿਧੀਆਂ ਨੂੰ ਪੂਰਾ ਕਰੋ ਅਤੇ ਐਮਜੀ ਸਿੱਕਾ ਨੂੰ ਜਿੱਤੋ, ਸਾਡੀ ਆਪਣੀ ਐਪ ਮੁਦਰਾ ਵਿੱਚ। ਕਾਫ਼ੀ MG ਸਿੱਕਾ ਇਕੱਠਾ ਕਰੋ ਅਤੇ ਮੋਬਾਈਲ ਰੀਚਾਰਜ ਅਤੇ ਹੋਰ ਇਨਾਮਾਂ ਲਈ ਉਹਨਾਂ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "মেরা গাঁও অ্যাপে বিভিন্ন ক্রিয়াকলাপ সম্পূর্ণ করুন এবং জিতে নিন এমজি সিক্কা, অ্যাপের মুদ্রায় আমাদের নিজস্ব। পর্যাপ্ত MG Sikka সংগ্রহ করুন এবং মোবাইল রিচার্জ এবং অন্যান্য পুরস্কারের জন্য ব্যবহার করুন।",
            },
            14: {
                language: "Assamese",
                translation:
                    "মেৰা গাওঁ এপত বিভিন্ন কাৰ্য্যকলাপ সম্পূৰ্ণ কৰক আৰু এপ মুদ্ৰাত আমাৰ নিজৰ এম জি ছিক্কা জিকিব। যথেষ্ট পৰিমাণৰ এম জি ছিক্কা সংগ্ৰহ কৰি মোবাইল ৰিচাৰ্জ আৰু অন্যান্য পুৰস্কাৰৰ বাবে ব্যৱহাৰ কৰক",
            },
            18: {
                language: "Nagamese",
                translation:
                    "kotom kory bhi alak kam kan Mera Gaon App deh aru jeti bhi MG Sikka,moi kan laka app poisa bara. jama kory bhi beshi MG Sikka aru jolai bhi mobile recharges kan karney aru dusra prizes kan.",
            },
            21: {
                language: "Khasi",
                translation:
                    "Pyndep bun ki activities ha ka Mera Gaon App bad jop   MG Sikka, ka app jong ngi ha ka  currency. Pyndap shuh ia ka MG Sikka bad pyndonkam ia ki na bynta mobile recharges bad ki wei ki prize.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Mera Gaon App-ah hian activity hrang hrang ti zo la, kan app pawisa kan ta ngei MG Sikka chu dawng rawh. MG Sikka ti tling khawm la, mobile recharge leh lawmman dang atan hmang rawh.",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Mera Gaon app asida makhal kayagi activity loisallu aduga MG Sikka ngamnaba,eikhoi esagi oiba currency asida. MG Sikka ayamba loisanbiyu aduga phone recharge toubada amadi atoppa kayada sijinabasu yagani",
            },
            8: {
                language: "Gujarati",
                translation:
                    "મેરા ગાંવ એપ પર વિવિધ પ્રવૃત્તિઓ પૂર્ણ કરો અને એમજી સિક્કા જીતો, જે એપ ચલણમાં આપણી પોતાની છે.  પર્યાપ્ત MG Sikka એકત્રિત કરો અને તેનો મોબાઈલ રિચાર્જ અને અન્ય ઈનામો માટે ઉપયોગ કરો.",
            },
            17: {
                language: "Tamil",
                translation:
                    "மேரா காவ்ன் செயலியில் பல்வேறு செயல்பாடுகளை முடித்து, பயன்பாட்டு நாணயத்தில் எங்களுடைய சொந்தமான எம்ஜி சிக்காவை வெல்லுங்கள். போதுமான எம்ஜி சிக்காவை சேகரித்து மொபைல் ரீசார்ஜ்கள் மற்றும் பிற பரிசுகளுக்கு பயன்படுத்தவும்.",
            },
            5: {
                language: "Telugu",
                translation:
                    "మేరా గావ్ యాప్‌లో విభిన్న కార్యకలాపాలను పూర్తి చేయండి మరియు యాప్ కరెన్సీలో మా స్వంతమైన MG సిక్కాను గెలుచుకోండి. తగినంత MG సిక్కాను సేకరించి, మొబైల్ రీఛార్జ్‌లు మరియు ఇతర బహుమతుల కోసం వాటిని ఉపయోగించండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "മേരാ ഗാവ് ആപ്പിൽ വ്യത്യസ്‌ത പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കി ആപ്പ് കറൻസിയിൽ ഞങ്ങളുടെ സ്വന്തം എംജി സിക്കയെ വിജയിപ്പിക്കൂ. ആവശ്യത്തിന് എംജി സിക്ക ശേഖരിച്ച് മൊബൈൽ റീചാർജുകൾക്കും മറ്റ് സമ്മാനങ്ങൾക്കും ഉപയോഗിക്കുക.",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಮೇರಾ ಗಾಂವ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ವಿಭಿನ್ನ ಚಟುವಟಿಕೆಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ ಮತ್ತು ಅಪ್ಲಿಕೇಶನ್ ಕರೆನ್ಸಿಯಲ್ಲಿ ನಮ್ಮದೇ ಆದ MG ಸಿಕ್ಕಾವನ್ನು ಗೆಲ್ಲಿರಿ.  ಸಾಕಷ್ಟು ಎಂಜಿ ಸಿಕ್ಕಾವನ್ನು ಸಂಗ್ರಹಿಸಿ ಮತ್ತು ಅವುಗಳನ್ನು ಮೊಬೈಲ್ ರೀಚಾರ್ಜ್‌ಗಳು ಮತ್ತು ಇತರ ಬಹುಮಾನಗಳಿಗಾಗಿ ಬಳಸಿ.",
            },
        },
        default:
            "Complete different activities on the Mera Gaon App and win MG Sikka, our very own in app currency. Collect enough MG Sikka and use them for mobile recharges and other prizes.",
    },
    mgDostHeading: {
        languages: {
            1: {
                language: "English",
                translation: "Talk to your virtual friend, MG Dost!",
            },
            2: {
                language: "hindi",
                translation: "अपने आभासी मित्र एमजी दोस्त से बात करें!",
            },
            3: {
                language: "Marathi",
                translation: "तुमच्या व्हर्च्युअल मित्राशी बोला, एमजी दोस्त!",
            },
            7: {
                language: "Punjabi",
                translation: "ਆਪਣੇ ਵਰਚੁਅਲ ਦੋਸਤ, ਐਮਜੀ ਦੋਸਤ ਨਾਲ ਗੱਲ ਕਰੋ!",
            },
            6: {
                language: "Bengali",
                translation: "আপনার ভার্চুয়াল বন্ধু, এমজি দোস্তের সাথে কথা বলুন!",
            },
            14: {
                language: "Assamese",
                translation: "আপোনাৰ ভাৰ্চুৱেল বন্ধু এম জি ডষ্টৰ সৈতে কথা পাতক!",
            },
            18: {
                language: "Nagamese",
                translation: "apne laka virtual sathi ki kota kory bhi , MG Dost!",
            },
            21: {
                language: "Khasi",
                translation: "Kren ia virtual paralok jong phi, MG Dost!",
            },
            20: {
                language: "Mizo",
                translation: "I virtual friend, MG Dost nen inbiakna neihpui rawh!",
            },
            19: {
                language: "Manipuri",
                translation: "Wari sanabiyu sakudaba marup asiga, MG Dost!",
            },
            8: {
                language: "Gujarati",
                translation: "તમારા વર્ચ્યુઅલ મિત્ર, એમજી દોસ્ત સાથે વાત કરો!",
            },
            17: {
                language: "Tamil",
                translation: "உங்கள் மெய்நிகர் நண்பரான எம்ஜி தோஸ்த்திடம் பேசுங்கள்!",
            },
            5: {
                language: "Telugu",
                translation: "మీ వర్చువల్ స్నేహితుడు, MG దోస్త్‌తో మాట్లాడండి!",
            },
            10: {
                language: "Malayalam",
                translation: "നിങ്ങളുടെ വെർച്വൽ സുഹൃത്തായ എംജി ദോസ്‌തിനോട് സംസാരിക്കൂ!",
            },
            4: {
                language: "Kannada",
                translation: "ನಿಮ್ಮ ವರ್ಚುವಲ್ ಸ್ನೇಹಿತ, MG ದೋಸ್ತ್ ಜೊತೆ ಮಾತನಾಡಿ!",
            },
        },
        default: "Talk to your virtual friend, MG Dost!",
    },
    mgDostHeading1: {
        languages: {
            1: {
                language: "English",
                translation:
                    "Whether it be information about maintaining a healthy life, government schemes to benefit you or even what you can buy for your best friend’s birthday, MG Dost is here to answer any questions you might have!",
            },
            2: {
                language: "hindi",
                translation:
                    "चाहे वह स्वस्थ जीवन बनाए रखने के बारे में जानकारी हो, आपको लाभ पहुंचाने वाली सरकारी योजनाएं हों या यहां तक कि आप अपने सबसे अच्छे दोस्त के जन्मदिन के लिए क्या खरीद सकते हैं, एमजी दोस्त आपके किसी भी प्रश्न का उत्तर देने के लिए यहां है!",
            },
            3: {
                language: "Marathi",
                translation:
                    "आरोग्यदायी जीवन टिकवून ठेवण्याची माहिती असो, सरकारी योजना तुम्हाला लाभदायक असोत किंवा तुमच्या जिवलग मित्राच्या वाढदिवसानिमित्त तुम्ही काय खरेदी करू शकता, एम जी दोस्त तुमच्या कोणत्याही प्रश्नांची उत्तरे देण्यासाठी येथे आहे!",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਭਾਵੇਂ ਇਹ ਇੱਕ ਸਿਹਤਮੰਦ ਜੀਵਨ ਨੂੰ ਬਣਾਈ ਰੱਖਣ ਬਾਰੇ ਜਾਣਕਾਰੀ ਹੋਵੇ, ਤੁਹਾਨੂੰ ਲਾਭ ਪਹੁੰਚਾਉਣ ਵਾਲੀਆਂ ਸਰਕਾਰੀ ਸਕੀਮਾਂ ਜਾਂ ਇੱਥੋਂ ਤੱਕ ਕਿ ਤੁਸੀਂ ਆਪਣੇ ਸਭ ਤੋਂ ਚੰਗੇ ਦੋਸਤ ਦੇ ਜਨਮਦਿਨ ਲਈ ਕੀ ਖਰੀਦ ਸਕਦੇ ਹੋ, MG Dost ਤੁਹਾਡੇ ਕਿਸੇ ਵੀ ਸਵਾਲ ਦਾ ਜਵਾਬ ਦੇਣ ਲਈ ਇੱਥੇ ਹੈ!",
            },
            6: {
                language: "Bengali",
                translation:
                    "এটি একটি স্বাস্থ্যকর জীবন বজায় রাখার বিষয়ে তথ্য, আপনার উপকারের জন্য সরকারী স্কিম বা এমনকি আপনি আপনার সেরা বন্ধুর জন্মদিনের জন্য যা কিনতে পারেন সে সম্পর্কে তথ্যই হোক না কেন, আপনার যেকোনো প্রশ্নের উত্তর দিতে MG Dost এখানে আছে!",
            },
            14: {
                language: "Assamese",
                translation:
                    "সুস্থ জীৱন বজাই ৰখাৰ তথ্যই হওক, আপোনাৰ উপকাৰৰ বাবে চৰকাৰী আঁচনি হওক বা আনকি আপোনাৰ বেষ্ট ফ্ৰেণ্ডৰ জন্মদিনৰ বাবে আপুনি কি কিনিব পাৰে, আপোনাৰ যিকোনো প্ৰশ্নৰ উত্তৰ দিবলৈ এম জি ডষ্ট ইয়াত আছে!",
            },
            18: {
                language: "Nagamese",
                translation:
                    "etu jankare kan bhal kao kan, government schemes kan nahoi ley apne ki kine boley barey bo apne laka sathi karney, MG Dost ase yatey apne laka jawab deboley saval kan!",
            },
            21: {
                language: "Khasi",
                translation:
                    "La kane ka jingpyntip kadei shaphang ban pynbit ia ka jingim ba koit ba khiah,ki scheme sorkar ban iohnong ia phi lane wat ia kiba phi lah ban thied na bynta ka sngi kha ki paralok baieid jong phi, MG Dost ka don hangne ban jubab ia ki jingkylli ba phi don !",
            },
            20: {
                language: "Mizo",
                translation:
                    "Nun hrisel vawn chungchang thu emaw, i hlawkna tur sorkar ruahmanna emaw, i ṭhian ṭha ber piancham puala i lei theih tur emaw pawh ni se, MG Dost hian zawhna i neih apiang chhang turin a inpeih reng a ni!",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Hakchang phajana thamnabagi maramda oirasu, Sarkar scheme adomda kanaba ntraga adomgi best friend birthday oina leibada kanaba oiba, MG Dost asi leijare adomgi wahang khudinggi paokhum pinaba!",
            },
            8: {
                language: "Gujarati",
                translation:
                    "ભલે તે સ્વસ્થ જીવન જાળવવા વિશેની માહિતી હોય, તમને લાભ પહોંચાડવા માટેની સરકારી યોજનાઓ હોય અથવા તો તમે તમારા શ્રેષ્ઠ મિત્રના જન્મદિવસ માટે શું ખરીદી શકો છો, MG દોસ્ત તમારા કોઈપણ પ્રશ્નોના જવાબ આપવા માટે અહીં છે!",
            },
            17: {
                language: "Tamil",
                translation:
                    "அது ஆரோக்கியமான வாழ்க்கையைப் பராமரிப்பது பற்றிய தகவலாக இருந்தாலும், உங்களுக்குப் பலனளிக்கும் அரசாங்கத் திட்டங்கள் அல்லது உங்கள் சிறந்த நண்பரின் பிறந்தநாளுக்கு நீங்கள் எதை வாங்கலாம் என எதுவாக இருந்தாலும், உங்களுக்கு ஏதேனும் கேள்விகளுக்கு பதிலளிக்க MG தோஸ்த் இங்கே உள்ளது!",
            },
            5: {
                language: "Telugu",
                translation:
                    "ఆరోగ్యవంతమైన జీవితాన్ని కొనసాగించడం గురించిన సమాచారం, మీకు ప్రయోజనం చేకూర్చే ప్రభుత్వ పథకాలు లేదా మీ బెస్ట్ ఫ్రెండ్ పుట్టినరోజు కోసం మీరు ఏమి కొనుగోలు చేయవచ్చనే సమాచారం అయినా, మీకు ఏవైనా సందేహాలకు సమాధానం ఇవ్వడానికి MG దోస్త్ ఇక్కడ ఉంది!",
            },
            10: {
                language: "Malayalam",
                translation:
                    "അത് ആരോഗ്യകരമായ ജീവിതം നിലനിർത്തുന്നതിനെ കുറിച്ചുള്ള വിവരമോ, നിങ്ങൾക്ക് പ്രയോജനപ്പെടുന്ന സർക്കാർ പദ്ധതികളോ അല്ലെങ്കിൽ നിങ്ങളുടെ ഉറ്റസുഹൃത്തിന്റെ ജന്മദിനത്തിന് നിങ്ങൾക്ക് വാങ്ങാൻ കഴിയുന്നവയോ ആകട്ടെ, നിങ്ങൾക്ക് ഉണ്ടാകാവുന്ന ഏത് ചോദ്യങ്ങൾക്കും ഉത്തരം നൽകാൻ MG ദോസ്ത് ഇവിടെയുണ്ട്!",
            },
            4: {
                language: "Kannada",
                translation:
                    "ಅದು ಆರೋಗ್ಯಕರ ಜೀವನವನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳುವ ಬಗ್ಗೆ ಮಾಹಿತಿಯಾಗಿರಲಿ, ನಿಮಗೆ ಪ್ರಯೋಜನವಾಗಲು ಸರ್ಕಾರದ ಯೋಜನೆಗಳು ಅಥವಾ ನಿಮ್ಮ ಆತ್ಮೀಯ ಸ್ನೇಹಿತನ ಜನ್ಮದಿನದಂದು ನೀವು ಏನನ್ನು ಖರೀದಿಸಬಹುದು ಎಂಬುದಾಗಿರಲಿ, ನೀವು ಹೊಂದಿರುವ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು MG ದೋಸ್ತ್ ಇಲ್ಲಿದೆ!",
            },
        },
        default:
            "Whether it be information about maintaining a healthy life, government schemes to benefit you or even what you can buy for your best friend’s birthday, MG Dost is here to answer any questions you might have!",
    },
    mgDostHeading2: {
        languages: {
            1: {
                language: "English",
                translation:
                    "You can even customize how your MG Dost looks and choose from a variety of available avatars!",
            },
            2: {
                language: "hindi",
                translation:
                    "आप यह भी अनुकूलित कर सकते हैं कि आपका एमजी दोस्त कैसा दिखे और विभिन्न उपलब्ध अवतारों में से चुनें!",
            },
            3: {
                language: "Marathi",
                translation:
                    "तुमचा एम जी दोस्त कसा दिसतो ते तुम्ही सानुकूलित करू शकता आणि उपलब्ध असलेल्या विविध अवतारांमधून निवडू शकता!",
            },
            7: {
                language: "Punjabi",
                translation:
                    "ਤੁਸੀਂ ਇਹ ਵੀ ਅਨੁਕੂਲਿਤ ਕਰ ਸਕਦੇ ਹੋ ਕਿ ਤੁਹਾਡਾ MG Dost ਕਿਵੇਂ ਦਿਖਾਈ ਦਿੰਦਾ ਹੈ ਅਤੇ ਕਈ ਉਪਲਬਧ ਅਵਤਾਰਾਂ ਵਿੱਚੋਂ ਚੁਣ ਸਕਦੇ ਹੋ!",
            },
            6: {
                language: "Bengali",
                translation:
                    "আপনি এমনকি আপনার MG Dost দেখতে কেমন তা কাস্টমাইজ করতে পারেন এবং বিভিন্ন উপলব্ধ অবতার থেকে বেছে নিতে পারেন!",
            },
            14: {
                language: "Assamese",
                translation:
                    "আপুনি আনকি আপোনাৰ MG Dost কেনেকুৱা দেখা যায় তাক কাষ্টমাইজ কৰিব পাৰে আৰু বিভিন্ন উপলব্ধ অৱতাৰৰ পৰা বাছি ল’ব পাৰে!",
            },
            18: {
                language: "Nagamese",
                translation:
                    "apne customize kory boley barey apne laka how your MG Dost kinika deki boley mun aru chuni bhi eman beshi avatars kan taka bara!",
            },
            21: {
                language: "Khasi",
                translation: "Phi lah cutomize kumno ki MG Dost jong phi ki long bad jied bun kiba don avatar!",
            },
            20: {
                language: "Mizo",
                translation:
                    "I MG Dost hmel pawh i duh angin i siam thei a, avatar awm hrang hrang atang pawhin i thlang thei bawk!",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna MG Dost asigi oihaningba mashak oihanba yagani awatar makhal kaya amasu leigani!",
            },
            8: {
                language: "Gujarati",
                translation:
                    " તમે તમારા MG Dost કેવું દેખાય છે તે કસ્ટમાઇઝ પણ કરી શકો છો અને ઉપલબ્ધ વિવિધ અવતારમાંથી પસંદ કરી શકો છો!",
            },
            17: {
                language: "Tamil",
                translation:
                    "உங்கள் MG தோஸ்த் தோற்றத்தை நீங்கள் தனிப்பயனாக்கலாம் மற்றும் கிடைக்கக்கூடிய பல்வேறு அவதாரங்களில் இருந்து தேர்வு செய்யலாம்!",
            },
            5: {
                language: "Telugu",
                translation:
                    "మీరు మీ MG దోస్త్ ఎలా కనిపిస్తుందో కూడా అనుకూలీకరించవచ్చు మరియు అందుబాటులో ఉన్న వివిధ అవతార్‌ల నుండి ఎంచుకోవచ్చు!",
            },
            10: {
                language: "Malayalam",
                translation:
                    "നിങ്ങളുടെ MG ദോസ്ത് എങ്ങനെയുണ്ടെന്ന് നിങ്ങൾക്ക് ഇഷ്ടാനുസൃതമാക്കാനും ലഭ്യമായ വിവിധ അവതാറുകളിൽ നിന്ന് തിരഞ്ഞെടുക്കാനും കഴിയും!",
            },
            4: {
                language: "Kannada",
                translation:
                    " ನಿಮ್ಮ MG ದೋಸ್ತ್ ಹೇಗೆ ಕಾಣುತ್ತದೆ ಎಂಬುದನ್ನು ನೀವು ಕಸ್ಟಮೈಸ್ ಮಾಡಬಹುದು ಮತ್ತು ಲಭ್ಯವಿರುವ ವಿವಿಧ ಅವತಾರಗಳಿಂದ ಆಯ್ಕೆ ಮಾಡಬಹುದು!",
            },
        },
        default: "You can even customize how your MG Dost looks and choose from a variety of available avatars!",
    },
    mgJigsawHeading: {
        languages: {
            1: {
                language: "English",
                translation: "Play the MG Jigsaw daily!",
            },
            2: {
                language: "hindi",
                translation: "प्रतिदिन एमजी जिग्सॉ खेलें!",
            },
            3: {
                language: "Marathi",
                translation: "दररोज एमजी जिगसॉ खेळा!",
            },
            7: {
                language: "Punjabi",
                translation: "ਰੋਜ਼ਾਨਾ MG Jigsaw ਚਲਾਓ!",
            },
            6: {
                language: "Bengali",
                translation: " প্রতিদিন এমজি জিগস খেলুন!",
            },
            14: {
                language: "Assamese",
                translation: "দৈনিক এম জি জিগছ খেলক!",
            },
            18: {
                language: "Nagamese",
                translation: "kiley bhi MG Jigsaw hotai!",
            },
            21: {
                language: "Khasi",
                translation: "Lehkai ka MG Jigsaw man ka sngi!",
            },
            20: {
                language: "Mizo",
                translation: "Nitin MG Jigsaw chu khel rawh!",
            },
            19: {
                language: "Manipuri",
                translation: "Nungtigi MG Jigsaw asi sanabiyu!",
            },
            8: {
                language: "Gujarati",
                translation: "દરરોજ એમજી જીગ્સૉ વગાડો!",
            },
            17: {
                language: "Tamil",
                translation: "எம்ஜி ஜிக்சாவை தினமும் விளையாடுங்கள்!",
            },
            5: {
                language: "Telugu",
                translation: "ప్రతిరోజూ MG జా ప్లే చేయండి!",
            },
            10: {
                language: "Malayalam",
                translation: "ദിവസവും MG Jigsaw പ്ലേ ചെയ്യുക!",
            },
            4: {
                language: "Kannada",
                translation: "ಪ್ರತಿದಿನ ಎಂಜಿ ಜಿಗ್ಸಾ ಪ್ಲೇ ಮಾಡಿ!",
            },
        },
        default: "Play the MG Jigsaw daily!",
    },
    mgJigsawSubHeading: {
        languages: {
            1: {
                language: "English",
                translation: "Complete Jigsaw puzzles based on rural life as quickly as you can to win MG Sikka.",
            },
            2: {
                language: "hindi",
                translation:
                    "एमजी सिक्का जीतने के लिए जितनी जल्दी हो सके ग्रामीण जीवन पर आधारित जिग्सॉ पहेलियाँ पूरी करें।",
            },
            3: {
                language: "Marathi",
                translation: "एमजी सिक्का जिंकण्यासाठी ग्रामीण जीवनावर आधारित जिगसॉ पझल्स लवकरात लवकर पूर्ण करा.",
            },
            7: {
                language: "Punjabi",
                translation:
                    "MG ਸਿੱਕਾ ਨੂੰ ਜਿੱਤਣ ਲਈ ਜਿੰਨੀ ਜਲਦੀ ਹੋ ਸਕੇ ਪੇਂਡੂ ਜੀਵਨ 'ਤੇ ਆਧਾਰਿਤ ਜਿਗਸਾ ਪਹੇਲੀਆਂ ਨੂੰ ਪੂਰਾ ਕਰੋ।",
            },
            6: {
                language: "Bengali",
                translation:
                    "গ্রামীণ জীবনের উপর ভিত্তি করে জিগস পাজলগুলি সম্পূর্ণ করুন যত তাড়াতাড়ি আপনি এমজি সিক্কা জিততে পারেন।",
            },
            14: {
                language: "Assamese",
                translation:
                    "এম জি ছিক্কা জিকিবলৈ যিমান পাৰে সোনকালে গ্ৰাম্য জীৱনৰ ওপৰত ভিত্তি কৰি জিগছ পাজল সম্পূৰ্ণ কৰক।",
            },
            18: {
                language: "Nagamese",
                translation: "kotom kory bhi Jigsaw puzzles kan bosti kan laka  aru MG Sikka jeti boley.",
            },
            21: {
                language: "Khasi",
                translation:
                    "Pyndep ka  Jigsaw puzzles katkum ka jingim nongkyndong katba kloi ban jop ia ka MG Sikka.",
            },
            20: {
                language: "Mizo",
                translation:
                    "Thingtlang nun atanga Jigsaw puzzle te chu i theih ang anga rang thei ang berin tifel la, MG Sikka dawng rawh.",
            },
            19: {
                language: "Manipuri",
                translation: "Jigsaw puzzles asi athuba matamda loisanbiyu aduga MG Sikka kaya ama ngamnaba.",
            },
            8: {
                language: "Gujarati",
                translation:
                    "એમજી સિક્કા જીતવા માટે તમે બને તેટલી ઝડપથી ગ્રામીણ જીવન પર આધારિત જીગ્સૉ કોયડાઓ પૂર્ણ કરો.",
            },
            17: {
                language: "Tamil",
                translation:
                    "எம்ஜி சிக்காவை வெல்ல உங்களால் முடிந்தவரை விரைவாக கிராமப்புற வாழ்க்கையை அடிப்படையாகக் கொண்ட புதிர்களை முடிக்கவும்.",
            },
            5: {
                language: "Telugu",
                translation:
                    " MG సిక్కాను గెలవడానికి మీరు వీలైనంత త్వరగా గ్రామీణ జీవితం ఆధారంగా జిగ్సా పజిల్‌లను పూర్తి చేయండి.",
            },
            10: {
                language: "Malayalam",
                translation:
                    "എംജി സിക്കയെ വിജയിപ്പിക്കാൻ കഴിയുന്നത്ര വേഗത്തിൽ ഗ്രാമീണ ജീവിതത്തെ അടിസ്ഥാനമാക്കിയുള്ള ജിഗ്‌സ പസിലുകൾ പൂർത്തിയാക്കുക.",
            },
            4: {
                language: "Kannada",
                translation:
                    "MG ಸಿಕ್ಕಾವನ್ನು ಗೆಲ್ಲಲು ನೀವು ಸಾಧ್ಯವಾದಷ್ಟು ಬೇಗ ಗ್ರಾಮೀಣ ಜೀವನವನ್ನು ಆಧರಿಸಿದ ಜಿಗ್ಸಾ ಒಗಟುಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
            },
        },
        default: "Complete Jigsaw puzzles based on rural life as quickly as you can to win MG Sikka.",
    },
    mgJigsawSubHeading2: {
        languages: {
            1: {
                language: "English",
                translation:
                    "You can also complete Jigsaws presented by brands to win amazing gifts/discounts from them!",
            },
            2: {
                language: "hindi",
                translation:
                    "आप ब्रांडों द्वारा प्रस्तुत जिग्स को भी पूरा कर सकते हैं और उनसे अद्भुत उपहार/छूट जीत सकते हैं!",
            },
            3: {
                language: "Marathi",
                translation:
                    "तुम्ही ब्रँडद्वारे सादर केलेले जिगसॉ पूर्ण करू शकता आणि त्यांच्याकडून अप्रतिम भेटवस्तू/सवलत मिळवू शकता!",
            },
            7: {
                language: "Punjabi",
                translation:
                    " ਤੁਸੀਂ ਉਨ੍ਹਾਂ ਤੋਂ ਸ਼ਾਨਦਾਰ ਤੋਹਫ਼ੇ/ਛੋਟ ਜਿੱਤਣ ਲਈ ਬ੍ਰਾਂਡਾਂ ਦੁਆਰਾ ਪੇਸ਼ ਕੀਤੇ ਜਿਗਸਾ ਨੂੰ ਵੀ ਪੂਰਾ ਕਰ ਸਕਦੇ ਹੋ!",
            },
            6: {
                language: "Bengali",
                translation:
                    "এছাড়াও আপনি ব্র্যান্ডের দ্বারা উপস্থাপিত জিগসগুলি সম্পূর্ণ করতে পারেন তাদের কাছ থেকে আশ্চর্যজনক উপহার/ডিসকাউন্ট জিততে!",
            },
            14: {
                language: "Assamese",
                translation:
                    "আপুনি ব্ৰেণ্ডসমূহে উপস্থাপন কৰা জিগছও সম্পূৰ্ণ কৰিব পাৰে যাতে তেওঁলোকৰ পৰা আচৰিত উপহাৰ/ৰেহাই লাভ কৰিব পাৰে!",
            },
            18: {
                language: "Nagamese",
                translation:
                    "apne bhi kotom kory boley barey bo Jigsaws brands kan laka aru jeti bhi enam/ discount kan daikan bara!",
            },
            21: {
                language: "Khasi",
                translation:
                    " Phi lah ruh ban pyndep ki You can also complete Jigsaws ba lah pyni iaphi da ki brand ban jop ki to  gift/discounts na ki !",
            },
            20: {
                language: "Mizo",
                translation:
                    "Brand hrang hrangte hnen atanga thilpek/discount tha tak tak dawng turin Jigsaws an rawn hlan te pawh i tifel thei bawk!",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Adomna jigsaw asi brand singgi matung innasu loisanba yagani aduga angakpa gift ntraga discount kaya phangnaba!",
            },
            8: {
                language: "Gujarati",
                translation:
                    "તમે બ્રાન્ડ્સ દ્વારા પ્રસ્તુત જીગ્સૉને તેમની પાસેથી આકર્ષક ભેટ/ડિસ્કાઉન્ટ જીતવા માટે પણ પૂર્ણ કરી શકો છો!",
            },
            17: {
                language: "Tamil",
                translation:
                    "பிராண்டுகளின் அற்புதமான பரிசுகள்/தள்ளுபடிகளை வெல்ல, வழங்கும் ஜிக்சாக்களையும் நீங்கள் முடிக்கலாம்!",
            },
            5: {
                language: "Telugu",
                translation:
                    " బ్రాండ్‌ల నుండి అద్భుతమైన బహుమతులు/తగ్గింపులను గెలుచుకోవడానికి మీరు అందించే జాలను కూడా పూర్తి చేయవచ్చు!",
            },
            10: {
                language: "Malayalam",
                translation:
                    " നിങ്ങൾക്ക് അവരിൽ നിന്ന് അതിശയകരമായ സമ്മാനങ്ങൾ/കിഴിവുകൾ നേടുന്നതിന് ബ്രാൻഡുകൾ അവതരിപ്പിക്കുന്ന Jigsaws പൂർത്തിയാക്കാനും കഴിയും!",
            },
            4: {
                language: "Kannada",
                translation:
                    " ಬ್ರಾಂಡ್‌ಗಳಿಂದ ಅದ್ಭುತವಾದ ಉಡುಗೊರೆಗಳು/ರಿಯಾಯಿತಿಗಳನ್ನು ಗೆಲ್ಲಲು ನೀವು ಪ್ರಸ್ತುತಪಡಿಸಿದ ಜಿಗ್ಸಾಗಳನ್ನು ಸಹ ಪೂರ್ಣಗೊಳಿಸಬಹುದು!",
            },
        },
        default: " You can also complete Jigsaws presented by brands to win amazing gifts/discounts from them!",
    },
    lottieSikkaTest: {
        languages: {
            1: {
                language: "English",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_ENG.json",
            },
            2: {
                language: "hindi",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_HINDI.json",
            },
            3: {
                language: "Marathi",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_MARATHI.json",
            },
            7: {
                language: "Punjabi",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_PUNJABI.json",
            },
            6: {
                language: "Bengali",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_BENGLA.json",
            },
            14: {
                language: "Assamese",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_ASSAMESE.json",
            },
            18: {
                language: "Nagamese",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_NAGAMESSE.json",
            },
            21: {
                language: "Khasi",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_KHASI.json",
            },
            20: {
                language: "Mizo",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_MIZO.json",
            },
            19: {
                language: "Manipuri",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_MANIPURI.json",
            },
            8: {
                language: "Gujarati",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_GUJRATI.json",
            },
            17: {
                language: "Tamil",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_TAMIL.json",
            },
            5: {
                language: "Telugu",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_TELUGU.json",
            },
            10: {
                language: "Malayalam",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_MALYALAM.json",
            },
            4: {
                language: "Kannada",
                translation: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_KANNADA.json",
            },
        },
        default: "https://storage.googleapis.com/iwh/websiteLottie/MGSIKKALOTTIE_ENG.json",
    },
    lottieSikkaProd: {
        languages: {
            1: {
                language: "English",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_ENG.json",
            },
            2: {
                language: "hindi",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_HINDI.json",
            },
            3: {
                language: "Marathi",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_MARATHI.json",
            },
            7: {
                language: "Punjabi",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_PUNJABI.json",
            },
            6: {
                language: "Bengali",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_BENGLA.json",
            },
            14: {
                language: "Assamese",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_ASSAMESE.json",
            },
            18: {
                language: "Nagamese",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_NAGAMESSE.json",
            },
            21: {
                language: "Khasi",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_KHASI.json",
            },
            20: {
                language: "Mizo",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_MIZO.json",
            },
            19: {
                language: "Manipuri",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_MANIPURI.json",
            },
            8: {
                language: "Gujarati",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_GUJRATI.json",
            },
            17: {
                language: "Tamil",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_TAMIL.json",
            },
            5: {
                language: "Telugu",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_TELUGU.json",
            },
            10: {
                language: "Malayalam",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_MALYALAM.json",
            },
            4: {
                language: "Kannada",
                translation: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_KANNADA.json",
            },
        },
        default: "https://storage.googleapis.com/lottie-website/MGSIKKALOTTIE_ENG.json",
    },
    playStoreText: {
        languages: {
            1: {
                language: "English",
                translation: "Trusted by {{1000000}}+ users.",
            },
            2: {
                language: "hindi",
                translation: "{{1000000}} से अधिक उपयोगकर्ताओं द्वारा विश्वसनीय।",
            },
            3: {
                language: "Marathi",
                translation: "{{1000000}}+ वापरकर्त्यांद्वारे विश्वसनीय।",
            },
            7: {
                language: "Punjabi",
                translation: "{{1000000}}+ ਉਪਭੋਗਤਾਵਾਂ ਦੁਆਰਾ ਭਰੋਸੇਯੋਗ।",
            },
            6: {
                language: "Bengali",
                translation: "{{1000000}}+ ব্যৱহাৰকাৰীৰ দ্বাৰা বিশ্বাস কৰা হৈছে।",
            },
            14: {
                language: "Assamese",
                translation: "{{1000000}}+ ব্যৱহাৰকাৰীৰ দ্বাৰা বিশ্বাস কৰা হৈছে।",
            },
            18: {
                language: "Nagamese",
                translation: "Trusted kory shai {{1000000}}+ jola manu bara.",
            },
            21: {
                language: "Khasi",
                translation: "Shaniah daki {{1000000}}+ nongpyndonkam.",
            },
            20: {
                language: "Mizo",
                translation: "{{1000000}}+ hmangtuten an rin.",
            },
            19: {
                language: "Manipuri",
                translation: "Thajaba mee {{1000000}}+ users.",
            },
            8: {
                language: "Gujarati",
                translation: "{{1000000}}+ વપરાશકર્તાઓ દ્વારા વિશ્વસનીય.",
            },
            17: {
                language: "Tamil",
                translation: "{{1000000}}+ பயனர்களால் நம்பப்படுகிறது.",
            },
            5: {
                language: "Telugu",
                translation: "{{1000000}}+ వినియోగదారులు విశ్వసించారు.",
            },
            10: {
                language: "Malayalam",
                translation: "{{1000000}}+ ഉപയോക്താക്കൾ വിശ്വസിച്ചു.",
            },
            4: {
                language: "Kannada",
                translation: "{{1000000}}+ ಬಳಕೆದಾರರಿಂದ ನಂಬಲಾಗಿದೆ.",
            },
        },
        default: "Trusted by {{10,00,000}}+ users.",
    },
    playStoreRate: {
        languages: {
            1: {
                language: "English",
                translation: "Rated",
            },
            2: {
                language: "hindi",
                translation: "रेटेड",
            },
            3: {
                language: "Marathi",
                translation: "रेटिंग आहे",
            },
            7: {
                language: "Punjabi",
                translation: "ਦਰਜਾ ਦਿੱਤਾ ਗਿਆ",
            },
            6: {
                language: "Bengali",
                translation: "রেট",
            },
            14: {
                language: "Assamese",
                translation: "ৰেটিং",
            },
            18: {
                language: "Nagamese",
                translation: "Rated",
            },
            21: {
                language: "Khasi",
                translation: "Lah ïtbha",
            },
            20: {
                language: "Mizo",
                translation: "Rated",
            },
            19: {
                language: "Manipuri",
                translation: "Rated",
            },
            8: {
                language: "Gujarati",
                translation: "રેટ કરેલ",
            },
            17: {
                language: "Tamil",
                translation: "மதிப்பிடப்பட்டது",
            },
            5: {
                language: "Telugu",
                translation: "రేటింగ్",
            },
            10: {
                language: "Malayalam",
                translation: "റേറ്റുചെയ്തത്",
            },
            4: {
                language: "Kannada",
                translation: "ರೇಟ್ ಮಾಡಲಾಗಿದೆ",
            },
        },
        default: "Rated",
    },
    // appDownloadButton: {
    //     languages: {
    //         1: {
    //             language: "English",
    //             translation: "aaaaaaa",
    //         },
    //         2: {
    //             language: "hindi",
    //             translation: "aaaaa",
    //         },
    //         3: {
    //             language: "Marathi",
    //             translation: "aaaaa",
    //         },
    //         7: {
    //             language: "Punjabi",
    //             translation: "aaaa",
    //         },
    //         6: {
    //             language: "Bengali",
    //             translation: "aaaaaa",
    //         },
    //         14: {
    //             language: "Assamese",
    //             translation: "aaaaa",
    //         },
    //         18: {
    //             language: "Nagamese",
    //             translation: "aaaa",
    //         },
    //         21: {
    //             language: "Khasi",
    //             translation: "aaaaa",
    //         },
    //         20: {
    //             language: "Mizo",
    //             translation: "aaaaaa",
    //         },
    //         19: {
    //             language: "Manipuri",
    //             translation: "aaaaa",
    //         },
    //         8: {
    //             language: "Gujarati",
    //             translation: "aaaaa",
    //         },
    //         17: {
    //             language: "Tamil",
    //             translation: "aaaaaa",
    //         },
    //         5: {
    //             language: "Telugu",
    //             translation: "aaaaaa",
    //         },
    //         10: {
    //             language: "Malayalam",
    //             translation: "aaaa",
    //         },
    //         4: {
    //             language: "Kannada",
    //             translation: "aaaaaa",
    //         },
    //     },
    //     default: "aaaaa",
    // },
};
export default translations;
