import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

//import './scss/bootstrap.scss';
import "./scss/index.scss";
import Home from "./components/pages/MeraGaonWebpage";
import Loading from "./components/common/PageLoader";
import CustomeRoute from "./components/common/CustomRoute";
import { Toaster } from "react-hot-toast";
import WeekActivity from "./components/pages/Week1Activity";
// const Home = lazy(() => import("./components/pages/MeraGaonWebpage/index"));
// const ApnonSepoocho = lazy(() => import("./components/pages/ApnonSePoocho/index"));
const ApnonSepoocho = lazy(() => import("./components/pages/NewAsp/index"));
const Careers = lazy(() => import("./components/pages/Careers/index"));
const Engineering = lazy(() => import("./components/pages/Engineering"));
const MeraGaon = lazy(() => import("./components/pages/MeraGaonWebpage/index"));
const ApnonSepoochoAskQuestion = lazy(() => import("./components/pages/ApnonSePoochoAskQuestion/index"));
const CampaignAddressLink = lazy(() => import("./components/pages/CampaignAddressLink/index"));
const FeedRoute = lazy(() => import("./components/pages/MeraGaon/Feed"));
const JobApply = lazy(() => import("./components/pages/JobApply"));
const SuccessJobs = lazy(() => import("./components/pages/JobSuccess"));
const Legal = lazy(() => import("./components/pages/LegalPage"));
const ProtectedRoutes = lazy(() => import("./protected/ProtectedRoutes"));
const VillagePhotoCard = lazy(() => import("./components/pages/MgVillagePhotoCard"));
const AccountDeletion = lazy(() => import("./components/pages/AccountDeletion/index"));
//const WeekActivity = lazy(() => import("./components/pages/Week1Activity"));
const FeedVideoRoute = lazy(() => import("./components/pages/MeraGaon/FeedVideo"));
const Survey = lazy(() => import("./components/pages/Survey"));
const App = () => {
    return (
        <div>
            <Toaster position="bottom-center" containerClassName="toaster-container" />
            <BrowserRouter>
                <Suspense fallback={<Loading />}>
                    <div>
                        <Switch>
                            <CustomeRoute exact path="/careers" component={Careers} />
                            <CustomeRoute exact path="/careers/engineering" component={Engineering} />
                            <CustomeRoute exact path="/apply/:jobID" component={JobApply} />
                            <Route path="/protected" component={ProtectedRoutes} />
                            <CustomeRoute exact path="/asp/ask-question" component={ApnonSepoochoAskQuestion} />
                            <CustomeRoute
                                exact
                                path="/asp/ask-question/:questionlanguage"
                                component={ApnonSepoochoAskQuestion}
                            />
                            <CustomeRoute exact path="/campaign/reward-form" component={CampaignAddressLink} />
                            <CustomeRoute exact path="/" component={Home} />
                            <Route path="/legal" component={Legal} />
                            <Route path="/audio-survey" component={Survey} />
                            <CustomeRoute exact path="/asp" component={ApnonSepoocho} />
                            <Route path="/account-deletion" component={AccountDeletion} />
                            <CustomeRoute exact path="/asp/:language" component={ApnonSepoocho} />
                            <CustomeRoute exact path="/:mglanguage" component={MeraGaon} />
                            <CustomeRoute path="/meragaon/feed" component={FeedRoute} />
                            <CustomeRoute exact path="/success/jobs" component={SuccessJobs} />
                            <CustomeRoute exact path="/meragaon/idcard" component={VillagePhotoCard} />
                            <Route exact path="/meragaon/week-activity" component={WeekActivity} />
                            <Route path="/meragaon/video" component={FeedVideoRoute} />
                        </Switch>
                    </div>
                </Suspense>
            </BrowserRouter>
        </div>
    );
};
export default App;
