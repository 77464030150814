import React, { useState, useEffect } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import MileImage4 from "./images/mile4.png";
import MileImage6 from "./images/rmile6.png";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
import {
    ButttonActionContainerRight2,
    ButttonActionContainerRight1,
    DayName,
    ActiveAction,
    ApprovalPendingAction,
    CompletedAction,
    UnCompletedAction,
    LockedAction,
    EarnRewardText,
    CanEarnRewardText,
} from "./util";
import Highlight from "./highlight";
import moment from "moment";
/**
 * @description Mera Gaon weekly activity
 * @author Suman Kumar (21-12-22)
 */
export default function Event7(props) {
    const { eventData, languageId, isScroll, noOfEvent } = props;
    let rewardEachEvent = props.rewardEachEvent;
    if (noOfEvent == 6) {
        rewardEachEvent = props.rewardEachEvent + 3;
    }
    const [viewEventDetail, setViewEventDetail] = useState(false);

    let params = null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
    params.id = params.id;

    function openAppAction(openEvent) {
        window.MeraGaon.navigate(openEvent);
    }
    //const eventStatus = eventData.status;
    let eventStatus = "";
    if (isScroll == 5) {
        eventStatus = eventData.status;
    }
    useEffect(() => {
        if (!params.id && eventStatus == "pending") {
            var access = document.getElementById(eventStatus);
            access.scrollIntoView({ behavior: "smooth" }, true);
        }
    });
    return (
        <React.Fragment>
            {noOfEvent === 6 ? (
                <ScrollableAnchor id={"event6"}>
                    <ButttonActionContainerRight2 id={eventStatus}>
                        {eventData.status == "pending" ? (
                            <ActiveAction style={{ textAlign: "right" }}>
                                <div
                                    style={{
                                        width: "80%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                    <button
                                        style={{ width: "auto", background: "transparent", boxShadow: "none" }}
                                        onClick={() => openAppAction(eventData.action)}>
                                        <Highlight marginLeft={"13px"} />
                                    </button>
                                    <button
                                        onClick={() => openAppAction(eventData.action)}
                                        className={
                                            eventData.status == "pending" || params.id == "2" || isScroll === 1
                                                ? "glow-on-hover"
                                                : ""
                                        }>
                                        <p>🕗 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        <CanEarnRewardText>
                                            {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </CanEarnRewardText>
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage4}></img>
                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </ActiveAction>
                        ) : eventData.status == "awaiting-approval" ? (
                            <ApprovalPendingAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "4" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>⌛ {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        {viewEventDetail ? (
                                            <span>
                                                {`${getTranslation(translations, "pendingApproval", languageId).replace(
                                                    "{{Date}}",
                                                    moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                                )}`}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage4}></img>
                                    <DayName style={{ right: "26%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </ApprovalPendingAction>
                        ) : eventData.status == "completed" ? (
                            <CompletedAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "4" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>🎉 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        <EarnRewardText>
                                            {getTranslation(translations, "wonMgSikka", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </EarnRewardText>
                                        {viewEventDetail ? (
                                            <span>
                                                {`${getTranslation(
                                                    translations,
                                                    "completeEventText",
                                                    languageId
                                                ).replace(
                                                    "{{Date}}",
                                                    moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                                )}`}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage4}></img>
                                    <DayName style={{ right: "26%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </CompletedAction>
                        ) : eventData.status == "expired" ? (
                            <UnCompletedAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "4" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #6a0808" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>😔 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        {viewEventDetail ? (
                                            <span>{getTranslation(translations, "missedEvent", languageId)}</span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage4}></img>
                                    <DayName style={{ right: "26%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </UnCompletedAction>
                        ) : eventData.status == "locked" ? (
                            <LockedAction style={{ textAlign: "right", flexDirection: "column" }}>
                                <div style={{ marginRight: "10px" }}>
                                    <img alt="" src={MileImage4}></img>
                                    <DayName style={{ right: "26%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                                <div style={{ width: "80%", textAlign: "center" }}>
                                    <button disabled={true} style={{ width: "100%" }}>
                                        🔓 {getTranslation(translations, "unlock", languageId)}{" "}
                                        {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}
                                        <CanEarnRewardText>
                                            {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </CanEarnRewardText>
                                    </button>
                                </div>
                                {/* <div>
                            <img alt="" src={MileImage4}></img>
                            <DayName style={{ right: "26%" }}>
                                {getTranslation(translations, "day", languageId)}
                            </DayName>
                        </div> */}
                            </LockedAction>
                        ) : null}
                    </ButttonActionContainerRight2>
                </ScrollableAnchor>
            ) : (
                <ScrollableAnchor id={"event6"}>
                    <ButttonActionContainerRight1 id={eventStatus}>
                        {eventData.status == "pending" ? (
                            <ActiveAction style={{ textAlign: "right" }}>
                                <div
                                    style={{
                                        width: "80%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                    <button
                                        style={{ width: "auto", background: "transparent", boxShadow: "none" }}
                                        onClick={() => openAppAction(eventData.action)}>
                                        <Highlight marginLeft={"13px"} />
                                    </button>
                                    <button
                                        onClick={() => openAppAction(eventData.action)}
                                        className={
                                            eventData.status == "pending" || params.id == "2" || isScroll === 1
                                                ? "glow-on-hover"
                                                : ""
                                        }>
                                        <p>🕗 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        <CanEarnRewardText>
                                            {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </CanEarnRewardText>
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage6}></img>

                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </ActiveAction>
                        ) : eventData.status == "awaiting-approval" ? (
                            <ApprovalPendingAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "2" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>⌛ {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        {viewEventDetail ? (
                                            <span>
                                                {`${getTranslation(translations, "pendingApproval", languageId).replace(
                                                    "{{Date}}",
                                                    moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                                )}`}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage6}></img>
                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </ApprovalPendingAction>
                        ) : eventData.status == "completed" ? (
                            <CompletedAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "2" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>🎉 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        <EarnRewardText>
                                            {getTranslation(translations, "wonMgSikka", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </EarnRewardText>
                                        {viewEventDetail ? (
                                            <span>
                                                {`${getTranslation(
                                                    translations,
                                                    "completeEventText",
                                                    languageId
                                                ).replace(
                                                    "{{Date}}",
                                                    moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                                )}`}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage6}></img>
                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </CompletedAction>
                        ) : eventData.status == "expired" ? (
                            <UnCompletedAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                                    <button
                                        // className={params.id == "2" ? "glow-on-hover" : ""}
                                        style={{
                                            width: "90%",
                                            border: `${viewEventDetail ? "1px solid #6a0808" : "none"}`,
                                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                        }}>
                                        <p>😔 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                        <p>{eventData.title}</p>
                                        {viewEventDetail ? (
                                            <span>{getTranslation(translations, "missedEvent", languageId)}</span>
                                        ) : (
                                            ""
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage6}></img>
                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </UnCompletedAction>
                        ) : eventData.status == "locked" ? (
                            <LockedAction style={{ textAlign: "right" }}>
                                <div style={{ width: "80%" }}>
                                    <button disabled={true}>
                                        🔓 {getTranslation(translations, "unlock", languageId)}{" "}
                                        {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}
                                        <CanEarnRewardText>
                                            {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                                "{{100}}",
                                                rewardEachEvent
                                            )}
                                        </CanEarnRewardText>
                                    </button>
                                </div>
                                <div>
                                    <img alt="" src={MileImage6}></img>
                                    <DayName style={{ right: "28%" }}>
                                        {getTranslation(translations, "day", languageId)}
                                    </DayName>
                                </div>
                            </LockedAction>
                        ) : null}
                    </ButttonActionContainerRight1>
                </ScrollableAnchor>
            )}
        </React.Fragment>
    );
}
