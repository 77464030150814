import React, { useState, useEffect } from "react";
import SikkaImage from "./Images/sikkawebpage2.png";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
import Image from "../../../elements/Image";
import styled from "styled-components";
import Lottie from "react-lottie";
/**
 * @author Suman Kumar, 27th Sept 2023
 **/
const LottieStyledComponent = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1440px;
    max-height: 354px;
    margin: 90px auto;

    @media (max-width: 1024px) {
    }
    @media (max-width: 780px) {
        margin: 40px auto;
    }
    @media (max-width: 680px) {
        margin: 50px auto;
    }
    @media (max-width: 480px) {
        margin: 40px auto;
    }
`;
export default function MGSikkaSection(props) {
    const { languageId } = props;
    const [animationData, setAnimationData] = useState(null);
    useEffect(() => {
        // Fetch the JSON data from the URL
        fetch(getTranslation(translations, "lottieSikkaProd", languageId))
            .then((response) => response.json())
            .then((data) => {
                setAnimationData(data);
            })
            .catch((error) => {
                console.error("Error fetching animation data:", error);
            });
    }, []);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div className="mg_sikka_section">
            <div className="meraGaon_sikkap_image_section">
                <h2>{getTranslation(translations, "mgSikkaIntro", languageId)}</h2>
                <p>{getTranslation(translations, "mgSikkaIntroDetails", languageId)}</p>
                {animationData ? (
                    <div>
                        <LottieStyledComponent>
                            <Lottie options={defaultOptions} style={{ width: "100%", height: "100%" }} />
                        </LottieStyledComponent>
                    </div>
                ) : (
                    <Image src={SikkaImage} alt="" />
                )}
            </div>
        </div>
    );
}
