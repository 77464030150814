/* eslint-disable */
import React from "react";
import { Route } from "react-router-dom";
import Aux from "./Auxillary";
import Header from "../elements/NewHeader";
import Footer from "../elements/Footer";
import { getURLParams } from "../pages/MeraGaon/Feed/page.util";
const languages = {
    english: 1,
    hindi: 2,
    marathi: 3,
    kannada: 4,
    telugu: 5,
    bengali: 6,
    punjabi: 7,
    gujarati: 8,
    // malay: 9,
    malayalam: 10,
    tamil: 17,
    assamese: 14,
    khasi: 21,
    mizo: 20,
    manipuri: 19,
    nagamese: 18,
};
const CustomeRoute = ({ component: Component, ...rest }) => {
    //let languageId = 1;
    const params = getURLParams();
    let languageId = params && params.l ? params.l : 1;
    try {
        const language = rest.computedMatch.params.mglanguage || rest.computedMatch.params.language;
        languageId = languages[language] || languageId;
    } catch (err) {
        console.log(err);
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <Aux>
                        <Header languageId={languageId} />
                        <Component {...rest} />
                        <Footer languageId={languageId} />
                    </Aux>
                );
            }}
        />
    );
};
export default CustomeRoute;
