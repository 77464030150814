const translations = {
    namaste: {
        languages: {
            "1": {
                language: "English",
                translation: "Namaste {{Username}}",
            },
            "2": {
                language: "Hindi",
                translation: "नमस्ते {{Username}} ",
            },
            "3": {
                language: "Marathi",
                translation: "नमस्ते {{Username}}",
            },
            "4": {
                language: "Kannada",
                translation: "ನಮಸ್ತೆ {{Username}}",
            },
            "5": {
                language: "Telugu",
                translation: "నమస్తే {{Username}}",
            },
            "6": {
                language: "Bengali",
                translation: "নমস্তে {{Username}} ",
            },
            "7": {
                language: "Punjabi",
                translation: "ਨਮਸਤੇ {{Username}}",
            },
            8: {
                language: "Gujarati",
                translation: "નમસ્તે {{Username}}",
            },
            "10": {
                language: "Malayalam",
                translation: "നമസ്തേ {{Username}}",
            },
            "17": {
                language: "Tamil",
                translation: "நமஸ்தே {{Username}}",
            },
            "14": {
                language: "Assamese",
                translation: "নমস্তে {{Username}}",
            },
            21: {
                language: "Khasi",
                translation: "Khublei {{Username}}  ",
            },
            20: {
                language: "Mizo",
                translation: "Chibai {{Username}}",
            },
            19: {
                language: "Manipuri",
                translation: "khurumjari {{Username}}",
            },
            18: {
                language: "Nagamese",
                translation: "Namaste {{Username}} ",
            },
        },
        default: "Namaste {{Username}}",
    },
    stepsFollow: {
        languages: {
            "1": {
                language: "English",
                translation:
                    " Welcome to Mera Gaon! Complete All the stages below during your Week 1 to get know our app better and win rewards!",
            },
            "2": {
                language: "Hindi",
                translation:
                    "मेरा गाँव में आपका स्वागत है! हमारे ऐप को बेहतर तरीके से जानने और पुरस्कार जीतने के लिए अपने सप्ताह 1 के दौरान नीचे दिए सभी चरणों को पूरा करें!",
            },
            "3": {
                language: "Marathi",
                translation:
                    "मेरा गाव मध्ये आपले स्वागत आहे! आमच्या ऐप अधिक चांगल्या प्रकारे जाणून घेण्यासाठी आणि बक्षिसे जिंकण्यासाठी तुमच्या 1 आठवड्यादरम्यान खालील सर्व टप्पे पूर्ण करा!",
            },
            "4": {
                language: "Kannada",
                translation:
                    "ಮೇರಾ ಗಾಂವ್‌ಗೆ ಸುಸ್ವಾಗತ! ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಚೆನ್ನಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲಲು ನಿಮ್ಮ 1 ನೇ ವಾರದಲ್ಲಿ ಕೆಳಗಿನ ಎಲ್ಲಾ ಹಂತಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ!",
            },
            "5": {
                language: "Telugu",
                translation:
                    "మేరా గావ్‌కు స్వాగతం! మా యాప్‌ను మరింత బాగా తెలుసుకోవడానికి మరియు రివార్డ్‌లను గెలుచుకోవడానికి మీ 1వ వారంలో దిగువన ఉన్న అన్ని దశలను పూర్తి చేయండి!",
            },
            "6": {
                language: "Bengali",
                translation:
                    " মেরা গাঁও স্বাগতম! আমাদের অ্যাপকে আরও ভালোভাবে জানতে এবং পুরস্কার জিততে আপনার সপ্তাহ 1-এ নীচের সমস্ত ধাপগুলি সম্পূর্ণ করুন!",
            },
            "7": {
                language: "Punjabi",
                translation:
                    "ਜੀ ਆਇਆਂ ਨੂੰ Mera Gaon ਜੀ! ਸਾਡੀ ਐਪ ਨੂੰ ਬਿਹਤਰ ਜਾਣਨ ਅਤੇ ਇਨਾਮ ਜਿੱਤਣ ਲਈ ਆਪਣੇ ਹਫ਼ਤੇ 1 ਦੌਰਾਨ ਹੇਠਾਂ ਦਿੱਤੇ ਸਾਰੇ ਪੜਾਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ!",
            },
            8: {
                language: "Gujarati",
                translation:
                    "મેરા ગાંવમાં આપનું સ્વાગત છે! અમારી એપ્લિકેશનને વધુ સારી રીતે જાણવા અને પુરસ્કારો જીતવા માટે તમારા અઠવાડિયા 1 દરમિયાન નીચેના તમામ તબક્કાઓ પૂર્ણ કરો!",
            },
            "10": {
                language: "Malayalam",
                translation:
                    "മേരാ ഗാവിലേക്ക് സ്വാഗതം! ഞങ്ങളുടെ ആപ്പിനെ നന്നായി അറിയുന്നതിനും റിവാർഡുകൾ നേടുന്നതിനും നിങ്ങളുടെ ഒന്നാം ആഴ്‌ചയിൽ ചുവടെയുള്ള എല്ലാ ഘട്ടങ്ങളും പൂർത്തിയാക്കുക!",
            },
            "17": {
                language: "Tamil",
                translation:
                    "மேரா கானுக்கு வரவேற்கிறோம்! எங்கள் பயன்பாட்டை நன்கு தெரிந்துகொள்ளவும் வெகுமதிகளை வெல்லவும் 1 வாரத்தில் கீழே உள்ள அனைத்து நிலைகளையும் முடிக்கவும்!",
            },
            "14": {
                language: "Assamese",
                translation:
                    "মেৰা গাওঁলৈ আপোনাক স্বাগতম! আমাৰ এপটো ভালদৰে জানিবলৈ আৰু পুৰস্কাৰ লাভ কৰিবলৈ আপোনাৰ ১ সপ্তাহৰ সময়ছোৱাত তলৰ সকলো পৰ্যায় সম্পূৰ্ণ কৰক!",
            },
            21: {
                language: "Khasi",
                translation:
                    "Welcome shaka Mera Gaon! Pyndep baroh ki stages harum hapoh ka 1 T-iew ban tip iaka app khambniah bad jop khusnam!",
            },
            20: {
                language: "Mizo",
                translation:
                    "Mera Gaon ah hian kan lo lawm a che! Kan app hi i hriat chian lehzual theih nan leh lawmman dawng turin a hnuaia stage zawng zawng hi i Week 1 chhung hian tifel vek rawh!",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Lengsanbiyu Mera Gaon asida! Adomgi week 1 asida stages khudingmak loisanbiyu loinana app asigi maramda henna khangnaba amadi reward phangnaba! ",
            },
            18: {
                language: "Nagamese",
                translation:
                    "ahi bhi Mera Gaon deh! kotom kory bhi sob stages neche deh taka apne laka 1 mhapta deh moikan laka app bhal bara jane boley aru jeti bhi enam kan!",
            },
        },
        default:
            " Welcome to Mera Gaon! Complete All the stages below during your Week 1 to get know our app better and win rewards!",
    },
    becameMGUser: {
        languages: {
            "1": {
                language: "English",
                translation: "Became Mera Gaon User",
            },
            "2": {
                language: "Hindi",
                translation: "मेरा गांव का यूजर बन गए",
            },
            "3": {
                language: "Marathi",
                translation: "मेरा गाव यूजर बनले",
            },
            "4": {
                language: "Kannada",
                translation: "ಮೇರಾ ಗಾಂವ್ ಬಳಕೆದಾರರಾದರು",
            },
            "5": {
                language: "Telugu",
                translation: "మేరా గావ్ యూజర్ అయ్యాడు",
            },
            "6": {
                language: "Bengali",
                translation: "মেরা গাঁও ব্যবহারকারী হয়েছেন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਮੇਰਾ ਗਾਓਂ ਯੂਜ਼ਰ ਬਣ ਗਿਆ",
            },
            8: {
                language: "Gujarati",
                translation: "મેરા ગાંવ યુઝર બની ગયા",
            },
            "10": {
                language: "Malayalam",
                translation: "മേരാ ഗാവ് ഉപയോക്താവായി",
            },
            "17": {
                language: "Tamil",
                translation: "மேரா காவ்ன் பயனர் ஆனார்",
            },
            "14": {
                language: "Assamese",
                translation: "হৈ পৰিল মেৰা গাওঁ ব্যৱহাৰকাৰী",
            },
            21: {
                language: "Khasi",
                translation: "Long nongpyndonkam jong ka Mera Gaon",
            },
            20: {
                language: "Mizo",
                translation: "Mera Gaon User a ni ta",
            },
            19: {
                language: "Manipuri",
                translation: "Mera Gaon sijinaba oiyu",
            },
            18: {
                language: "Nagamese",
                translation: "mera gaon user hoi bhi ",
            },
        },
        default: "Became Mera Gaon User",
    },
    claimReward: {
        languages: {
            "1": {
                language: "English",
                translation: "Claim Reward",
            },
            "2": {
                language: "Hindi",
                translation: "क्लेम रिवार्ड ",
            },
            "3": {
                language: "Marathi",
                translation: "रिवॉर्डचा दावा करा",
            },
            "4": {
                language: "Kannada",
                translation: "ಪ್ರತಿಫಲವನ್ನು ಕ್ಲೈಮ್ ಮಾಡಿ",
            },
            "5": {
                language: "Telugu",
                translation: "రివార్డ్ క్లెయిమ్ చేయండి",
            },
            "6": {
                language: "Bengali",
                translation: "পুরস্কার দাবি",
            },
            "7": {
                language: "Punjabi",
                translation: "ਇਨਾਮ ਦਾ ਦਾਅਵਾ ਕਰੋ",
            },
            8: {
                language: "Gujarati",
                translation: "પુરસ્કારનો દાવો કરો",
            },
            "10": {
                language: "Malayalam",
                translation: "പ്രതിഫലം സ്വീകരിക്കുക",
            },
            "17": {
                language: "Tamil",
                translation: "வெகுமதியைக் கோருங்கள்",
            },
            "14": {
                language: "Assamese",
                translation: "পুৰস্কাৰ দাবী কৰক",
            },
            21: {
                language: "Khasi",
                translation: "Kam ia ka khusnam",
            },
            20: {
                language: "Mizo",
                translation: "Lawmman dawng rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Reward claim tou",
            },
            18: {
                language: "Nagamese",
                translation: "enam lobhi ",
            },
        },
        default: "Claim Reward",
    },
    day: {
        languages: {
            "1": {
                language: "English",
                translation: "Day",
            },
            "2": {
                language: "Hindi",
                translation: "दिन",
            },
            "3": {
                language: "Marathi",
                translation: "Day",
            },
            "4": {
                language: "Kannada",
                translation: "Day",
            },
            "5": {
                language: "Telugu",
                translation: "Day",
            },
            "6": {
                language: "Bengali",
                translation: "দিন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਦਿਨ",
            },
            8: {
                language: "Gujarati",
                translation: "Day",
            },
            "10": {
                language: "Malayalam",
                translation: "Day",
            },
            "17": {
                language: "Tamil",
                translation: "Day",
            },
            "14": {
                language: "Assamese",
                translation: "দিন",
            },
            21: {
                language: "Khasi",
                translation: "Sngi",
            },
            20: {
                language: "Mizo",
                translation: "Ni",
            },
            19: {
                language: "Manipuri",
                translation: "Day",
            },
            18: {
                language: "Nagamese",
                translation: "din ",
            },
        },
        default: "Day",
    },
    loadingText: {
        languages: {
            "1": {
                language: "English",
                translation: "Loading...Kindly wait.Do not press any button or close the application",
            },
            "2": {
                language: "Hindi",
                translation: "लोड हो रहा है... कृपया प्रतीक्षा करें। कोई भी बटन ना दबाएं या एप्लिकेशन को बंद ना करें",
            },
            "3": {
                language: "Marathi",
                translation: "लोड होत आहे...कृपया प्रतीक्षा करा. कोणतेही बटण दाबू नका किंवा अनुप्रयोग बंद करू नका",
            },
            "4": {
                language: "Kannada",
                translation:
                    "ಲೋಡ್ ಆಗುತ್ತಿದೆ...ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ.  ಯಾವುದೇ ಬಟನ್ ಅನ್ನು ಒತ್ತಬೇಡಿ ಅಥವಾ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಮುಚ್ಚಬೇಡಿ",
            },
            "5": {
                language: "Telugu",
                translation: "లోడ్ అవుతోంది...దయచేసి వేచి ఉండండి. ఏ బటన్‌ను నొక్కవద్దు లేదా అప్లికేషన్‌ను మూసివేయవద్దు",
            },
            "6": {
                language: "Bengali",
                translation:
                    "প্রেরণ করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন. কোনো বোতাম টিপুন না বা অ্যাপ্লিকেশনটি বন্ধ করবেন না",
            },
            "7": {
                language: "Punjabi",
                translation: "ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ...ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ। ਕੋਈ ਵੀ ਬਟਨ ਨਾ ਦਬਾਓ ਜਾਂ ਐਪਲੀਕੇਸ਼ਨ ਨੂੰ ਬੰਦ ਨਾ ਕਰੋ",
            },
            8: {
                language: "Gujarati",
                translation:
                    "લોડ કરી રહ્યું છે...કૃપા કરીને રાહ જુઓ.કોઈપણ બટન દબાવશો નહીં અથવા એપ્લિકેશન બંધ કરશો નહીં",
            },
            "10": {
                language: "Malayalam",
                translation:
                    "ലോഡുചെയ്യുന്നു...ദയവായി കാത്തിരിക്കുക. ഒരു ബട്ടണും അമർത്തുകയോ ആപ്ലിക്കേഷൻ അടയ്ക്കുകയോ ചെയ്യരുത്",
            },
            "17": {
                language: "Tamil",
                translation:
                    "ஏற்றுகிறது...தயவுசெய்து காத்திருக்கவும்.எந்தவொரு பொத்தானையும் அழுத்த வேண்டாம் அல்லது பயன்பாட்டை மூட வேண்டாம்",
            },
            "14": {
                language: "Assamese",
                translation: "লোড হৈ আছে...দয়া কৰি অপেক্ষা কৰক।কোনো বুটাম টিপিব বা এপ্লিকেচনটো বন্ধ নকৰিব",
            },
            21: {
                language: "Khasi",
                translation: "Loading..Sngewbha ban ap..Wat nym nion uno uno u button lane khang ia ka application.",
            },
            20: {
                language: "Mizo",
                translation: "A in load mek .Button engmah hmet lo la, application kha khar suh",
            },
            19: {
                language: "Manipuri",
                translation:
                    " Load touri...Chanbiduna ngaibiyu. Button amata nambinu ntraga application asi close toubinu",
            },
            18: {
                language: "Nagamese",
                translation:
                    "load kory ase... morom bara rukey bhi eku button najoi bhi ya application bun nakory bhi ",
            },
        },
        default: "Loading...Kindly wait.Do not press any button or close the application",
    },
    errorText: {
        languages: {
            "1": {
                language: "English",
                translation: "Oops something went wrong",
            },
            "2": {
                language: "Hindi",
                translation: "ओह! कुछ गलत हो गया है",
            },
            "3": {
                language: "Marathi",
                translation: "अरेरे काहीतरी चूक झाली",
            },
            "4": {
                language: "Kannada",
                translation: "ಅಯ್ಯೋ!  ಏನೋ ತಪ್ಪಾಗಿದೆ",
            },
            "5": {
                language: "Telugu",
                translation: "అయ్యో! ఎదో తప్పు జరిగింది",
            },
            "6": {
                language: "Bengali",
                translation: "কিছু ভুল হয়েছে",
            },
            "7": {
                language: "Punjabi",
                translation: "ਓਹੋ ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ",
            },
            8: {
                language: "Gujarati",
                translation: "અરેરે કંઈક ખોટું થયું",
            },
            "10": {
                language: "Malayalam",
                translation: "ശ്ശോ! എന്തോ തെറ്റായി സംഭവിച്ചു",
            },
            "17": {
                language: "Tamil",
                translation: "அச்சச்சோ! ஏதோ தவறு நடந்துவிட்டது",
            },
            "14": {
                language: "Assamese",
                translation: "উফ কিবা এটা ভুল হ’ল৷",
            },
            21: {
                language: "Khasi",
                translation: "Oops don jingthut",
            },
            20: {
                language: "Mizo",
                translation: "Oops thil engemaw a kal sual ta",
            },
            19: {
                language: "Manipuri",
                translation: "Oops aranba khara leire",
            },
            18: {
                language: "Nagamese",
                translation: "oops kiba ekta hoi shia ",
            },
        },
        default: "Oops something went wrong",
    },
    retry: {
        languages: {
            "1": {
                language: "English",
                translation: "Retry",
            },
            "2": {
                language: "Hindi",
                translation: "दोबारा प्रयास करें",
            },
            "3": {
                language: "Marathi",
                translation: "पुन्हा प्रयत्न करा",
            },
            "4": {
                language: "Kannada",
                translation: "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
            },
            "5": {
                language: "Telugu",
                translation: "మళ్లీ ప్రయత్నించండి",
            },
            "6": {
                language: "Bengali",
                translation: "পুনরায় চেষ্টা করা",
            },
            "7": {
                language: "Punjabi",
                translation: "ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
            },
            8: {
                language: "Gujarati",
                translation: "ફરી પ્રયાસ કરો",
            },
            "10": {
                language: "Malayalam",
                translation: "വീണ്ടും ശ്രമിക്കുക",
            },
            "17": {
                language: "Tamil",
                translation: "மீண்டும் முயற்சிக்கவும்",
            },
            "14": {
                language: "Assamese",
                translation: "পুনৰ চেষ্টা কৰক",
            },
            21: {
                language: "Khasi",
                translation: "Pyrshang biang",
            },
            20: {
                language: "Mizo",
                translation: "Ti leh rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Amuk toubiyu",
            },
            18: {
                language: "Nagamese",
                translation: "aru kory bhi ",
            },
        },
        default: "Retry",
    },
    joinMeraGaon: {
        languages: {
            "1": {
                language: "English",
                translation: "You have joined Mera Gaon on {{Date}}",
            },
            "2": {
                language: "Hindi",
                translation: "आप {{Date}} को मेरा गांव में शामिल हो गए हैं",
            },
            "3": {
                language: "Marathi",
                translation: "तूम्ही {{Date}} ला मेरा गाव वर सामील झाला आहात ",
            },
            "4": {
                language: "Kannada",
                translation: "ನೀವು {{Date}} ರಂದು ಮೇರಾ ಗಾಂವ್‌ಗೆ ಸೇರಿದ್ದೀರಿ",
            },
            "5": {
                language: "Telugu",
                translation: "మీరు {{ Date }}న మేరా గావ్‌లో చేరారు",
            },
            "6": {
                language: "Bengali",
                translation: "আপনি {{Date}} তারিখে মেরা গাঁওতে যোগ দিয়েছেন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ {{Date}} ਨੂੰ ਮੇਰਾ ਗਾਓਂ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਏ ਹੋ",
            },
            8: {
                language: "Gujarati",
                translation: "તમે {{Date}} ના રોજ મેરા ગાંવમાં જોડાયા છો",
            },
            "10": {
                language: "Malayalam",
                translation: "നിങ്ങൾ {{Date}}-ന് മേരാ ഗാവിൽ ചേർന്നു",
            },
            "17": {
                language: "Tamil",
                translation: "நீங்கள் {{Date}} அன்று மேரா காவ்னில் சேர்ந்துள்ளீர்கள்",
            },
            "14": {
                language: "Assamese",
                translation: " আপুনি {{Date}} তাৰিখে মেৰা গাওঁত যোগদান কৰিছে।",
            },
            21: {
                language: "Khasi",
                translation: "Phi lah join ia ka Mera Gaon ha {{Date}}",
            },
            20: {
                language: "Mizo",
                translation: "Mera Gaon hi {{Date}} khan i zawm tawh a ni.",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna {{Date}} asida Mera Gaon asi yaore",
            },
            18: {
                language: "Nagamese",
                translation: "apne mera gaon join kory shai {{Date}} deh ",
            },
        },
        default: "You have joined Mera Gaon on {{Date}}",
    },
    // missedEvent: {
    //     languages: {
    //         "1": {
    //             language: "English",
    //             translation: "You did not complete this event on {{Date}}",
    //         },
    //         "2": {
    //             language: "Hindi",
    //             translation: "आपने {{Date}} को इस इवेंट को पूरा नहीं किया ",
    //         },
    //         "3": {
    //             language: "Marathi",
    //             translation: "तुम्ही हे इवेंट {{Date}} ला पूर्ण केले नाही",
    //         },
    //         "4": {
    //             language: "Kannada",
    //             translation: "ನೀವು ಈ ಕಾರ್ಯವನ್ನು {{Date}} ರಂದು ಪೂರ್ಣಗೊಳಿಸಿಲ್ಲ",
    //         },
    //         "5": {
    //             language: "Telugu",
    //             translation: "మీరు ఈ పనిని {{ Date}}న పూర్తి చేయలేదు",
    //         },
    //         "6": {
    //             language: "Bengali",
    //             translation: "আপনি এই কাজটি {{Date}} তারিখে সম্পূর্ণ করেননি",
    //         },
    //         "7": {
    //             language: "Punjabi",
    //             translation: "ਤੁਸੀਂ ਇਹ ਕੰਮ {{Date}} ਨੂੰ ਪੂਰਾ ਨਹੀਂ ਕੀਤਾ",
    //         },
    //         8: {
    //             language: "Gujarati",
    //             translation: "તમે આ કાર્ય {{Date}} ના રોજ પૂર્ણ કર્યું નથી",
    //         },
    //         "10": {
    //             language: "Malayalam",
    //             translation: "നിങ്ങൾ ഈ ടാസ്ക് {{Date}}-ന് പൂർത്തിയാക്കിയില്ല",
    //         },
    //         "17": {
    //             language: "Tamil",
    //             translation: "நீங்கள் இந்தப் பணியை {{Date}} அன்று முடிக்கவில்லை",
    //         },
    //         "14": {
    //             language: "Assamese",
    //             translation: "আপুনি এই কামটো {{Date}} ত সম্পূৰ্ণ কৰা নাই।",
    //         },
    //         21: {
    //             language: "Khasi",
    //             translation: "Phi khlem pyndep ia kane ka kam ha {{Date}}",
    //         },
    //         20: {
    //             language: "Mizo",
    //             translation: "He hna hi {{Date}} khan i zo lo.",
    //         },
    //         19: {
    //             language: "Manipuri",
    //             translation: "Adomna masigi task asi{{Date}} sida loisankhide",
    //         },
    //         18: {
    //             language: "Nagamese",
    //             translation: "apne etu {{Date}} deh task kotom howa nai ",
    //         },
    //     },
    //     default: "You did not complete this task on {{Date}}",
    // },
    unlock: {
        languages: {
            "1": {
                language: "English",
                translation: "Unlock on",
            },
            "2": {
                language: "Hindi",
                translation: "अनलॉक करें",
            },
            "3": {
                language: "Marathi",
                translation: "अनलॉक चालू करा",
            },
            "4": {
                language: "Kannada",
                translation: "ಅನ್ಲಾಕ್ ಅನ್ನು ಆನ್ ಮಾಡಿ",
            },
            "5": {
                language: "Telugu",
                translation: "అన్‌లాక్ ఆన్ చేయండి",
            },
            "6": {
                language: "Bengali",
                translation: "আনলক চালু করুন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਅਣਲਾਕ ਚਾਲੂ ਕਰੋ",
            },
            8: {
                language: "Gujarati",
                translation: "અનલૉક ચાલુ કરો",
            },
            "10": {
                language: "Malayalam",
                translation: "അൺലോക്ക് ചെയ്യുക",
            },
            "17": {
                language: "Tamil",
                translation: "திறக்கவும்",
            },
            "14": {
                language: "Assamese",
                translation: "আনলক অন কৰক",
            },
            21: {
                language: "Khasi",
                translation: "Unlock ha",
            },
            20: {
                language: "Mizo",
                translation: "Unlock ni",
            },
            19: {
                language: "Manipuri",
                translation: "Unlock hangle",
            },
            18: {
                language: "Nagamese",
                translation: "etu deh unlock ",
            },
        },
        default: "Unlock on",
    },
    bottomText: {
        languages: {
            "1": {
                language: "English",
                translation: "Congratulations on making it so far! ",
            },
            "2": {
                language: "Hindi",
                translation: "यहाँ तक पहुचने के लिए आपको मुबारक!",
            },
            "3": {
                language: "Marathi",
                translation: "आतापर्यंत केल्याबद्दल अभिनंदन!",
            },
            "4": {
                language: "Kannada",
                translation: "ಇಲ್ಲಿಯವರೆಗೆ ಮಾಡಿದಕ್ಕಾಗಿ ಅಭಿನಂದನೆಗಳು!",
            },
            "5": {
                language: "Telugu",
                translation: "ఇంతవరకు చేసినందుకు అభినందనలు!",
            },
            "6": {
                language: "Bengali",
                translation: "এটা এ পর্যন্ত তৈরি করার জন্য অভিনন্দন!",
            },
            "7": {
                language: "Punjabi",
                translation: "ਇਸ ਨੂੰ ਹੁਣ ਤੱਕ ਬਣਾਉਣ ਲਈ ਵਧਾਈਆਂ!",
            },
            8: {
                language: "Gujarati",
                translation: "તેને અત્યાર સુધી બનાવવા બદલ અભિનંદન!",
            },
            "10": {
                language: "Malayalam",
                translation: "ഇത് ഇതുവരെ ഉണ്ടാക്കിയതിന് അഭിനന്ദനങ്ങൾ!",
            },
            "17": {
                language: "Tamil",
                translation: "இதுவரை செய்ததற்கு வாழ்த்துகள்!",
            },
            "14": {
                language: "Assamese",
                translation: "এতিয়ালৈকে পোৱাৰ বাবে অভিনন্দন!",
            },
            21: {
                language: "Khasi",
                translation: "Congratulations ba phi lah poi shaduh katne! ",
            },
            20: {
                language: "Mizo",
                translation: "Tun thlenga kan thlen theih avangin lawmthu kan sawi e!",
            },
            19: {
                language: "Manipuri",
                translation: "Congratulations masi youna yourakpagi!",
            },
            18: {
                language: "Nagamese",
                translation: "Congratulations eman tak aha karney!",
            },
        },
        default: "Congratulations on making it so far! ",
    },
    bottomText2: {
        languages: {
            "1": {
                language: "English",
                translation:
                    "Explore all our other features by clicking the menu below, or click on the Explore Milestones button below to win even more rewards!",
            },
            "2": {
                language: "Hindi",
                translation:
                    "नीचे दिए गए मेन्यू पर क्लिक करके हमारी सभी अन्य सुविधाओं को एक्सप्लोर करें, या और भी अधिक पुरस्कार जीतने के लिए नीचे दिए गए मील के पत्थर एक्सप्लोर करें बटन पर क्लिक करें!",
            },
            "3": {
                language: "Marathi",
                translation:
                    "खालील मेनूवर क्लिक करून आमची इतर सर्व वैशिष्ट्ये एक्सप्लोर करा किंवा आणखी बक्षिसे जिंकण्यासाठी खालील एक्सप्लोर माईलस्टोन्स बटणावर क्लिक करा!",
            },
            "4": {
                language: "Kannada",
                translation:
                    "ಕೆಳಗಿನ ಮೆನುವನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ನಮ್ಮ ಎಲ್ಲಾ ಇತರ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಎಕ್ಸ್‌ಪ್ಲೋರ್ ಮಾಡಿ ಅಥವಾ ಇನ್ನಷ್ಟು ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲಲು ಕೆಳಗಿನ ಮೈಲಿಗಲ್ಲುಗಳನ್ನು ಅನ್ವೇಷಿಸಿ ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ!",
            },
            "5": {
                language: "Telugu",
                translation:
                    "దిగువ మెనుని క్లిక్ చేయడం ద్వారా మా అన్ని ఇతర లక్షణాలను అన్వేషించండి లేదా మరిన్ని రివార్డ్‌లను గెలుచుకోవడానికి దిగువన ఉన్న మైల్‌స్టోన్‌లను అన్వేషించండి బటన్‌పై క్లిక్ చేయండి!",
            },
            "6": {
                language: "Bengali",
                translation:
                    "নীচের মেনুতে ক্লিক করে আমাদের অন্যান্য সমস্ত বৈশিষ্ট্যগুলি অন্বেষণ করুন, অথবা আরও বেশি পুরস্কার জিততে নীচের অন্বেষণ মাইলস্টোন বোতামে ক্লিক করুন!",
            },
            "7": {
                language: "Punjabi",
                translation:
                    "ਹੇਠਾਂ ਦਿੱਤੇ ਮੀਨੂ 'ਤੇ ਕਲਿੱਕ ਕਰਕੇ ਸਾਡੀਆਂ ਸਾਰੀਆਂ ਹੋਰ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ, ਜਾਂ ਹੋਰ ਇਨਾਮ ਜਿੱਤਣ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਮੀਲ ਪੱਥਰਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ ਬਟਨ 'ਤੇ ਕਲਿੱਕ ਕਰੋ!",
            },
            8: {
                language: "Gujarati",
                translation:
                    "નીચેના મેનૂ પર ક્લિક કરીને અમારી અન્ય તમામ સુવિધાઓનું અન્વેષણ કરો અથવા હજી વધુ પુરસ્કારો જીતવા માટે નીચેના માઇલસ્ટોન્સનું અન્વેષણ કરો બટન પર ક્લિક કરો!",
            },
            "10": {
                language: "Malayalam",
                translation:
                    "ചുവടെയുള്ള മെനുവിൽ ക്ലിക്കുചെയ്‌ത് ഞങ്ങളുടെ മറ്റ് എല്ലാ സവിശേഷതകളും പര്യവേക്ഷണം ചെയ്യുക, അല്ലെങ്കിൽ കൂടുതൽ റിവാർഡുകൾ നേടുന്നതിന് ചുവടെയുള്ള നാഴികക്കല്ലുകൾ പര്യവേക്ഷണം ചെയ്യുക ബട്ടണിൽ ക്ലിക്കുചെയ്യുക!",
            },
            "17": {
                language: "Tamil",
                translation:
                    "கீழேயுள்ள மெனுவைக் கிளிக் செய்வதன் மூலம் எங்களின் அனைத்து அம்சங்களையும் ஆராயுங்கள் அல்லது இன்னும் அதிகமான வெகுமதிகளைப் பெற கீழே உள்ள மைல்கற்களை ஆராயுங்கள் பொத்தானைக் கிளிக் செய்யவும்!",
            },
            "14": {
                language: "Assamese",
                translation:
                    "তলৰ মেনুত ক্লিক কৰি আমাৰ অন্য সকলো বৈশিষ্ট্য অন্বেষণ কৰক, বা আৰু অধিক পুৰস্কাৰ লাভ কৰিবলৈ তলৰ মাইলৰ খুঁটিসমূহ অন্বেষণ কৰক বুটামত ক্লিক কৰক!",
            },
            21: {
                language: "Khasi",
                translation:
                    "Shem shuh ki wei pat ki features jong ngi da ka ba nion h ka menu hapoh,lane nion ha Explore Milestones hapoh ban jop shuh ki khusnam!",
            },
            20: {
                language: "Mizo",
                translation:
                    "A hnuaia menu hi click la, kan feature dang zawng zawng hi chhuichhuak la, a hnuaia Milestones te chu chhui chhuak rawh button kha click la, lawmman tam zawk dawng rawh!",
            },
            19: {
                language: "Manipuri",
                translation:
                    "Atoppa features kaya asi yengbiyu makhagi menu asida nambiduna, ntraga Explore Milestones button asida nambiyu ahenba rewards kaya phangnaba!",
            },
            18: {
                language: "Nagamese",
                translation:
                    "nechi menu deh dabai kina moi kan laka kiman features ase sai bhi, nahoi leh Explore Milestones laka button dabai kina aru enam kan cheti bhi! ",
            },
        },
        default:
            "Explore all our other features by clicking the menu below, or click on the Explore Milestones button below to win even more rewards!",
    },
    exploreMilestone: {
        languages: {
            "1": {
                language: "English",
                translation: "Explore Milestones",
            },
            "2": {
                language: "Hindi",
                translation: "माइलस्टोन एक्सप्लोर करें",
            },
            "3": {
                language: "Marathi",
                translation: "माइलस्टोन एक्सप्लोर करा",
            },
            "4": {
                language: "Kannada",
                translation: "ಮೈಲಿಗಲ್ಲು ಅನ್ವೇಷಿಸಿ",
            },
            "5": {
                language: "Telugu",
                translation: "మైలురాయిని అన్వేషించండి",
            },
            "6": {
                language: "Bengali",
                translation: "মাইলস্টোন এক্সপ্লোর করুন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਮੀਲ ਪੱਥਰ ਦੀ ਪੜਚੋਲ ਕਰੋ",
            },
            8: {
                language: "Gujarati",
                translation: "માઇલસ્ટોનનું અન્વેષણ કરો",
            },
            "10": {
                language: "Malayalam",
                translation: "നാഴികക്കല്ല് പര്യവേക്ഷണം ചെയ്യുക",
            },
            "17": {
                language: "Tamil",
                translation: "மைல்ஸ்டோனை ஆராயுங்கள்",
            },
            "14": {
                language: "Assamese",
                translation: "মাইলৰ খুঁটি অন্বেষণ কৰক",
            },
            21: {
                language: "Khasi",
                translation: "Shem ki Mawmer",
            },
            20: {
                language: "Mizo",
                translation: "Milestones hi chhui chhuak rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Milestones yengbiyu",
            },
            18: {
                language: "Nagamese",
                translation: "milestones besary bhi ",
            },
        },
        default: "Explore Milestones",
    },
    gotoReward: {
        languages: {
            "1": {
                language: "English",
                translation: "Goto Rewards",
            },
            "2": {
                language: "Hindi",
                translation: "इनाम पर जाएं",
            },
            "3": {
                language: "Marathi",
                translation: "बक्षीस मिळवा",
            },
            "4": {
                language: "Kannada",
                translation: "ಪ್ರತಿಫಲಕ್ಕೆ ಹೋಗಿ",
            },
            "5": {
                language: "Telugu",
                translation: "బహుమతిని పొందండి",
            },
            "6": {
                language: "Bengali",
                translation: "পুরস্কার যান",
            },
            "7": {
                language: "Punjabi",
                translation: "ਇਨਾਮ 'ਤੇ ਜਾਓ",
            },
            8: {
                language: "Gujarati",
                translation: "પુરસ્કાર પર જાઓ",
            },
            "10": {
                language: "Malayalam",
                translation: "പ്രതിഫലം നേടുക",
            },
            "17": {
                language: "Tamil",
                translation: "வெகுமதி கிடைக்கும்",
            },
            "14": {
                language: "Assamese",
                translation: "পুৰস্কাৰলৈ যাওক",
            },
            21: {
                language: "Khasi",
                translation: "Leit sha ka khusnam",
            },
            20: {
                language: "Mizo",
                translation: "Lawmman dawnna ah kal rawh",
            },
            19: {
                language: "Manipuri",
                translation: "Rewards asida lakpiyu",
            },
            18: {
                language: "Nagamese",
                translation: "enam deh jabi",
            },
        },
        default: "Goto Rewards",
    },
    pendingApproval: {
        languages: {
            "1": {
                language: "English",
                translation: "You have completed this task on {{Date}} awaiting approval from HeadQuarter",
            },
            "2": {
                language: "Hindi",
                translation: "आपने {{Date}} को इवेंट पूरा कर लिया है मुख्यालय से स्वीकृति का इंतजार",
            },
            "3": {
                language: "Marathi",
                translation:
                    " तुम्ही हे इवेंट {{Date}} रोजी पूर्ण केले आहे आणि हेडक्वार्टरच्या मंजुरीच्या प्रतीक्षेत आहे",
            },
            "4": {
                language: "Kannada",
                translation:
                    "ನೀವು ಹೆಡ್‌ಕ್ವಾರ್ಟರ್‌ನಿಂದ ಅನುಮೋದನೆಗಾಗಿ ಕಾಯುತ್ತಿರುವ ಈ ಕಾರ್ಯವನ್ನು {{Date}} ರಂದು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ",
            },
            "5": {
                language: "Telugu",
                translation: "మీరు హెడ్‌క్వార్టర్ నుండి ఆమోదం కోసం వేచి ఉన్న ఈ పనిని{{ Date }} పూర్తి చేసారు",
            },
            "6": {
                language: "Bengali",
                translation: "আপনি হেডকোয়ার্টার থেকে অনুমোদনের অপেক্ষায় এই কাজটি {{Date}} তারিখে সম্পন্ন করেছেন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ ਹੈੱਡਕੁਆਰਟਰ ਤੋਂ ਮਨਜ਼ੂਰੀ ਦੀ ਉਡੀਕ ਵਿੱਚ ਇਹ ਕੰਮ {{Date}} ਨੂੰ ਪੂਰਾ ਕਰ ਲਿਆ ਹੈ",
            },
            8: {
                language: "Gujarati",
                translation: "તમે હેડક્વાર્ટરની મંજૂરીની રાહ જોઈને {{Date}} ના રોજ આ કાર્ય પૂર્ણ કર્યું છે",
            },
            "10": {
                language: "Malayalam",
                translation: "ഹെഡ്ക്വാർട്ടറിൽ നിന്നുള്ള അംഗീകാരത്തിനായി നിങ്ങൾ {{Date}} ഈ ടാസ്ക് പൂർത്തിയാക്കി",
            },
            "17": {
                language: "Tamil",
                translation:
                    "தலைமையகத்தின் ஒப்புதலுக்காகக் காத்திருக்கும் இந்தப் பணியை {{Date}} அன்று முடித்துவிட்டீர்கள்",
            },
            "14": {
                language: "Assamese",
                translation: " আপুনি এই কামটো {{Date}} ত মুখ্য কাৰ্যালয়ৰ পৰা অনুমোদনৰ অপেক্ষাত সম্পূৰ্ণ কৰিছে",
            },
            21: {
                language: "Khasi",
                translation: "Phi lah pyndep kane ka kam ha {{Date}} dang ap ban ioh jingbit na ka HeadQuarter",
            },
            20: {
                language: "Mizo",
                translation: "He hna hi HeadQuarter atanga phalna nghah laiin {{Date}} ah i zo tawh a ni",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna event asi {{Date}} loisalle HeadQuarter dagi ayaba pirakpa ngairi",
            },
            18: {
                language: "Nagamese",
                translation: "apne etu event {{Date }} deh kotom hois shai etya headquarter bara approval ruki ase ",
            },
        },
        default: "You have completed this event on {{Date}} awaiting approval from HeadQuarter",
    },
    completeEventText: {
        languages: {
            "1": {
                language: "English",
                translation: "You have completed this event on {{Date}}",
            },
            "2": {
                language: "Hindi",
                translation: "आपने {{Date}} को इवेंट पूरा कर लिया है",
            },
            "3": {
                language: "Marathi",
                translation: "तुम्ही हे इवेंट {{Date}} रोजी पूर्ण केले आहे",
            },
            "4": {
                language: "Kannada",
                translation: "ನೀವು ಈ ಕಾರ್ಯವನ್ನು {{Date}} ರಂದು ಪೂರ್ಣಗೊಳಿಸಿರುವಿರಿ",
            },
            "5": {
                language: "Telugu",
                translation: "మీరు ఈ పనిని {{ Date }}న పూర్తి చేసారు",
            },
            "6": {
                language: "Bengali",
                translation: "আপনি এই কাজটি {{Date}} তারিখে সম্পন্ন করেছেন",
            },
            "7": {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ ਇਹ ਕੰਮ {{Date}} ਨੂੰ ਪੂਰਾ ਕਰ ਲਿਆ ਹੈ",
            },
            8: {
                language: "Gujarati",
                translation: "તમે આ કાર્ય {{Date}} ના રોજ પૂર્ણ કર્યું છે",
            },
            "10": {
                language: "Malayalam",
                translation: "നിങ്ങൾ ഈ ടാസ്ക് {{Date}}-ന് പൂർത്തിയാക്കി",
            },
            "17": {
                language: "Tamil",
                translation: "இந்தப் பணியை {{Date}} அன்று முடித்துவிட்டீர்கள்",
            },
            "14": {
                language: "Assamese",
                translation: "আপুনি এই কামটো {{Date}} ত সম্পূৰ্ণ কৰিছে।",
            },
            21: {
                language: "Khasi",
                translation: "Phi lah pyndep ia kane ka kam ha {{Date}}",
            },
            20: {
                language: "Mizo",
                translation: "He hna hi {{Date}} ah i zo tawh a ni.",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna masigi task asi {{Date}} sida loisalle",
            },
            18: {
                language: "Nagamese",
                translation: "apne etu task {{Date}} deh kotom hoishai ",
            },
        },
        default: "You have completed this event on {{Date}}",
    },
    missedEvent: {
        languages: {
            "1": {
                language: "English",
                translation: "You did not complete this event.",
            },
            "2": {
                language: "Hindi",
                translation: "आपने इस इवेंट को पूरा नहीं किया",
            },
            "3": {
                language: "Marathi",
                translation: "आपण हे इवेंट पूर्ण केले नाही",
            },
            "4": {
                language: "Kannada",
                translation: "ನೀವು ಈ ಕಾರ್ಯವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿಲ್ಲ",
            },
            "5": {
                language: "Telugu",
                translation: "మీరు ఈ పనిని పూర్తి చేయలేదు",
            },
            "6": {
                language: "Bengali",
                translation: "আপনি এই কাজটি সম্পূর্ণ করেননি",
            },
            "7": {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ ਇਸ ਕਾਰਜ ਨੂੰ ਪੂਰਾ ਨਹੀਂ ਕੀਤਾ",
            },
            8: {
                language: "Gujarati",
                translation: "તમે આ કાર્ય પર પૂર્ણ કર્યું નથી",
            },
            "10": {
                language: "Malayalam",
                translation: "നിങ്ങൾ ഈ ടാസ്ക് പൂർത്തിയാക്കിയില്ല",
            },
            "17": {
                language: "Tamil",
                translation: "நீங்கள் இந்தப் பணியை முடிக்கவில்லை",
            },
            "14": {
                language: "Assamese",
                translation: "আপুনি এই কামটো সম্পূৰ্ণ কৰা নাছিল",
            },
            21: {
                language: "Khasi",
                translation: "Phi khlem pyndep ia kane ka kam ha ",
            },
            20: {
                language: "Mizo",
                translation: "He hna hi i zo lo hemi ni hian",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna masigi task asi loisankhidaba",
            },
            18: {
                language: "Nagamese",
                translation: "apne etu deh tasj kotom howa nai ",
            },
        },
        default: "You did not complete this task on",
    },
    winUptoReward: {
        languages: {
            1: {
                language: "English",
                translation: "Win upto 500 MG Sikka!",
            },
            2: {
                language: "hindi",
                translation: "500 एमजी सिक्का तक जीतें!",
            },
            3: {
                language: "Marathi",
                translation: "500 MG Sikka पर्यंत जिंका!",
            },
            7: {
                language: "Punjabi",
                translation: "500 MG ਸਿੱਕਾ ਤੱਕ ਜਿੱਤੋ!",
            },
            6: {
                language: "Bengali",
                translation: "500 এমজি সিক্কা পর্যন্ত জিতুন!",
            },
            14: {
                language: "Assamese",
                translation: "৫০০ এম জি ছিক্কালৈকে জয়ী হ’ব!",
            },
            18: {
                language: "Nagamese",
                translation: "jeti bhi 500 MG Sikka tak!",
            },
            21: {
                language: "Khasi",
                translation: "Jop haduh 500 MG Sikka!",
            },
            20: {
                language: "Mizo",
                translation: "500 MG Sikka thleng dawng rawh!",
            },
            19: {
                language: "Manipuri",
                translation: "Ngambiyu 500 MG Sikka faoba!",
            },
            8: {
                language: "Gujarati",
                translation: "500 MG સિક્કા સુધી જીતો!",
            },
            17: {
                language: "Tamil",
                translation: "500 MG சிக்கா வரை வெல்லுங்கள்!",
            },
            5: {
                language: "Telugu",
                translation: "500 MG సిక్కా వరకు గెలవండి!",
            },
            10: {
                language: "Malayalam",
                translation: "500 എംജി സിക്ക വരെ നേടൂ!",
            },
            4: {
                language: "Kannada",
                translation: "500 ಎಂಜಿ ಸಿಕ್ಕಾ ವರೆಗೆ ಗೆಲ್ಲಿರಿ!",
            },
        },
        default: "Win upto 500 MG Sikka!",
    },
    completedEventNumber: {
        languages: {
            1: {
                language: "English",
                translation: "Completed events:",
            },
            2: {
                language: "hindi",
                translation: "पूर्ण घटनाएँ:",
            },
            3: {
                language: "Marathi",
                translation: "पूर्ण झालेले कार्यक्रम:",
            },
            7: {
                language: "Punjabi",
                translation: "ਸੰਪੂਰਨ ਘਟਨਾਵਾਂ:",
            },
            6: {
                language: "Bengali",
                translation: " ঘটনার সমাপ্তি :",
            },
            14: {
                language: "Assamese",
                translation: "ಪೂರ್ಣಗೊಂಡ ಘಟನೆಗಳು:",
            },
            18: {
                language: "Nagamese",
                translation: "kotom howa events:",
            },
            21: {
                language: "Khasi",
                translation: "Lah ioh MG Sikka:",
            },
            20: {
                language: "Mizo",
                translation: "Event zawh tawh ho:",
            },
            19: {
                language: "Manipuri",
                translation: "Loikhiba Event:",
            },
            8: {
                language: "Gujarati",
                translation: " પૂર્ણ થયેલ ઘટનાઓ:",
            },
            17: {
                language: "Tamil",
                translation: "முடிந்த நிகழ்வுகள்:",
            },
            5: {
                language: "Telugu",
                translation: "పూర్తయిన ఈవెంట్‌లు:",
            },
            10: {
                language: "Malayalam",
                translation: "പൂർത്തിയാക്കിയ ഇവന്റുകൾ:",
            },
            4: {
                language: "Kannada",
                translation: "ಪೂರ್ಣಗೊಂಡ ಘಟನೆಗಳು:",
            },
        },
        default: "Completed events:",
    },
    earnedMgSikkaNumber: {
        languages: {
            1: {
                language: "English",
                translation: "Earned MG Sikka:",
            },
            2: {
                language: "hindi",
                translation: "अर्जित एमजी सिक्का:",
            },
            3: {
                language: "Marathi",
                translation: "एमजी सिक्का मिळवले:",
            },
            7: {
                language: "Punjabi",
                translation: "ਐਮਜੀ ਸਿੱਕਾ ਦੀ ਕਮਾਈ:",
            },
            6: {
                language: "Bengali",
                translation: "এমজি সিক্কা অর্জন করেছেন:",
            },
            14: {
                language: "Assamese",
                translation: "এম জি ছিক্কা উপাৰ্জন:",
            },
            18: {
                language: "Nagamese",
                translation: "kama bhi MG Sikka:",
            },
            21: {
                language: "Khasi",
                translation: "Lah ioh MG Sikka:",
            },
            20: {
                language: "Mizo",
                translation: "MG Sikka hlawh tawh zat:",
            },
            19: {
                language: "Manipuri",
                translation: "Tanbiyu MG Sikka:",
            },
            8: {
                language: "Gujarati",
                translation: " એમજી સિક્કાની કમાણી:",
            },
            17: {
                language: "Tamil",
                translation: "சம்பாதித்த எம்ஜி சிக்கா:",
            },
            5: {
                language: "Telugu",
                translation: "సంపాదించిన MG సిక్కా:",
            },
            10: {
                language: "Malayalam",
                translation: "എംജി സിക്ക നേടിയത്:",
            },
            4: {
                language: "Kannada",
                translation: "ಎಂ.ಜಿ.ಸಿಕ್ಕಾ ಗಳಿಕೆ:",
            },
        },
        default: "Earned MG Sikka:",
    },
    accumulateSikkaText: {
        languages: {
            1: {
                language: "English",
                translation: "Complete this task to win {{100}} MG Sikka.",
            },
            2: {
                language: "hindi",
                translation: "{{100}} एमजी सिक्का को जीतने के लिए इस टास्क को पूरा करें।",
            },
            3: {
                language: "Marathi",
                translation: "{{100}} MG Sikka जिंकण्यासाठी हे कार्य पूर्ण करा.",
            },
            7: {
                language: "Punjabi",
                translation: "{{100}} MG ਸਿੱਕਾ ਨੂੰ ਜਿੱਤਣ ਲਈ ਇਸ ਕੰਮ ਨੂੰ ਪੂਰਾ ਕਰੋ।",
            },
            6: {
                language: "Bengali",
                translation: "এমজি সিক্কা জিততে {{100}} এই টাস্কটি সম্পূর্ণ করুন৷",
            },
            14: {
                language: "Assamese",
                translation: "{{100}} এম জি ছিক্কা জিকিবলৈ এই কাম সম্পূৰ্ণ কৰক।",
            },
            18: {
                language: "Nagamese",
                translation: "etu task kotom kory bhi jeti boley {{100}} MG Sikka.",
            },
            21: {
                language: "Khasi",
                translation: "Pyndep ia kane ka task ban jop {{100}} MG Sikka.",
            },
            20: {
                language: "Mizo",
                translation: "{{100}} MG Sikka hnehna chang turin he hna hi ti zo rawh.",
            },
            19: {
                language: "Manipuri",
                translation: "Masigi Task asi loisallu ngamnaba {{100}} MG Sikka.",
            },
            8: {
                language: "Gujarati",
                translation: "{{100}} MG સિક્કા જીતવા માટે આ કાર્ય પૂર્ણ કરો.",
            },
            17: {
                language: "Tamil",
                translation: "எம்ஜி சிக்காவை {{100}} வெல்ல இந்தப் பணியை முடிக்கவும்.",
            },
            5: {
                language: "Telugu",
                translation: "MG సిక్కా {{100}} గెలవడానికి ఈ టాస్క్‌ని పూర్తి చేయండి.",
            },
            10: {
                language: "Malayalam",
                translation: "എംജി സിക്ക {{100}} വിജയിക്കാൻ ഈ ടാസ്‌ക് പൂർത്തിയാക്കുക.",
            },
            4: {
                language: "Kannada",
                translation: "ಎಂಜಿ ಸಿಕ್ಕಾವನ್ನು {{100}} ಗೆಲ್ಲಲು ಈ ಕಾರ್ಯವನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.",
            },
        },
        default: "Complete this task to win {{100}} MG Sikka.",
    },
    wonMgSikka: {
        languages: {
            1: {
                language: "English",
                translation: "You won {{100}}  MG Sikka!",
            },
            2: {
                language: "Hindi",
                translation: "आपने जीत लिया {{100}} एमजी सिक्का!",
            },
            3: {
                language: "Marathi",
                translation: "तुम्ही {{100}} एमजी सिक्का जिंकले!",
            },
            7: {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ MG ਸਿੱਕਾ ਨੂੰ {{100}} ਜਿੱਤਿਆ!",
            },
            6: {
                language: "Bengali",
                translation: "আপনি জিতেছেন {{100}} এমজি সিক্কা!",
            },
            14: {
                language: "Assamese",
                translation: "আপুনি {{100}} এম জি ছিক্কা জিকিছিল!",
            },
            18: {
                language: "Nagamese",
                translation: "apne jeti shai {{100}} MG Sikka!",
            },
            21: {
                language: "Khasi",
                translation: "Phi jop {{100}} MG Sikka!",
            },
            20: {
                language: "Mizo",
                translation: "{{100}} MG Sikka i dawng ta!",
            },
            19: {
                language: "Manipuri",
                translation: "Adomna ngamle {{100}} MG Sikka!",
            },
            8: {
                language: "Gujarati",
                translation: "તમે {{100}} એમજી સિક્કા જીત્યા!",
            },
            17: {
                language: "Tamil",
                translation: "நீங்கள் {{100}} எம்ஜி சிக்காவை வென்றீர்கள்!",
            },
            5: {
                language: "Telugu",
                translation: "మీరు {{100}} MG సిక్కాను గెలుచుకున్నారు!",
            },
            10: {
                language: "Malayalam",
                translation: "നിങ്ങൾ {{100}} എംജി സിക്ക വിജയിച്ചു!",
            },
            4: {
                language: "Kannada",
                translation: "ನೀವು {{100}} MG ಸಿಕ್ಕಾವನ್ನು ಗೆದ್ದಿದ್ದೀರಿ!",
            },
        },
        default: "You won {{100}}  MG Sikka!",
    },
    updateAppText: {
        languages: {
            1: {
                language: "English",
                translation: "Please update app to use",
            },
            2: {
                language: "hindi",
                translation: "कृपया उपयोग करने के लिए ऐप को अपडेट करें",
            },
            3: {
                language: "Marathi",
                translation: "कृपया ॲप वापरण्यासाठी अपडेट करा",
            },
            7: {
                language: "Punjabi",
                translation: "ਕਿਰਪਾ ਕਰਕੇ ਵਰਤਣ ਲਈ ਐਪ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ",
            },
            6: {
                language: "Bengali",
                translation: "অনুগ্রহ করে ব্যবহার করার জন্য অ্যাপ আপডেট করুন",
            },
            14: {
                language: "Assamese",
                translation: "ব্যৱহাৰ কৰিবলৈ এপটো আপডেট কৰক",
            },
            18: {
                language: "Nagamese",
                translation: "morom bara app update kory bhi jola boley",
            },
            21: {
                language: "Khasi",
                translation: "Sngewbha ban update iaka app ban pyndonkam biang",
            },
            20: {
                language: "Mizo",
                translation: "App update rawh hmang thei turin",
            },
            19: {
                language: "Manipuri",
                translation: "Chanbiduna app update toubiyu sijinanaba",
            },
            8: {
                language: "Gujarati",
                translation: "ઉપયોગ કરવા માટે કૃપા કરીને એપ્લિકેશન અપડેટ કરો",
            },
            17: {
                language: "Tamil",
                translation: "பயன்படுத்த, பயன்பாட்டைப் புதுப்பிக்கவும்",
            },
            5: {
                language: "Telugu",
                translation: "దయచేసి ఉపయోగించడానికి యాప్‌ను అప్‌డేట్ చేయండి",
            },
            10: {
                language: "Malayalam",
                translation: "ഉപയോഗിക്കാൻ ആപ്പ് അപ്‌ഡേറ്റ് ചെയ്യുക",
            },
            4: {
                language: "Kannada",
                translation: "ದಯವಿಟ್ಟು ಬಳಸಲು ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನವೀಕರಿಸಿ",
            },
        },
        default: "Please update app to use",
    },
    SorryTitle: {
        languages: {
            1: {
                language: "English",
                translation: "Sorry!",
            },
            2: {
                language: "hindi",
                translation: "सॉरी!",
            },
            3: {
                language: "Marathi",
                translation: "माफ करा!",
            },
            7: {
                language: "Punjabi",
                translation: "ਮਾਫ਼ ਕਰਨਾ!",
            },
            6: {
                language: "Bengali",
                translation: "দুঃখিত!",
            },
            14: {
                language: "Assamese",
                translation: "দুঃখিত!",
            },
            18: {
                language: "Nagamese",
                translation: "beya nababhi!",
            },
            21: {
                language: "Khasi",
                translation: "Map!",
            },
            20: {
                language: "Mizo",
                translation: "Tihpalh!",
            },
            19: {
                language: "Manipuri",
                translation: "Ngakpigani!",
            },
            8: {
                language: "Gujarati",
                translation: "માફ કરશો!",
            },
            17: {
                language: "Tamil",
                translation: "மன்னிக்கவும்!",
            },
            5: {
                language: "Telugu",
                translation: "క్షమించండి!",
            },
            10: {
                language: "Malayalam",
                translation: "ക്ഷമിക്കണം!",
            },
            4: {
                language: "Kannada",
                translation: "ಕ್ಷಮಿಸಿ!",
            },
        },
        default: "Sorry!",
    },
    sorryText: {
        languages: {
            1: {
                language: "English",
                translation: "You were not able to completed all the events in Week 1 ",
            },
            2: {
                language: "hindi",
                translation: "आप पहले सप्ताह में सभी इवेंट पूरे नहीं कर सके",
            },
            3: {
                language: "Marathi",
                translation: "तुम्ही आठवडा १ मधील सर्व कार्यक्रम पूर्ण करू शकला नाही",
            },
            7: {
                language: "Punjabi",
                translation: "ਤੁਸੀਂ ਹਫ਼ਤੇ 1 ਵਿੱਚ ਸਾਰੀਆਂ ਘਟਨਾਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਸੀ",
            },
            6: {
                language: "Bengali",
                translation: "আপনি সপ্তাহ 1-এ সমস্ত ইভেন্ট সম্পূর্ণ করতে সক্ষম হননি",
            },
            14: {
                language: "Assamese",
                translation: "আপুনি ১ সপ্তাহত সকলো ইভেণ্ট সম্পূৰ্ণ কৰিব পৰা নাছিল",
            },
            18: {
                language: "Nagamese",
                translation: "apne all event doh 1 hapta deh kotom koty boley bara nai",
            },
            21: {
                language: "Khasi",
                translation: "Phim lah ban pyndep ia baroh ki event ha ka 1 T-iew",
            },
            20: {
                language: "Mizo",
                translation: "Week 1 chhung khan event zawng zawng i ti zo vek thei lo",
            },
            19: {
                language: "Manipuri",
                translation: "Adom weel 1 asida event loina loisanaba ngamde",
            },
            8: {
                language: "Gujarati",
                translation: "તમે અઠવાડિયું 1 માં બધી ઇવેન્ટ્સ પૂર્ણ કરવામાં સક્ષમ ન હતા",
            },
            17: {
                language: "Tamil",
                translation: "1 வாரத்தில் உங்களால் அனைத்து நிகழ்வுகளையும் முடிக்க முடியவில்லை",
            },
            5: {
                language: "Telugu",
                translation: "మీరు 1వ వారంలో అన్ని ఈవెంట్‌లను పూర్తి చేయలేకపోయారు",
            },
            10: {
                language: "Malayalam",
                translation: "1 ആഴ്ചയിൽ നിങ്ങൾക്ക് എല്ലാ ഇവന്റുകളും പൂർത്തിയാക്കാൻ കഴിഞ്ഞില്ല",
            },
            4: {
                language: "Kannada",
                translation: "ವಾರ 1 ರಲ್ಲಿ ಎಲ್ಲಾ ಈವೆಂಟ್‌ಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗಲಿಲ್ಲ",
            },
        },
        default: "You were not able to completed all the events in Week 1 ",
    },
};
export default translations;
