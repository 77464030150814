import surveyTypes from "../../actions/Survey/types";

export function surveyQuestionDataList(state = [], action) {
    switch (action.type) {
        case surveyTypes.FETCH_SURVEY_QUESTION_DATA: {
            return [...action.payload];
        }
        case surveyTypes.UPDATE_SURVEY_QUESTION_DATA: {
            const { questionId, resultURL } = action.payload;
            const newState = [...state];
            return newState.filter((survey) => survey.questionId !== questionId);
        }
        default:
            return state;
    }
}
