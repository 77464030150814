import React, { useState, useEffect } from "react";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
/** Play store count
@author Suman Kumar, 5th Dec 2023
**/
const PlayStoreCount = (props) => {
    const { languageId } = props;
    const endValue = 1000000;
    const [currentValue, setCurrentValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentValue < endValue) {
                setCurrentValue((prevValue) => Math.min(prevValue + 10000, endValue));
            } else {
                clearInterval(interval);
            }
        }, 10); // Adjust the interval as needed

        return () => clearInterval(interval);
    }, [currentValue, endValue]);

    const formatNumber = (value) => {
        const stringValue = value.toLocaleString("en-IN", { maximumFractionDigits: 0 });
        return stringValue;
    };

    return (
        <h4 className="mg_playstoreCount_header">
            {getTranslation(translations, "playStoreText", languageId).replace(
                "{{1000000}}",
                formatNumber(currentValue)
            )}
        </h4>
    );
};

export default PlayStoreCount;
