import React, { Component } from "react";
import requestFarmer from "../../MeraGaon/Request";
import toast from "react-hot-toast";
import playstorebutton from "../components/Images/p1.png";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
import playstorestar from "../components/Images/stars.png";
/**
 * @author Suman Kumar (3rd March 2023)
 * Get MG App link Section from bottom
 */
export default class GetMgAppBottom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: "",
            isSending: false,
            mobileError: "",
        };
    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    validate = () => {
        const { mobileNo } = this.state;
        let mobileError = "";

        if (mobileNo.trim().length !== 10) {
            mobileError = getTranslation(translations, "getLinkText", this.props.languageId);
            toast(mobileError, {
                icon: "❌",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        }
        if (mobileError) {
            this.setState({
                mobileError,
            });
            return false;
        }
        return true;
    };
    handleGetApp = (e) => {
        const isValid = this.validate();
        const languageId = this.props.languageId;
        if (isValid) {
            this.setState({ mobileError: "" });
            this.setState({ isSending: !this.state.isSending });
            requestFarmer({
                method: "post",
                url: `/api/protected/user/app-link-req`,
                data: { mobileNo: this.state.mobileNo, languageId },
            })
                .then(() => {
                    const message = getTranslation(translations, "smsLinkSuccessfully", this.props.languageId);
                    toast.success(message, {
                        style: {
                            borderRadius: "10px",
                            background: "#333",
                            color: "#fff",
                        },
                    });
                    this.setState({
                        isSending: false,
                        mobileNo: "",
                    });
                })
                .catch((error) => {
                    toast.error(error, {
                        style: {
                            borderRadius: "10px",
                            background: "#333",
                            color: "#fff",
                        },
                    });
                    console.log(error);
                    this.setState({
                        isSending: false,
                    });
                });
        }
    };
    render() {
        const { mobileNo, isSending } = this.state;
        const { languageId } = this.props;

        return (
            <div className="App_get_wrapper">
                <div className="getApp_footer_section">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <h4>{getTranslation(translations, "downloadApp", languageId)}</h4>
                        <div className="get_mg_app_foter">
                            <button>
                                <a href="https://meragaon.page.link/webapp" target="blank">
                                    <img src={playstorebutton} alt="" />
                                </a>
                            </button>
                        </div>
                        <div className="footer_playstore_rating">
                            <h5>
                                {getTranslation(translations, "playStoreRate", languageId)} <span>4.3</span>{" "}
                                <img src={playstorestar} alt="" />
                            </h5>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 get_mg_app_input_section_footer">
                        <input
                            id="number"
                            name="mobileNo"
                            value={mobileNo}
                            maxLength={10}
                            onChange={this.changeHandler}
                            placeholder={getTranslation(translations, "getLinkText", languageId)}
                        />
                        <button
                            disabled={isSending}
                            onClick={this.handleGetApp}
                            style={{ cursor: isSending ? "default" : "pointer" }}>
                            {getTranslation(translations, "getLink", languageId)}
                            {isSending ? <i className="fa fa-spin fa-spinner" /> : null}
                        </button>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getTranslation(translations, "getLinkBottomText", languageId),
                            }}></div>
                    </div>
                </div>
            </div>
        );
    }
}
