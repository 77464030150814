
/**
 * Get translation of a string in particular language
 * @param {Object} translationObj Translation object
 * @param {String} stringName 
 * @param {String | Number} languageId 
 * @returns string or throw error if translations are not available
 */
function getTranslation(translationObj, stringName, languageId="1"){
    if(typeof translationObj !=="object"){
        throw new Error(`typeof translationObj must be an object`)
    }

    if(typeof stringName!=="string"){
        throw new Error(`typeof stringName must be a string`);
    }

    if(!translationObj[stringName]){
        throw new Error(`translationObj does not have any translations for ${stringName} `);
    }

    const translations = translationObj[stringName];
    const languages = translations.languages;
    if (languages[languageId] && languages[languageId].translation) {
        return languages[languageId].translation;
    }

    return translations.default;
}   

export default getTranslation;