import { combineReducers } from "redux";
import candidate from "./Candidate/index";
import { surveyQuestionDataList } from "./Survey/index"
import { states, districts, subDistricts, townVillages } from "./location/index";
import { translationData } from "./translation";
export default combineReducers({
    candidate,
    states,
    districts,
    subDistricts,
    townVillages,
    translationData,
    surveyQuestionDataList,
});
