import DoublyLinkedListNode from "./DoublyLinkedListNode";
/**
 * Cicular Doubly Linked List to store testimonial data
 * @author Suman Kumar, 04 March 2023
 */
class DoublyCicularLinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }
    addNode = (data) => {
        const newNode = new DoublyLinkedListNode(data);
        if (!this.head) {
            //linked list is empty
            this.head = newNode;
            this.tail = newNode;
            return newNode;
        }
        this.tail.next = newNode; //add item to the end of the list
        this.head.prev = newNode;
        newNode.prev = this.tail;
        newNode.next = this.head; //making it cicular
        this.tail = newNode;
        return newNode;
    };

    arrayToLinkedList = (array) => {
        return array.map((item) => {
            return this.addNode(item);
        });
    };
}

export default DoublyCicularLinkedList;
