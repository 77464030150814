import React, { Component } from "react";
import SS1 from "./Images/U1.png";
import SS2 from "./Images/U2.png";
import SS3 from "./Images/U3.png";
import SS4 from "./Images/U4.png";
import quoteIcon from "./Images/quote1.png";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
import DoublyCicularLinkedList from "./DoublyCicularLinkedList"; //for carousdhskle
/**
 * @author Suman Kumar (6th March 2023)
 *  MG App TESTIMONIAL
 */
class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNode: null,
            play: false,
            intervalID: null,
            timeOutID: null,
        };
        this.currentRef = React.createRef();
        this.prevRef = React.createRef();
        this.nextRef = React.createRef();
    }
    componentDidMount() {
        /**
         * Initialized a Circular Doubly Linked List
         */
        var TESTIMONIALS = [
            {
                id: 0,
                image: SS1,
                name: getTranslation(translations, "reviewer1", this.props.languageId),
                description: getTranslation(translations, "review1", this.props.languageId),
            },
            {
                id: 1,
                image: SS2,
                name: getTranslation(translations, "reviewer2", this.props.languageId),
                description: getTranslation(translations, "review2", this.props.languageId),
            },
            {
                id: 2,
                image: SS3,
                name: getTranslation(translations, "reviewer3", this.props.languageId),
                description: getTranslation(translations, "review3", this.props.languageId),
            },
            {
                id: 3,
                image: SS4,
                name: getTranslation(translations, "reviewer4", this.props.languageId),
                description: getTranslation(translations, "review4", this.props.languageId),
            },
        ];
        let DoublyCicularLinkedListObj = new DoublyCicularLinkedList();
        let arrayLinkedList = DoublyCicularLinkedListObj.arrayToLinkedList(TESTIMONIALS);
        let activeNode = arrayLinkedList[1]; //randomly selected a node to display in the card, based on this, now we can show prev and next testimonials, YO.
        let timeOutID = setTimeout(() => {
            let intervalID = setInterval(this.changeCard, 10000);
            this.setState({ intervalID: intervalID });
        }, 5000);
        this.setState({ activeNode: activeNode, timeOutID: timeOutID });
    }

    handleChangeBtnClick = (e) => {
        let btnType = e.target.dataset.type; //next or prev
        let currentNode = this.state.activeNode;
        let newActiveNode = null;
        if (btnType === "prev") {
            newActiveNode = currentNode.prev;
        } else {
            newActiveNode = currentNode.next;
        }
        this.changeCard(newActiveNode);
    };
    changeCard = (newActiveNode = this.state.activeNode.next) => {
        this.currentRef.current.classList.add("animate-card");
        this.prevRef.current.classList.add("animate-card");
        this.nextRef.current.classList.add("animate-card");
        this.setState({ activeNode: newActiveNode, play: true });
        setTimeout(() => {
            this.currentRef.current.classList.remove("animate-card");
            this.prevRef.current.classList.remove("animate-card");
            this.nextRef.current.classList.remove("animate-card");
        }, 600);
    };
    componentWillUnmount() {
        clearInterval(this.state.intervalID);
        clearTimeout(this.state.timeOutID);
    }
    render() {
        let { activeNode } = this.state;
        if (!activeNode) {
            return null;
        }
        let prevNode = activeNode.prev;
        let nextNode = activeNode.next;
        const { languageId } = this.props;
        return (
            //    <SectionContainer>
            <div className="testimonial-section full-width">
                <div className="text-center">
                    <h1 className="mg_testimonial_heading">
                        {getTranslation(translations, "reviewHeading", languageId)}
                    </h1>
                </div>
                <div className="testimonial-wrapper">
                    <div className="testimonial-container">
                        <div key="first-card" className="first-card  " ref={this.prevRef}>
                            <img className="quoteIcon" src={quoteIcon} alt="quoteIcon" />
                            <p className="text-sm testimonial-text">
                                <i style={{ fontStyle: "normal" }}>{prevNode.data.description}</i>
                            </p>
                            <div className="info-wrapper">
                                <div className="image">
                                    <img src={prevNode.data.image} alt="" />
                                </div>
                                <div className="name">
                                    <p className="text-sm">{prevNode.data.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="second-card active-card animate-in" ref={this.currentRef}>
                            <div>
                                <img className="quoteIcon" src={quoteIcon} alt="quoteIcon" />
                                <p className="text-sm testimonial-text">
                                    {" "}
                                    <i style={{ fontStyle: "normal" }}>{activeNode.data.description}</i>{" "}
                                </p>
                            </div>
                            <div className="info-wrapper">
                                <div className="image">
                                    <img src={activeNode.data.image} alt="" />
                                </div>
                                <div className="name">
                                    <h5>{activeNode.data.name}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="third-card" ref={this.nextRef}>
                            <img className="quoteIcon" src={quoteIcon} alt="quoteIcon" />
                            <p className="text-sm testimonial-text">
                                {" "}
                                <i style={{ fontStyle: "normal" }}>{nextNode.data.description}</i>{" "}
                            </p>
                            <div className="info-wrapper">
                                <div className="image">
                                    <img src={nextNode.data.image} alt="" />
                                </div>
                                <div className="name">
                                    <p className="text-sm">{nextNode.data.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-btns">
                        <div className="wrapper">
                            <div onClick={this.handleChangeBtnClick} data-type="prev" className="arrow-btn">
                                <i className="fa fa-chevron-left" data-type="prev"></i>
                            </div>
                            <div onClick={this.handleChangeBtnClick} data-type="next" className="arrow-btn">
                                <i className="fa fa-chevron-right" data-type="next"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            //    </SectionContainer>
        );
    }
}
export default Testimonial;
