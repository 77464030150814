import React, { Component } from "react";
import Lightbox from "react-image-lightbox";

export default class ImageViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            photoIndex: this.props.photoIndex || 0,
        };
    }
    componentDidMount() {
        const { images } = this.props;
        this.setState({ images });
    }
    render() {
        const { images, photoIndex } = this.state;
        return (
            <Lightbox
                mainSrc={images[photoIndex]}
                imageTitle={this.props.title || ""}
                nextSrc={images.length === 1 ? undefined : images[(photoIndex + 1) % images.length]}
                prevSrc={images.length === 1 ? undefined : images[(photoIndex + images.length - 1) % images.length]}
                ariaHideApp={false}
                onAfterOpen={() => {
                    var elem = document.getElementsByClassName("ril__image")[0];
                    elem.style.transform = "rotate(" + 0 + "deg)";
                }}
                onCloseRequest={() => {
                    this.props.onClose();
                }}
                onPreMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                }
                onPreMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                }
            />
        );
    }
}
