import { v4 as uuidv4 } from "uuid";

/**
 * @description get URL query parameters
 * also computes the values of some variables
 */
export function getURLParams() {
    let params = {};
    try {
        const urlSearchParams = new URLSearchParams(window.location.search.replaceAll("and", "&"));
        params = Object.fromEntries(urlSearchParams.entries());
        const redirectedFrom = params.creatorId != null ? "creator-link" : params.type != null ? "general-link" : "not-available";

        params.hash = params.h || params.hash;
        params.languageId = params.l || params.languageId;

        params.redirectedFrom = redirectedFrom;
    } catch (e) {
        params = {};
    }
    return params;
}

/**
 * updates redirectedFrom value in session storage
 * The reason why this value is kept in session storage is that
 * we want to preserve the value on page refresh, as we cannot store this
 * in query param because user can manipulate this value.
 * 
 * In session storage, this value is stored as a base64 encoded string
 * 
 * redirectedFrom determines from where the user has redirected
 * to the website. 
 * 
 * ===============
 * Possible Values
 * ===============
 * creator-link: User clicked on the feed creator short url
 * general-link: User clicked on the feed short url
 * not-available: User clicked on the long url directly, or opened the url in new tab/window
 */
export function setRedirectedFrom(redirectedFrom) {
    const encodedRedirectedFrom = sessionStorage.getItem("rF");
    if (encodedRedirectedFrom == null) {
        sessionStorage.setItem("rF", btoa(redirectedFrom));
    }
}

export function getRedirectedFrom() {
    const encodedRedirectedFrom = sessionStorage.getItem("rF");
    const redirectedFrom = encodedRedirectedFrom ? atob(encodedRedirectedFrom) : null;
    return redirectedFrom;
}

export function setRedirectToPlayStore(redirectToPlayStore) {
    const encodedRedirectToPlayStore = sessionStorage.getItem("rtp");
    if (encodedRedirectToPlayStore == null) {
        sessionStorage.setItem("rtp", btoa(redirectToPlayStore));
    }
}

export function getRedirectToPlayStore() {
    const encodedRedirectToPlayStore = sessionStorage.getItem("rtp");
    const redirectToPlayStore = encodedRedirectToPlayStore ? atob(encodedRedirectToPlayStore) : null;
    return redirectToPlayStore;
}

export function setLocalStorageTempered() {
    const encoded = btoa("true");
    localStorage.setItem("lst", encoded);
}

export function getLocalStorageTempered() {
    const encoded = localStorage.getItem("lst");
    const localStorageTempered = encoded ? atob(encoded) : null;
    return localStorageTempered;
}

/**
 * Unique identifier of the user
 * this ID is stored in localStorage so that we can
 * track the user who is using the browser
 * and handle the reachCount of feed in sync of view count
 */
export function setUserId(uId) {
    const encodedUserId = localStorage.getItem("uId");
    if (encodedUserId == null) {
        localStorage.setItem("uId", btoa(uId));
    }
}

export function getUserId() {
    const encodedUId = localStorage.getItem("uId");
    const uId = encodedUId ? atob(encodedUId) : null;
    return uId;
}

/**
 * generates a unique ID for the browser user
 */
export function generateUniqueUserId() {
    return uuidv4();
}
