import React from "react";
import Image1 from "./Images/123.png";
import SS1 from "./Images/ss1.png";
import SS2 from "./Images/ss2.png";
import SS3 from "./Images/ss3.png";
import SS4 from "./Images/ss4.png";
import SS5 from "./Images/ss5.png";
import SS6 from "./Images/ss6.png";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
/**
 * @author Suman Kumar, 4th March 2023
**/

export default function GreenImageSection(props) {
    const { languageId } = props;
    return (
        <div className="mg_green_section">
            <div className="meraGaon_group_image_section">
                <h2> {getTranslation(translations, "greenPartHeading1", languageId)}</h2>
                <p>{getTranslation(translations, "greenPartSubHeading1", languageId)}</p>
                <p style={{ color: "white" }}>{getTranslation(translations, "greenPartText1", languageId)}</p>
                <img src={Image1} alt="" />
            </div>
            <div className="meraGaon_screenshot_section">
                <h2> {getTranslation(translations, "greenPartHeading2", languageId)}</h2>
                <p>{getTranslation(translations, "greenPartSubHeading2", languageId)}</p>
                <p style={{ color: "white" }}>{getTranslation(translations, "greenPartText2", languageId)}</p>
                <div className="meraGaon_screenshot_image_section">
                    <div>
                        <img src={SS1} alt="" />
                        <h6>{getTranslation(translations, "ss1", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss1d", languageId)}</h6>
                    </div>
                    <div>
                        <img src={SS2} alt="" />
                        <h6>{getTranslation(translations, "ss2", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss2d", languageId)}</h6>
                    </div>
                    <div>
                        <img src={SS3} alt="" />
                        <h6>{getTranslation(translations, "ss3", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss3d", languageId)}</h6>
                    </div>
                    <div>
                        <img src={SS4} alt="" />
                        <h6>{getTranslation(translations, "ss4", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss4d", languageId)}</h6>
                    </div>
                    <div>
                        <img src={SS5} alt="" />
                        <h6>{getTranslation(translations, "ss5", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss5d", languageId)}</h6>
                    </div>
                    <div>
                        <img src={SS6} alt="" />
                        <h6>{getTranslation(translations, "ss6", languageId)}</h6>
                        <h6 style={{ color: "#ffffffad" }}>{getTranslation(translations, "ss6d", languageId)}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
