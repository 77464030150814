import React, { Component } from "react";
import EventBtnCard from "./Components/index";
import NoDataFound from "../../elements/NoDataFound";
import Loader from "./Components/Loader";
import ErrorUI from "./Components/AppErrorUI";
import requestFarmer from "../MeraGaon/Request";
import ScrollUi from "./Components/ScrollUi";
/**
 * @description Mera Gaon weekly activities
 * @author Suman Kumar (21-12-22)
 */
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            weeklyEventData: {},
        };
    }
    componentDidMount() {
        this.getUserWeeklyEventData();
    }
    onClaimAvailable = async () => {
        try {
            if (window.MeraGaon && window.MeraGaon.onClaimAvailable) {
                await window.MeraGaon.onClaimAvailable();
            }
        } catch (error) {
            console.log(error);
        }
    };
    getUserWeeklyEventData = () => {
        this.setState({ isLoading: true, isError: false });
        const userAuthKey = window.MeraGaon && window.MeraGaon.getUserData() ? window.MeraGaon.getUserData() : null;
        if (!userAuthKey) {
            this.setState({ isLoading: false, isError: true });
            return;
        }
        const decryptKey = userAuthKey;

        // const decryptKey =
        //    "McZ82Wz6VrPYjyxJgdBe8kcuvzhTV/k7NWVlX42pLPxC8aGpUYpWsAoX9G/zimN+Iom2fJCXKvagsXregphSM+idjK7wp5BVokQ/1YxVdl/gEX+s/xOeeSDUz7jtjLM3aAykU9guG+LS7W7r7VsA3MxLTNCy6B0pd8uc9fL2xa6C5EuCQGE7KKZqCbI0Jc6xDrc04e2NZ662ZURxzO5WvvujQzTdkxISK6IjWcDqbFLgzNtTBmWGZMtx116jaUNNqkcD94Y4ECzcG6WV7WGrmaHCwvK6fKF+U3U+hTZXqX2ND9kUjGGW8IctC1F7vNfoyWkPoVBeoCLmc8LzwSm7pfls9DOUslqTa9EiArGlaJ7ylEv6HoA6HJ7u0yVjJmwv9yvepBrRWjy/B15QVlwd/UPfiFLdQGcamKuyiqGZtiDt1Erbe5atdlLLnQiqWhoEN33ByWdegB31s41uvljDbxcZb0jjWua0xZ9o11Hi0Sq1xt1HoCtUv62myXF4npozmtKqQtn/mBxiNO0P7rBjTfhHgNtNpCEsfcE7AXWt8xRlAiDXQTnwIbINIwtrZKKoEBSB470MD+FUaPAPg6f9coPYYrsaHDLergMMdQeKm/e5iZnm9EFe1Xd4qyjhZIyOowOKGt0Acf2DeN6Ck1Q+kXq21I4QYtwURhA3pm0ypiY=";
        requestFarmer({
            url: `/api/protected/first-week/info`,
            method: "POST",
            data: { data: decryptKey },
        })
            .then((res) => {
                this.setState({ weeklyEventData: res.data, isLoading: false });
                if (res.data.claimRewardStatus === "eligible") {
                    this.onClaimAvailable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isLoading: false, isError: true });
            });
    };
    getUI = () => {
        const { isLoading, isError, weeklyEventData } = this.state;
        let eventData = [];
        if (weeklyEventData && weeklyEventData.events) {
            eventData = weeklyEventData.events;
        }
        let userData = "";
        if (weeklyEventData && weeklyEventData.user) {
            userData = weeklyEventData.user;
        }
        const ui = [];
        if (eventData && eventData.length) {
            ui.push(
                <EventBtnCard
                    eventData={eventData}
                    userData={userData}
                    key={"eventData"}
                    claimRewardStatus={weeklyEventData.claimRewardStatus}
                    appVersion={weeklyEventData.appVersion}
                />
            );
        }
        if (isLoading) {
            ui.push(
                <div style={style} key="loader">
                    <Loader show={true} fontSize={"16px"} languageId={userData.languageId} />
                </div>
            );
        }
        if (!isLoading && !isError && eventData.length === 0) {
            ui.push(
                <div style={style} key="noDataFound">
                    <NoDataFound message={"No weekly event data found"} />
                </div>
            );
        }
        if (isError) {
            ui.push(
                <div style={style} key="error">
                    <ErrorUI retry={() => this.getUserWeeklyEventData()} languageId={userData.languageId} />
                </div>
            );
        }
        return ui;
    };
    render() {
        return (
            <React.Fragment>
                {this.state.isLoading ? <ScrollUi /> : null}
                {this.getUI()}
            </React.Fragment>
        );
    }
}
export default Index;
const style = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    fontSize: "16px",
    boxShadow: "#0000004d 0px 0px 3px",
    width: "100%",
    position: "absolute",
    top: "0",
};
