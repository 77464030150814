import axios from "axios";

// class Request
class Request {
    client = axios.create();
    baseUrl;
    subscribers = [];
    isRefreshing = false;
    constructor(baseURL) {
        if (baseURL !== undefined) {
            this.baseUrl = baseURL;
            this.subscribers = [];
            this.isRefreshing = false;
            this.beforeRequest = this.beforeRequest.bind(this);
            this.onRequestSuccess = this.onRequestSuccess.bind(this);
            this.onRequestFail = this.onRequestFail.bind(this);
            this.client = axios.create({
                baseURL: this.baseUrl,
                timeout: 360000,
            });
            this.client.interceptors.request.use(this.beforeRequest);
            this.client.interceptors.response.use(this.onRequestSuccess, this.onRequestFail);
        }
    }

    beforeRequest(requestObj) {
        const modifiedRequest = Object.assign({}, requestObj);
        let headers = {};
        try {
            const newHeaders = JSON.parse(localStorage.getItem("farmer-headers"));
            if (newHeaders) {
                headers = newHeaders;
            }
        } catch (err) {
            console.log(err);
        }

        headers.Authorization = `Basic ${btoa(
            `${process.env.REACT_APP_FARMER_BACKEND_CLIENT_NAME}:${process.env.REACT_APP_FARMER_BACKEND_CLIENT_SECRET}`
        )}`;
        headers.apiKey = process.env.REACT_APP_FARMER_BACKEND_API_KEY;
        headers.clientId = process.env.REACT_APP_FARMER_BACKEND_CLIENT_ID;
        headers.version = process.env.REACT_APP_FARMER_BACKEND_CLIENT_VERSION;

        modifiedRequest.headers = headers;
        return modifiedRequest;
    }

    onRequestSuccess(config) {
        return config.data;
    }

    onRetrySuccess(accessToken) {
        this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
    }

    addSubscriber(callback) {
        this.subscribers.push(callback);
    }

    async onRequestFail(err) {
        const { response, config } = err;
        const originalReq = config;
        if (response && response.status === 401) {
            const retryOriginalRequest = new Promise((resolve) => {
                this.addSubscriber((accessToken) => {
                    originalReq.headers.token = accessToken;
                    resolve(requestFarmer(originalReq));
                });
            });

            if (!this.isRefreshing) {
                this.isRefreshing = true;
                try {
                    const token = await this.getNewToken();
                    this.isRefreshing = false;
                    this.onRetrySuccess(token);
                } catch (err) {
                    console.log(err);
                    // window.location.href = "/";
                }
            }
            return retryOriginalRequest;
        } else if (response && response.status === 502) {
            localStorage.setItem("redirectUrl", window.location.href);
            // window.location.href = "/";
        } else if (response && response.status === 403) {
            localStorage.clear();
            localStorage.setItem("redirectUrl", window.location.href);
            // window.location.href = "/";
        }

        throw err;
    }

    getNewToken = () => {
        return new Promise((resolve, reject) => {
            const headers = JSON.parse(localStorage.getItem("farmer-headers"));
            axios({
                method: "post",
                url: `${process.env.REACT_APP_FARMER_BACKEND_URL}/api/protected/user/login/token/create`,
                headers,
            })
                .then((res) => {
                    //console.log(res);
                    // on successfull response of new token, update the token in local storage
                    if (res.data.status === "success") {
                        headers.token = res.data.data.token;
                        localStorage.setItem("farmer-headers", JSON.stringify(headers));
                        resolve(res.data.data.token);
                    } else {
                        reject();
                    }
                })
                .catch((err) => {
                    reject();
                });
        });
    };
}

const requestFarmer = new Request(process.env.REACT_APP_FARMER_BACKEND_URL).client;
export default requestFarmer;
