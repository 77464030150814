import React from "react";
import { BottomTextContainer } from "./util";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
/**
 * @description Mera Gaon weekly activity Bottom Text
 * @author Suman Kumar (21-12-22)
 */
export default function BottomText(props) {
    const { languageId, noOfEvent,eventData } = props;
    return (
        <BottomTextContainer
            style={{
                 top: `${languageId == 17 || languageId == 21 || languageId == 10 || languageId == 20 ? "89%" : "90%"}`,
                //top: `${noOfEvent == 4 || noOfEvent == 5 ? "89%" : noOfEvent == 6 ? "91%" : "92%"}`,
            }}>
            <p>
                {getTranslation(translations, "bottomText", languageId)} 🎉 {""}
                {getTranslation(translations, "bottomText2", languageId)}
            </p>
        </BottomTextContainer>
    );
}
