import React from "react";
/**
 * @description Mera Gaon weekly activities loader same as APP
 * @author Suman Kumar (30-12-22)
 */
function Loader(props) {
    const { show } = props;
    if (!show) return null;
    return (
        <div
            style={{
                padding: "8px 36px",
                textAlign: "center",
                fontSize: "16px",
                color: "#555555",
            }}>
            <div className="loader-weekly"></div>
            {/* <p style={{ marginTop: "24px" }}>{getTranslation(translations, "loadingText", props.languageId)}</p> */}
        </div>
    );
}
export default Loader;
