import React, { Component } from "react";
import requestFarmer from "../../MeraGaon/Request";
import toast from "react-hot-toast";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
/**
 * @author Suman Kumar (2nd March 2023)
 * Get MG App link Section
 */
export default class GetLinkHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: "",
            isSending: false,
            mobileError: "",
        };
    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    validate = () => {
        const { mobileNo } = this.state;
        let mobileError = "";

        if (mobileNo.trim().length !== 10) {
            mobileError = getTranslation(translations, "getLinkText", this.props.languageId);
            toast(mobileError, {
                icon: "❌",
                style: {
                    borderRadius: "6px",
                    background: "#333",
                    color: "#fff",
                },
            });
        }
        if (mobileError) {
            this.setState({
                mobileError,
            });
            return false;
        }
        return true;
    };
    handleGetApp = (e) => {
        const isValid = this.validate();
        const languageId = this.props.languageId;
        if (isValid) {
            this.setState({ mobileError: "" });
            this.setState({ isSending: !this.state.isSending });
                requestFarmer({
                    method: "post",
                    url: `/api/protected/user/app-link-req`,
                    data: { mobileNo: this.state.mobileNo, languageId },
                })
                    .then(() => {
                        const message = getTranslation(translations, "smsLinkSuccessfully", this.props.languageId);
                        toast.success(message, {
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                        this.setState({
                            isSending: false,
                            mobileNo: "",
                        });
                    })
                    .catch((error) => {
                        toast.error(error, {
                            style: {
                                borderRadius: "10px",
                                background: "#333",
                                color: "#fff",
                            },
                        });
                        console.log(error);
                        this.setState({
                            isSending: false,
                        });
                    });
        }
    };
    render() {
        const { mobileNo, isSending } = this.state;
        const { languageId } = this.props;

        return (
            <div className="get_mg_app_link_home">
                <input
                    id="number"
                    name="mobileNo"
                    value={mobileNo}
                    maxLength={10}
                    onChange={this.changeHandler}
                    placeholder={getTranslation(translations, "getLinkText", languageId)}
                />
                <button
                    disabled={isSending}
                    onClick={this.handleGetApp}
                    style={{ cursor: isSending ? "default" : "pointer" }}>
                    {getTranslation(translations, "getLink", languageId)}
                    {isSending ? <i className="fa fa-spin fa-spinner" /> : null}
                </button>{" "}
                {/* <p>
                    *By submitting your mobile number, you agree to Our{" "}
                    <a href="https://meragaonapp.com/legal" target="blank">
                        Terms of use
                    </a>{" "}
                    and{" "}
                    <a href="https://meragaonapp.com/legal" target="blank">
                        Privacy Policy.
                    </a>
                </p> */}
                <div
                    dangerouslySetInnerHTML={{
                        __html: getTranslation(translations, "getLinkBottomText", languageId),
                    }}></div>
            </div>
        );
    }
}
