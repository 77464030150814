import React from "react";
import {
    HomeImageWrapper,
    HomeImageSection,
    HomeDetailSection,
    ButttonActionContainer0,
    ButttonActionContainerLeft1,
    ButttonActionContainerRight1,
    ButttonActionContainerLeft2,
    ButttonActionContainerRight2,
    DayName,
    CompletedAction,
    LockedAction,
    BottomButtonContainer,
    BotttomButton,
} from "./util";
import ScrollableAnchor from "react-scrollable-anchor";
import weekActivityImage from "./images/aaa.png";
import MileImage0 from "./images/mile0.png";
import MileImage1 from "./images/mile.png";
import MileImage2 from "./images/mile2.png";
import MileImage3 from "./images/mile3.png";
import MileImage4 from "./images/mile4.png";
export default function ScrollUi() {
    return (
        <HomeImageWrapper style={{ display: "none" }}>
            {/* <HomeImageWrapper> */}
            <HomeImageSection>
                <img alt="" src={weekActivityImage} />
            </HomeImageSection>
            <HomeDetailSection>
                <h4></h4>
            </HomeDetailSection>
            <ButttonActionContainer0>
                <CompletedAction style={{ textAlign: "right" }}>
                    <div style={{ width: "80%" }}>
                        <button>
                            <p>🎉 </p>
                            <p>😊 😊</p>
                        </button>
                    </div>
                    <div>
                        <img alt="" src={MileImage0}></img>
                        <DayName style={{ right: "26%" }}>Day</DayName>
                    </div>
                </CompletedAction>
            </ButttonActionContainer0>
            {/* <ScrollableAnchor id={"event1"}> */}
                <ButttonActionContainerLeft1>
                    <LockedAction style={{ textAlign: "left" }}>
                        <div>
                            <img alt="" src={MileImage1}></img>
                            <DayName style={{ left: "26%" }}>Day</DayName>
                        </div>
                        <div style={{ width: "80%" }}>
                            <button disabled={true}>🔓 Locked</button>
                        </div>
                    </LockedAction>
                </ButttonActionContainerLeft1>
            {/* </ScrollableAnchor> */}
            {/* <ScrollableAnchor id={"event2"}> */}
                <ButttonActionContainerRight1>
                    <LockedAction style={{ textAlign: "right" }}>
                        <div style={{ width: "80%" }}>
                            <button disabled={true} style={{ width: "80%" }}>
                                🔓 Locked
                            </button>
                        </div>
                        <div>
                            <img alt="" src={MileImage2}></img>
                            <DayName style={{ right: "28%" }}>Day</DayName>
                        </div>
                    </LockedAction>
                </ButttonActionContainerRight1>
            {/* </ScrollableAnchor> */}
            {/* <ScrollableAnchor id={"event3"}> */}
                <ButttonActionContainerLeft2>
                    <LockedAction style={{ textAlign: "left" }}>
                        <div>
                            <img alt="" src={MileImage3}></img>
                            <DayName style={{ left: "28%" }}>Day</DayName>
                        </div>
                        <div style={{ width: "80%" }}>
                            <button disabled={true}>🔓 Locked</button>
                        </div>
                    </LockedAction>
                </ButttonActionContainerLeft2>
            {/* </ScrollableAnchor> */}
            {/* <ScrollableAnchor id={"event4"}> */}
                <ButttonActionContainerRight2>
                    <LockedAction style={{ textAlign: "right" }}>
                        <div style={{ width: "80%" }}>
                            <button disabled={true} style={{ width: "80%" }}>
                                🔓 Locked
                            </button>
                        </div>
                        <div>
                            <img alt="" src={MileImage4}></img>
                            <DayName style={{ right: "26%" }}>Day</DayName>
                        </div>
                    </LockedAction>
                </ButttonActionContainerRight2>
            {/* </ScrollableAnchor> */}
            {/* <ScrollableAnchor id={"claimReward"}> */}
                <BottomButtonContainer>
                    <BotttomButton>
                        <button>Claim reward</button>
                    </BotttomButton>
                </BottomButtonContainer>
            {/* </ScrollableAnchor> */}
        </HomeImageWrapper>
    );
}
