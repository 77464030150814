import styled from "styled-components";

export const HomeImageWrapper = styled.div`
    height: 100%;
    position: relative;
`;
export const HomeImageSection = styled.div`
    img {
        max-height: 100%;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: fill;
    }
`;
export const HomeDetailSection = styled.div`
    width: 100%;
    position: absolute;
    top: 9%;
    h4 {
        padding: 4px 10px;
        font-size: 7vw;
        text-align: center;
        color: white;
        font-weight: 600;
    }
    h5 {
        padding: 6px 14px 4px 14px;
        font-size: 2.2rem;
        text-align: center;
        color: white;
        line-height: 1.2;
    }
`;
export const ButttonActionContainer0 = styled.div`
    // position: absolute;
    //  top: 23%;
    // right: 4%;
    // width: 90%;
`;
export const ButttonActionContainerLeft1 = styled.div`
    //  position: absolute;
    // top: 35%;
    // left: 4%;
    // width: 96%;
`;
export const ButttonActionContainerRight1 = styled.div`
    // position: absolute;
    // top: 47%;
    // right: 4%;
    // width: 90%;
`;
export const ButttonActionContainerLeft2 = styled.div`
    // position: absolute;
    // top: 57%;
    // left: 4%;
    // width: 96%;
`;
export const ButttonActionContainerRight2 = styled.div`
    // position: absolute;
    // top: 80%;
    // right: 4%;
    // width: 90%;
`;
export const DayName = styled.p`
    top: 12px;
    position: absolute;
    padding: 4px;
    font-size: 14px;
    color: white;
    font-weight: 500;
`;

export const ActiveAction = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    img {
        height: 100px;
        width: 60px;
        object-fit: contain;
    }
    button {
        width: 85%;
        background: #fafafa;
        color: #2e2d2d;
        padding: 6px 4px;
        font-size: 3.6vw;
        border-radius: 4px;
        font-weight: 500;
        border: none;
        box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
        &:hover {
            box-shadow: none;
            color: #676565cc;
        }
    }
`;
export const ApprovalPendingAction = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    img {
        height: 100px;
        width: 60px;
        object-fit: contain;
    }
    button {
        width: 85%;
        background: #fff7b4;
        color: #918c1e;
        padding: 6px 4px;
        font-size: 3.6vw;
        border-radius: 4px;
        font-weight: 500;
        border: none;
        box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
        span {
            font-size: 2.7vw;
            color: #918c1e;
            font-weight: 200;
        }
    }
`;
export const CompletedAction = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    img {
        height: 100px;
        width: 60px;
        object-fit: contain;
    }
    button {
        width: 85%;
        background: #d7ffdc;
        color: #0f4617;
        padding: 6px 4px;
        font-size: 3.6vw;
        border-radius: 4px;
        font-weight: 500;
        border: none;
        box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
        span {
            font-size: 2.7vw;
            color: #1c4617bd;
            font-weight: 200;
        }
    }
`;
export const UnCompletedAction = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    img {
        height: 100px;
        width: 60px;
        object-fit: contain;
    }
    button {
        width: 85%;
        background: #f6d0d1;
        color: #980101;
        padding: 6px 4px;
        font-size: 3.6vw;
        border-radius: 4px;
        font-weight: 500;
        span {
            font-size: 2.7vw;
            color: #99211dd4;
            font-weight: 200;
        }
    }
`;
export const LockedAction = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    text-align: right;
    img {
        height: 100px;
        width: 60px;
        object-fit: contain;
    }
    button {
        width: 90%;
        border: none;
        background: #d0d0d0;
        padding: 8px;
        font-size: 13px;
        border-radius: 4px;
        color: #949393;
        font-weight: 500;
        text-align: center;
    }
`;
export const BottomTextContainer = styled.div`
    position: absolute;
    // top: 86%;
    p {
        margin: 0 8px;
        padding: 4px 2px;
        color: white;
        font-size: 4.4vw;
        text-align: center;
        font-weight: 500;
    }
`;
export const BottomButtonContainer = styled.div`
    position: absolute;
    top: 94%;
    right: 4%;
    width: 90%;
`;
export const BotttomButton = styled.div`
    text-align: center;
    display: flex;
    gap: 14px;
    button {
        width: 100%;
        border: none;
        background: #f6fff7;
        padding: 10px 12px;
        font-size: 3.6vw;
        border-radius: 4px;
        color: #000000d4;
        font-weight: 500;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        &:active {
            background: #e1f2e4;
            border: 1px solid #7c7f7c;
            box-shadow: none;
            color: #0000005c;
        }
    }
`;
export const EventContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    padding: 4px 8px;
    gap: 131px;
    top: 24%;
    @media screen and (min-width: 350px) {
        gap: ${(props) =>
            props.languageId == 21 ||
            props.languageId == 20 ||
            props.languageId == 17 ||
            props.languageId == 10 ||
            props.languageId == 18
                ? "115px"
                : "120px"};
    }

    @media screen and (min-width: 360px) {
        gap: ${(props) =>
            props.languageId == 21 ||
            props.languageId == 20 ||
            props.languageId == 17 ||
            props.languageId == 10 ||
            props.languageId == 18
                ? "120px"
                : "125px"};
    }

    @media screen and (min-width: 375px) {
        gap: ${(props) =>
            props.languageId == 21 ||
            props.languageId == 20 ||
            props.languageId == 17 ||
            props.languageId == 10 ||
            props.languageId == 18
                ? "130px"
                : "135px"};
    }

    @media screen and (min-width: 400px) {
        gap: ${(props) =>
            props.languageId == 21 ||
            props.languageId == 20 ||
            props.languageId == 17 ||
            props.languageId == 10 ||
            props.languageId == 18
                ? "148px"
                : "153px"};
    }
    @media screen and (min-width: 425px) {
        gap: ${(props) =>
            props.languageId == 21 ||
            props.languageId == 20 ||
            props.languageId == 17 ||
            props.languageId == 10 ||
            props.languageId == 18
                ? "165px"
                : "170px"};
    }
`;
export const UpdateAppText = styled.p`
    text-align: center;
    background: rgb(253, 230, 154);
    padding: 8px 4px;
    width: 90%;
    margin: 6px auto;
    border-radius: 20px;
    color: rgb(21, 115, 34);
    font-weight: 600;
    font-size: 15px;
`;
export const EarnRewardText = styled.p`
    text-align: center;
    color: #1f6bf5;
    margin-top: 4px;
    font-weight: 600;
    font-size: 12px;
`;
export const CanEarnRewardText = styled.p`
    text-align: center;
    color: #0e47b1;
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
`;
