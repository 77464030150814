import React from "react";
import MG_Logo from "../../images/MG_Logo.png";
import playstoreIcon from "../../images/playstore.png";
import { NavLink } from "react-router-dom";
import translations from "../pages/MeraGaonWebpage/translation";
import getTranslation from "../../utils/getTranslation";
import { getURLParams } from "../pages/MeraGaon/Feed/page.util";
/* eslint-disable */
/**
 * @author Suman Kumar (2 March 2023)
 * New Header for MG Website
 */
class NewHeader extends React.Component {
    constructor(props) {
        super(props);
        const params = getURLParams();
        const languageId = params && params.l ? params.l : this.props.languageId
        this.state = {
            activeIndex: -1,
            dropdownVisible: false,
            languageId: languageId,
            // linksData: [
            //     {
            //         name: getTranslation(translations, "asp", languageId),
            //         hyperLink: "/asp",
            //     },
            //     {
            //         name: getTranslation(translations, "careers", languageId),
            //         hyperLink: "/careers",
            //     },
            // ],
            linksData: this.generateLinksData(languageId),
            menuVis: "hid",
            menuIco: "fa fa-bars",
        };
        this.handleClick = this.handleClick.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.removeQueryParam = this.removeQueryParam.bind(this);
        this.openUrl = this.openUrl.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.languageId !== this.props.languageId) {
            this.setState({
                languageId: this.props.languageId,
                linksData: this.generateLinksData(this.props.languageId),
            });
        }
    }
    generateLinksData(languageId) {
        return [
            {
                name: getTranslation(translations, "asp", languageId),
                hyperLink: "/asp",
            },
            {
                name: getTranslation(translations, "careers", languageId),
                hyperLink: "/careers",
            },
        ];
    }
    handleClick(index) {
        this.setState({
            activeIndex: index,
        });
    }
    toggleMenu() {
        if (this.state.menuVis === "vis") {
            this.setState({ menuVis: "hid", menuIco: "fa fa-bars" });
        } else {
            this.setState({ menuVis: "vis", menuIco: "fa fa-close" });
        }
    }
    removeQueryParam() {
        this.setState({ menuVis: "hid", menuIco: "fa fa-bars" });
    }
    openUrl() {
        window.open("https://meragaon.page.link/webapp", "_blank");
    }
    render() {
        var active = null;
        var that = this;
        const { languageId } = this.state;
        return (
            <div id="kn-header" className="scroll-before clearfix">
                <div className="col-lg-5 col-md-4 col-sm-12">
                    <div className="logo-container">
                        <a href="/">
                            <img src={MG_Logo} alt="kisan network logo" />
                        </a>
                    </div>
                    <button onClick={this.toggleMenu} className="menu-btn">
                        <i className={this.state.menuIco} />
                    </button>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12">
                    <div className={"link-container " + this.state.menuVis}>
                        <ul className="main-list">
                            {this.state.linksData.map(function(elem, index) {
                                return (
                                    <li
                                        className={active}
                                        key={index}
                                        onClick={() => {
                                            that.removeQueryParam();
                                        }}>
                                        <NavLink exact activeClassName="active" to={elem.hyperLink}>
                                            {elem.name}
                                        </NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                        <button
                            className="header_appdownload_btn"
                            onClick={() => {
                                that.openUrl();
                            }}>
                            {getTranslation(translations, "appDownloadButton", languageId)}
                            <img src={playstoreIcon} alt="" />
                        </button>
                    </div>
                </div>
                {window.location.pathname.includes("/careers") ? (
                    <div className="col-md-12 col-sm-12 hide-on-mobile hide-on-tablet header_comming_soon">
                        <button>New design coming soon!</button>
                    </div>
                ) : null}
            </div>
        );
    }
}
export default NewHeader;
