import React, { useState } from "react";
import ImageViewer from "../../common/ImageViewer";
function Image({ src }) {
    const [isOpen, toggleOpen] = useState(false);
    function handleOpen() {
        if (src) {
            toggleOpen(true);
        }
    }
    return (
        <div>
            <img onClick={handleOpen} src={src} alt="" style={{ cursor: "pointer" }} />
            {isOpen ? <ImageViewer onClose={() => toggleOpen(false)} images={[src]} /> : null}
        </div>
    );
}
export default Image;
