import React from "react";
import HeroSection from "./components/HeroSection";
import GreenImageSection from "./components/GreenImageSection";
import MGSikkaSection from "./components/MGSikkaSection"
import MGDostSection from "./components/MGDostSection"
import MGJigsawSection from "./components/MGJigsawSection"
import Testimonial from "./components/Testimonial";
import GetMgAppBottom from "./components/GetMgAppBottom";
/**
@author Suman Kumar, 4th March 2023
**/
const languages = {
    english: 1,
    hindi: 2,
    marathi: 3,
    kannada: 4,
    telugu: 5,
    bengali: 6,
    punjabi: 7,
    gujarati: 8,
    malayalam: 10,
    tamil: 17,
    assamese: 14,
    khasi: 21,
    mizo: 20,
    manipuri: 19,
    nagamese: 18,
};
export default function index(props) {
    let languageId = 1;
    try {
        const language = props.computedMatch.params.mglanguage;
        languageId = languages[language] || 1;
    } catch (err) {
        console.log(err);
    }
    return (
        <div className="homepage">
            <HeroSection languageId={languageId} />
            <GreenImageSection languageId={languageId} />
            <MGSikkaSection languageId={languageId} />
            <MGDostSection languageId={languageId} />
            <MGJigsawSection languageId={languageId} />
            <Testimonial languageId={languageId} />
            <GetMgAppBottom languageId={languageId} />
        </div>
    );
}
