import React, { useState } from "react";
import MileImage0 from "./images/mile0.png";
import { ButttonActionContainer0, CompletedAction, DayName, EarnRewardText } from "./util";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
import moment from "moment";
/**
 * @description Mera Gaon weekly activity
 * @author Suman Kumar (22-12-22)
 */

export default function StartEvent(props) {
    const { userData, languageId, rewardEachEvent } = props;
    const [viewEventDetail, setViewEventDetail] = useState(false);
    return (
        <ButttonActionContainer0
        // style={{
        //     top: `${
        //         languageId == 18 || languageId == 19 || languageId == 20
        //             ? "25%"
        //             : languageId == 12 || languageId == 21 || languageId == 10
        //             ? "25%"
        //             : "24%"
        //     }`,
        // }}
        >
            <CompletedAction style={{ textAlign: "right", flexDirection: "column" }}>
                <div style={{ marginRight: "10px" }}>
                    <img alt="" src={MileImage0}></img>
                    <DayName style={{ right: "26%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                </div>
                <div style={{ width: "90%", textAlign: "center" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                    <button
                        style={{
                            border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                            boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                        }}>
                        <p>🎉 {new Date(userData.registrationTimestamp).toLocaleDateString("en-IN")}</p>
                        <p>😊 {getTranslation(translations, "becameMGUser", languageId)} 😊</p>
                        <EarnRewardText>
                            {getTranslation(translations, "wonMgSikka", languageId).replace("{{100}}", rewardEachEvent)}
                        </EarnRewardText>
                        {viewEventDetail ? (
                            <span>
                                {`${getTranslation(translations, "joinMeraGaon", languageId).replace(
                                    "{{Date}}",
                                    moment(userData.registrationTimestamp).format("DD/MM/YYYY, h:mm A")
                                )}`}
                            </span>
                        ) : (
                            ""
                        )}
                    </button>
                </div>
                {/* <div>
                    <img alt="" src={MileImage0}></img>
                    <DayName style={{ right: "26%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                </div> */}
            </CompletedAction>
        </ButttonActionContainer0>
    );
}
