import React from "react";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
/**
 * @description Mera Gaon weekly activities Error UI same as APP
 * @author Suman Kumar (30-12-22)
 */
import ErrorImg from "./images/no_data_found.png";
function AppErrorUI(props) {
    return (
        <div className="weekly-error-wrapper">
            <img alt="" src={ErrorImg}></img>
            {/* <p>{getTranslation(translations, "errorText", props.languageId)}</p> */}
            <div className="weekly-error">
                <button onClick={props.retry}>
                    <i className="fa fa-repeat"></i>
                    {getTranslation(translations, "retry", props.languageId)}
                </button>
            </div>
        </div>
    );
}
export default AppErrorUI;
