import React, { useState, useEffect } from "react";
// import weekActivityImage from "./images/path4.png";
import weekActivityImage from "./images/path7.png";
import weekActivityImage2 from "./images/path3.png";
import weekActivityReward from "./images/coin.png";
import StartEvent from "./StartEvent";
import Event1 from "./Event1";
import Event3 from "./Event3";
import Event5 from "./Event5";
import Event7 from "./Event7";
import NewEvent2 from "./NewEvent2";
import NewEvent3 from "./NewEvent3";
import NewEvent7 from "./NewEvent7";
import RewardClaimBtn from "./RewardClaimBtn";
import BottomText from "./BottomText";
import { HomeImageWrapper, HomeImageSection, HomeDetailSection, EventContainer, UpdateAppText } from "./util";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
export default function index(props) {
    const { userData, eventData, claimRewardStatus, appVersion } = props;
    const expiredEvent = eventData.find((data) => data.status == "expired");
    let showSorryMessage = false;
    if (expiredEvent && eventData[6].status == "completed") {
        showSorryMessage = true;
    }
    const scrollableEvent = eventData.find((data) => data.status == "pending");
    let isScrollEvent = "";
    if (scrollableEvent) {
        isScrollEvent = eventData.indexOf(scrollableEvent);
    }
    const languageId = userData.languageId;
    const [smallPathImage, setSmallPathImage] = useState(false);
    const [longPathImage, setLongPathImage] = useState(false);
    const noOfEvent = eventData.length;
    const noOfCompletedEvent = eventData.filter((event) => event.status === "completed").length + 1;
    useEffect(() => {
        if (eventData.length === 4 || eventData.length === 5) {
            setSmallPathImage(true);
            setLongPathImage(false);
        }
        if (eventData.length === 6 || eventData.length === 7) {
            setSmallPathImage(false);
            setLongPathImage(true);
        }
    });
    const totalRewardAmount = 500;
    let rewardEachEvent = Math.floor(totalRewardAmount / 5);
    if (noOfEvent == 5) {
        rewardEachEvent = Math.floor(totalRewardAmount / 6);
    } else if (noOfEvent == 6) {
        rewardEachEvent = Math.floor(totalRewardAmount / 7);
    } else if (noOfEvent == 7) {
        rewardEachEvent = Math.floor(totalRewardAmount / 8);
    }
    let earnedReward = noOfCompletedEvent * rewardEachEvent;
    if (noOfCompletedEvent - 1 == noOfEvent) {
        earnedReward = totalRewardAmount;
    }
    const progressPercentage = (earnedReward / totalRewardAmount) * 100;

    const progressStyle = {
        width: `${progressPercentage}%`,
    };
    return (
        <HomeImageWrapper>
            <HomeImageSection>
                <img alt="" src={smallPathImage ? weekActivityImage2 : weekActivityImage} />
            </HomeImageSection>
            <HomeDetailSection
                style={{
                    top: `${smallPathImage ? "10%" : longPathImage ? "8.5%" : "10%"}`,
                }}>
                <h4>{userData.villageName} </h4>
                <h5>
                    {getTranslation(translations, "namaste", languageId).replace("{{Username}}", userData.userName)} 🙏{" "}
                    {""}
                    {getTranslation(translations, "stepsFollow", languageId)}
                </h5>
            </HomeDetailSection>
            <div
                className="weekOne_new_reward"
                style={{
                    // top: `${smallPathImage ? "17%" : longPathImage ? "14%" : "15%"}`,
                    top: `${languageId == 21 || languageId == 20 ? "15%" : "14%"}`,
                }}>
                <fieldset>
                    <legend> {getTranslation(translations, "winUptoReward", languageId)}</legend>
                    <div className="weekOne_new_reward_progressBar">
                        <div style={{ width: "35%" }}>
                            <img alt="" src={weekActivityReward} />
                        </div>
                        {showSorryMessage ? (
                            <div style={{ width: "65%", textAlign:"center" }}>
                                <p>{getTranslation(translations, "completedEventNumber", languageId)}</p>
                                <h4>
                                    {noOfCompletedEvent}/{noOfEvent + 1}
                                </h4>
                                <h4>😔 {getTranslation(translations, "SorryTitle", languageId)} 😔</h4>
                                <p>{getTranslation(translations, "sorryText", languageId)}</p>
                            </div>
                        ) : (
                            <div style={{ width: "65%" }}>
                                <p>{getTranslation(translations, "completedEventNumber", languageId)}</p>
                                <h4>
                                    {noOfCompletedEvent}/{noOfEvent + 1}
                                </h4>
                                <p>{getTranslation(translations, "earnedMgSikkaNumber", languageId)}</p>
                                <h4>
                                    {earnedReward}/{totalRewardAmount}
                                </h4>
                                <div className="progress-bar-container">
                                    <div className="progress-bar" style={progressStyle}></div>
                                </div>
                            </div>
                        )}
                    </div>
                </fieldset>
                {appVersion < 182 ? (
                    <UpdateAppText>{getTranslation(translations, "updateAppText", languageId)}</UpdateAppText>
                ) : null}
            </div>
            <EventContainer languageId={languageId}
                style={{
                    // gap: `${
                    //     eventData.length === 4
                    //         ? "150px"
                    //         : eventData.length === 6
                    //         ? "160px"
                    //         : eventData.length === 5
                    //         ? "105px"
                    //         : eventData.length === 7
                    //         ? "131px"
                    //         : "160px"
                    // }`,
                    // top: `${smallPathImage ? "28%" : longPathImage ? "24%" : "30%"}`,
                    top: `${languageId == 21 || languageId == 20 ? "23%" : "22.5%"}`,
                }}>
                <StartEvent userData={userData} languageId={languageId} rewardEachEvent={rewardEachEvent} />
                <Event1
                    eventData={eventData[0]}
                    languageId={languageId}
                    rewardEachEvent={rewardEachEvent}
                    isScroll={isScrollEvent}
                />
                <Event3
                    eventData={eventData[1]}
                    languageId={languageId}
                    rewardEachEvent={rewardEachEvent}
                    isScroll={isScrollEvent}
                    noOfEvent={noOfEvent}
                />
                <Event5
                    eventData={eventData[2]}
                    languageId={languageId}
                    rewardEachEvent={rewardEachEvent}
                    isScroll={isScrollEvent}
                    noOfEvent={noOfEvent}
                />
                <Event7
                    eventData={eventData[3]}
                    languageId={languageId}
                    rewardEachEvent={rewardEachEvent}
                    isScroll={isScrollEvent}
                    noOfEvent={noOfEvent}
                />
                {eventData.length == 5 ? (
                    <NewEvent2
                        eventData={eventData[4]}
                        languageId={languageId}
                        rewardEachEvent={rewardEachEvent}
                        isScroll={isScrollEvent}
                        noOfEvent={noOfEvent}
                    />
                ) : eventData.length == 6 ? (
                    <React.Fragment>
                        <NewEvent2
                            eventData={eventData[4]}
                            languageId={languageId}
                            rewardEachEvent={rewardEachEvent}
                            isScroll={isScrollEvent}
                            noOfEvent={noOfEvent}
                        />
                        <NewEvent3
                            eventData={eventData[5]}
                            languageId={languageId}
                            rewardEachEvent={rewardEachEvent}
                            isScroll={isScrollEvent}
                            noOfEvent={noOfEvent}
                        />
                    </React.Fragment>
                ) : eventData.length == 7 ? (
                    <React.Fragment>
                        <NewEvent2
                            eventData={eventData[4]}
                            languageId={languageId}
                            rewardEachEvent={rewardEachEvent}
                            isScroll={isScrollEvent}
                            noOfEvent={noOfEvent}
                        />
                        <NewEvent3
                            eventData={eventData[5]}
                            languageId={languageId}
                            rewardEachEvent={rewardEachEvent}
                            isScroll={isScrollEvent}
                            noOfEvent={noOfEvent}
                        />
                        <NewEvent7
                            eventData={eventData[6]}
                            languageId={languageId}
                            rewardEachEvent={rewardEachEvent}
                            isScroll={isScrollEvent}
                            noOfEvent={noOfEvent}
                        />
                    </React.Fragment>
                ) : null}
            </EventContainer>
            <BottomText languageId={languageId} noOfEvent={noOfEvent} eventData={eventData[6]} />
            <RewardClaimBtn claimRewardStatus={claimRewardStatus} languageId={languageId} noOfEvent={noOfEvent} />
        </HomeImageWrapper>
    );
}
