import React, { useEffect } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import { BottomButtonContainer, BotttomButton } from "./util";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
/**
 * @description Mera Gaon weekly activity
 * @author Suman Kumar (21-12-22)
 */
export default function RewardClaimBtn(props) {
    const { claimRewardStatus, languageId, noOfEvent } = props;

    function openAppActionMilestone() {
        window.MeraGaon.navigate("milestone-feature");
    }
    function openAppActionClaimReward() {
        window.MeraGaon.claimReward();
    }
    function openAppActionGotoReward() {
        window.MeraGaon.navigate("goto-rewards");
    }
    let params = null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
    params.id = params.id;

    useEffect(() => {
        if (!params.id && claimRewardStatus === "eligible") {
            var access = document.getElementById(claimRewardStatus);
            access.scrollIntoView({ behavior: "smooth" }, true);
        }
    });
    return (
        <ScrollableAnchor id={"claimReward"}>
            <BottomButtonContainer
                style={{
                    top: `${
                        languageId == 17 || languageId == 21 || languageId == 20 ? "95%" : "96%"
                    }`,
                   // top: `${noOfEvent == 4 || noOfEvent == 5 ? "96%" : noOfEvent == 6 ? "96%" : "96%"}`,
                }}
                id={claimRewardStatus}>
                <BotttomButton>
                    <button onClick={openAppActionMilestone}>
                        {getTranslation(translations, "exploreMilestone", languageId)}
                    </button>
                    {claimRewardStatus === "not-eligible" ? null : claimRewardStatus === "eligible" ? (
                        <button
                            onClick={openAppActionClaimReward}
                            className={
                                claimRewardStatus === "eligible" || params.id == "claimReward" ? "glow-on-hover" : ""
                            }>
                            {getTranslation(translations, "claimReward", languageId)}
                        </button>
                    ) : claimRewardStatus === "claimed" ? (
                        <button onClick={openAppActionGotoReward}>
                            {getTranslation(translations, "gotoReward", languageId)}
                        </button>
                    ) : null}
                </BotttomButton>
            </BottomButtonContainer>
        </ScrollableAnchor>
    );
}
