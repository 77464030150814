import React, { Component } from "react";
import util from "../../utils";
import MG_Logo from "../../images/MG_Logo.png";
import Youtube from "../pages/MeraGaonWebpage/components/Images/ut.png";
import Facebook from "../pages/MeraGaonWebpage/components/Images/fb.png";
import Pinterest from "../pages/MeraGaonWebpage/components/Images/pt.png";
import Instagram from "../pages/MeraGaonWebpage/components/Images/in.png";
import Tiktok from "../pages/MeraGaonWebpage/components/Images/tt.png";
import Linkedin from "../pages/MeraGaonWebpage/components/Images/lk.png";
import Twitter from "../pages/MeraGaonWebpage/components/Images/tw.png";
import translations from "../pages/MeraGaonWebpage/translation";
import getTranslation from "../../utils/getTranslation";
import { getURLParams } from "../pages/MeraGaon/Feed/page.util";
class Footer extends Component {
    constructor(props) {
        super(props);
        const params = getURLParams();
        const languageId = params && params.l ? params.l : this.props.languageId;
        this.state = {
            languageId: languageId,
            dropdownVisible: false,
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.languageId !== this.props.languageId) {
            this.setState({ languageId: this.props.languageId });
        }
    }
    openUrl() {
        window.open("https://youtube.com/@MeraGaonApp", "_blank");
    }
    render() {
        const { languageId } = this.state;
        return (
            <div>
                <div className="col-md-12 page-footer clearfix">
                    <div className="col-md-10 col-sm-9 col-xs-12">
                        <img src={MG_Logo} alt="kisan network logo" />
                        <p className="footer_details_text">
                            {getTranslation(translations, "bottomlogotext", languageId)}
                        </p>
                        <p className="footer_details_text">
                            <a href="mailto:info@meragaonapp.com">
                                <b>{getTranslation(translations, "email", languageId)}:</b> info@meragaonapp.com
                            </a>
                        </p>
                    </div>
                    <div className="col-md-2 col-sm-3 col-xs-12 footer_link_text">
                        <h4>{getTranslation(translations, "mgMenu", languageId)}</h4>
                        <p>
                            <a href="/">{getTranslation(translations, "home", languageId)}</a>
                        </p>
                        <p>
                            <a href="/asp">{getTranslation(translations, "asp", languageId)}</a>
                        </p>
                        <p>
                            <a href="/careers">{getTranslation(translations, "careers", languageId)}</a>
                        </p>
                        <p>
                            <a href="/legal">{getTranslation(translations, "legal", languageId)}</a>
                        </p>
                    </div>
                </div>
                <PostFooter openUrl={this.openUrl} languageId={languageId} />
            </div>
        );
    }
}

const PostFooter = (props) => {
    const languageId = props.languageId;
    return (
        <div className="col-md-12">
            <div className="row page-post-footer" style={{ textAlign: "left" }}>
                <p className="text-sm copyright-text">
                    {`${getTranslation(translations, "copyright", languageId).replace("2023", util.getCurrentYear())}`}

                    {/* Copyright  ©{util.getCurrentYear()} Mera Gaon (Adisan Rural Platforms India Private
                    Limited). All Rights Reserved.{" "} */}
                </p>
                <div className="follow-icons-container">
                    {/* <a href="https://youtube.com/@MeraGaonApp" target="_blank" rel="noopener noreferrer">
                        <button>
                            <img src={Youtube} alt="" />
                        </button>
                    </a> */}
                    <button
                        onClick={() => {
                            props.openUrl();
                        }}>
                        <img src={Youtube} alt="" />
                    </button>
                    <button disabled>
                        <img src={Facebook} alt="" />
                    </button>
                    <button disabled>
                        <img src={Pinterest} alt="" />
                    </button>

                    <button disabled>
                        <img src={Instagram} alt="" />
                    </button>

                    <button disabled>
                        <img src={Tiktok} alt="" />
                    </button>

                    <button disabled>
                        <img src={Linkedin} alt="" />
                    </button>

                    <button disabled>
                        <img src={Twitter} alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Footer;
