import React from "react";

export default function highlight(props) {
    return (
        <div className="highlight-loader" style={{marginLeft: `${props.marginLeft ? props.marginLeft : "0"}`,}}
        >
            <div className="highlight-loader-item"></div>
        </div>
    );
}
