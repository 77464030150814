import React from "react";
import Lottie from "react-lottie";
import MGDostLottie from "./Images/mgdostLottie.json";
import styled from "styled-components";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
/** MG Dost Lottie Section
@author Suman Kumar, 28th Sept 2023
**/
const LottieStyledComponent = styled.div`
    width: 95%;
    height: 100%;
    max-width: 400px;
    max-height: 480px;
    margin: auto;
    // border: 2px dashed #c5890d;
    // border-radius: 10px;

    @media (max-width: 1024px) {
        width: 70%;
    }
    @media (max-width: 780px) {
        width: 80%;
    }
    @media (max-width: 680px) {
        width: 80%;
    }
    @media (max-width: 480px) {
        width: 95%;
    }
`;
export default function MGDostSection(props) {
    const { languageId } = props;
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: MGDostLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div className="mg_dost_section">
            <h2>{getTranslation(translations, "mgDostHeading", languageId)}</h2>
            <div className="asp_mdDost_section_div">
                <div className="asp_mdDost_pSection">
                    <p>{getTranslation(translations, "mgDostHeading1", languageId)}</p>
                    <p style={{ color: "#0B3021", marginTop:"14px" }}>{getTranslation(translations, "mgDostHeading2", languageId)}</p>
                </div>
                <div>
                    <LottieStyledComponent>
                        <Lottie options={defaultOptions} style={{ width: "100%", height: "100%" }} />
                    </LottieStyledComponent>
                </div>
            </div>
        </div>
    );
}
