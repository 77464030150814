import React, { useState, useEffect } from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import MileImage4 from "./images/mile4.png";
import MileImage4P from "./images/eSevenp.png";
import translations from "./translation";
import getTranslation from "../../../../utils/getTranslation";
import {
    ButttonActionContainerRight2,
    DayName,
    ActiveAction,
    ApprovalPendingAction,
    CompletedAction,
    UnCompletedAction,
    LockedAction,
    EarnRewardText,
    CanEarnRewardText,
} from "./util";
import Highlight from "./highlight";
import moment from "moment";
/**
 * @description Mera Gaon weekly activity
 * @author Suman Kumar (11-12-22)
 */
export default function Event7(props) {
    const { eventData, languageId, isScroll, rewardEachEvent } = props;
    const [viewEventDetail, setViewEventDetail] = useState(false);

    let params = null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
    params.id = params.id;

    function openAppAction(openEvent) {
        window.MeraGaon.navigate(openEvent);
    }
    let eventStatus = "";
    if (isScroll == 6) {
        eventStatus = eventData.status;
    }
    useEffect(() => {
        if (!params.id && eventStatus == "pending") {
            var access = document.getElementById(eventStatus);
            access.scrollIntoView({ behavior: "smooth" }, true);
        }
    });
    return (
        <ScrollableAnchor id={"event7"}>
            <ButttonActionContainerRight2
                style={{
                    top: `${eventData.status == "pending" ? "84%" : "85%"}`,
                }}
                id={eventStatus}>
                {eventData.status == "pending" ? (
                    <ActiveAction>
                        <div>
                            <img alt="" src={MileImage4P}></img>
                            <DayName style={{ left: "28%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                        </div>
                        <div
                            style={{
                                width: "80%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                            <button
                                onClick={() => openAppAction(eventData.action)}
                                className={
                                    eventData.status == "pending" || params.id == "3" || isScroll === 2
                                        ? "glow-on-hover"
                                        : ""
                                }>
                                <p>🕗 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                <p>{eventData.title}</p>
                                <CanEarnRewardText>
                                    {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                        "{{100}}",
                                        rewardEachEvent + 4
                                    )}
                                </CanEarnRewardText>
                            </button>
                            <button
                                style={{ width: "auto", background: "transparent", boxShadow: "none" }}
                                onClick={() => openAppAction(eventData.action)}>
                                <Highlight />
                            </button>
                        </div>
                    </ActiveAction>
                ) : eventData.status == "awaiting-approval" ? (
                    <ApprovalPendingAction>
                        <div>
                            <img alt="" src={MileImage4P}></img>
                            <DayName style={{ left: "28%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                        </div>
                        <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                            <button
                                style={{
                                    width: "90%",
                                    border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                    boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                }}
                                // className={params.id == "3" ? "glow-on-hover" : ""}
                            >
                                <p>⌛ {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                <p>{eventData.title}</p>
                                {viewEventDetail ? (
                                    <span>
                                        {`${getTranslation(translations, "pendingApproval", languageId).replace(
                                            "{{Date}}",
                                            moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                        )}`}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </button>
                        </div>
                    </ApprovalPendingAction>
                ) : eventData.status == "completed" ? (
                    <CompletedAction>
                        <div>
                            <img alt="" src={MileImage4P}></img>
                            <DayName style={{ left: "28%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                        </div>
                        <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                            <button
                                // className={params.id == "3" ? "glow-on-hover" : ""}
                                style={{
                                    width: "90%",
                                    border: `${viewEventDetail ? "1px solid #2f6a36" : "none"}`,
                                    boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                }}>
                                <p>🎉 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                <p>{eventData.title}</p>
                                <EarnRewardText>
                                    {getTranslation(translations, "wonMgSikka", languageId).replace(
                                        "{{100}}",
                                        rewardEachEvent + 4
                                    )}
                                </EarnRewardText>
                                {viewEventDetail ? (
                                    <span>
                                        {`${getTranslation(translations, "completeEventText", languageId).replace(
                                            "{{Date}}",
                                            moment(eventData.completedAt).format("DD/MM/YYYY, h:mm A")
                                        )}`}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </button>
                        </div>
                    </CompletedAction>
                ) : eventData.status == "expired" ? (
                    <UnCompletedAction>
                        <div>
                            <img alt="" src={MileImage4P}></img>
                            <DayName style={{ left: "28%" }}>{getTranslation(translations, "day", languageId)}</DayName>
                        </div>
                        <div style={{ width: "80%" }} onClick={() => setViewEventDetail(!viewEventDetail)}>
                            <button
                                // className={params.id == "3" ? "glow-on-hover" : ""}
                                style={{
                                    width: "90%",
                                    border: `${viewEventDetail ? "1px solid #6a0808" : "none"}`,
                                    boxShadow: `${viewEventDetail ? "none" : "0px 4px 4px #00000040"}`,
                                }}>
                                <p>😔 {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}</p>
                                <p>{eventData.title}</p>
                                {viewEventDetail ? (
                                    <span>{getTranslation(translations, "missedEvent", languageId)}</span>
                                ) : (
                                    ""
                                )}
                            </button>
                        </div>
                    </UnCompletedAction>
                ) : eventData.status == "locked" ? (
                    // <LockedAction style={{ textAlign: "right", flexDirection: "column" }}>
                    <LockedAction>
                        {/* <div style={{ marginRight: "10px" }}> */}
                        <div>
                            <img alt="" src={MileImage4}></img>
                            <DayName style={{ right: "26%" }}>
                                {getTranslation(translations, "day", languageId)}
                            </DayName>
                        </div>
                        <div style={{ width: "80%", textAlign: "left" }}>
                            <button disabled={true} style={{ width: "100%" }}>
                                🔓 {getTranslation(translations, "unlock", languageId)}{" "}
                                {new Date(eventData.startTimestamp).toLocaleDateString("en-IN")}
                                <CanEarnRewardText>
                                    {getTranslation(translations, "accumulateSikkaText", languageId).replace(
                                        "{{100}}",
                                        rewardEachEvent + 4
                                    )}
                                </CanEarnRewardText>
                            </button>
                        </div>
                    </LockedAction>
                ) : null}
            </ButttonActionContainerRight2>
        </ScrollableAnchor>
    );
}
