import React from "react";
import translations from "../translation";
import getTranslation from "../../../../utils/getTranslation";
import GetLinkHome from "./GetLinkHome";
import MgHomeImage from "./Images/homeImage.png";
import PlayStoreCount from "./PlayStoreCount";

/** Hero Section testText
@author Suman Kumar, 24th Dec 2021 modified on 2nd march 2023
**/
export default function HeroSection(props) {
    const { languageId } = props;
    const handleOnChange = (e) => {
        const { value } = e.target;
        const languages = {
            1: "english",
            2: "hindi",
            3: "marathi",
            4: "kannada",
            5: "telugu",
            6: "bengali",
            7: "punjabi",
            8: "gujarati",
            // 9: "malay",
            10: "malayalam",
            17: "tamil",
            14: "assamese",
            21: "khasi",
            20: "mizo",
            19: "manipuri",
            18: "nagamese",
        };
        const language = languages[value];
        window.location = `http://meragaonapp.com/${language}`;
        //window.location = `https://mg-website-dot-kn-operations-test.an.r.appspot.com/${language}`;
        // window.location = `http://localhost:3000/${language}`;
    };
    return (
        <div style={{ marginTop: "80px" }}>
            <div className="button_language_area">
                <select onChange={handleOnChange} defaultValue={languageId}>
                    <option value="1">English</option>
                    <option value="2">हिंदी</option>
                    <option value="3">मराठी</option>
                    <option value="4">ಕನ್ನಡ </option>
                    <option value="5">తెలుగు</option>
                    <option value="6">বাংলা </option>
                    <option value="7">ਪੰਜਾਬੀ </option>
                    <option value="8">ગુજરાતી </option>
                    <option value="10">മലയാളം </option>
                    <option value="17">தமிழ் </option>
                    <option value="14">অসমীয়া </option>
                    <option value="20">Mizo </option>
                    <option value="21">Khasi </option>
                    <option value="19">Manipuri </option>
                    <option value="18">Nagamese </option>
                </select>
            </div>
            <div className="beta_Text_area">
                <button>{getTranslation(translations, "betaText", languageId)}</button>
            </div>
            <h1 className="mg_bold_header">{getTranslation(translations, "communityText", languageId)}</h1>
            <PlayStoreCount languageId={languageId} />
            <p className="mg_light_header">{getTranslation(translations, "mgIntroText", languageId)}</p>
            <div>
                <GetLinkHome languageId={languageId} />
            </div>
            <div className="meraGaon_hero_image">
                <img src={MgHomeImage} alt="" />
            </div>
        </div>
    );
}
